import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import Menu from "@mui/material/Menu"
import Flag from "react-country-flag"
import { MouseEvent, useMemo, useState } from "react"
import useLang from "../../../hooks/useLang"
import { LANG } from "../../../store/features/lang/langSlice"

export default function SelectCountry() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { toogleLang, lang } = useLang()

  const countries: { value: LANG; flag: string }[] = [
    {
      value: "fr",
      flag: "FR",
    },
    {
      value: "en",
      flag: "GB",
    },
  ]

  const handleClickListItem = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (event: MouseEvent<HTMLElement>, lang: LANG) => {
    toogleLang({ lang })
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const selectedLang = useMemo(() => (lang === "fr" ? "FR" : "GB"), [lang])

  return (
    <div>
      <List
        component="nav"
        aria-label="Country selection"
        sx={{ padding: 0, paddingTop: 0, paddingBottom: 0 }}
      >
        <ListItemButton
          id="country-button"
          aria-haspopup="listbox"
          aria-controls="country-menu"
          aria-label="select country"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          sx={{ padding: 0, paddingTop: 0, paddingBottom: 0, height: 40, paddingX: 1 }}
        >
          <ListItemText
            secondary={
              <span
                style={{
                  color: "white",
                  fontWeight: 400,
                  fontSize: "1rem",
                }}
              >
                <Flag
                  countryCode={selectedLang}
                  style={{ width: 20, marginRight: 8 }}
                />
                {lang.toUpperCase()}
              </span>
            }
          />
        </ListItemButton>
      </List>
      <Menu
        id="country-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{
          zIndex: 999999,
        }}
        MenuListProps={{
          "aria-labelledby": "country-button",
          role: "listbox",
        }}
      >
        {countries?.map((country, index) => (
          <MenuItem
            key={country.value}
            selected={index === index}
            onClick={(event) => handleMenuItemClick(event, country.value)}
          >
            <Flag
              countryCode={country.flag}
              style={{ width: 20, marginRight: 8 }}
            />
            {country.value}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
