import "./App.css"
import React, { useEffect, useMemo } from "react"
import theme from "./theme"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import CustomSnackbar from "./components/Common/CustomSnackbar/CustomSnackbar"
import CustomAlert from "./components/Common/CustomAlert/CustomAlert"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import Router from "./Router"
import { onMessage } from "firebase/messaging"
import { messaging, onForegroundMessage } from "./firebase/firebase"
import { useAppDispatch, useAppSelector } from "./store/app/hooks"
import { setPushTokens } from "./store/features/user/userSlice"
import { PlatformType } from "./gql/graphql"
import { toast, ToastContainer } from "react-toastify"
import Message from "./components/Notification/Message"
import { getFirebaseToken } from "./firebase/serviceWorker"
import { IntlProvider } from "react-intl"
import en from "./lang/en-US.json"
import fr from "./lang/fr.json"
import { getNativatorLanguage } from "./utils/language"
import { LANG, selectLang, setLang } from "./store/features/lang/langSlice"

type Mode = "light" | "dark"

// TODO: Dynamique mode
function App() {
  let mode = "light" as Mode
  const customTheme = React.useMemo(() => theme(mode), [mode])
  const dispatch = useAppDispatch()
  const lang = useAppSelector(selectLang)

  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission()
    if (permission === "granted") {
      const token = await getFirebaseToken()
      dispatch(
        setPushTokens({
          platform: PlatformType.Web,
          pushToken: token,
        }),
      )
    } else if (permission === "denied") {
      alert(
        "Veuillez activer vos notifications afin de recevoir les dernières nouvelles !",
      )
    }
  }

  onMessage(messaging, (payload) => {
    toast(<Message notification={payload.notification} data={payload.data} />, {
      type: "info",
    })
  })

  useEffect(() => {
    requestPermission()
  }, [])

  useEffect(() => {
    onForegroundMessage()
      .then((payload) => {
        toast(
          <Message notification={payload.notification} data={payload.data} />,
          {
            type: "info",
          },
        )
      })
      .catch((err) =>
        console.log(
          "An error occured while retrieving foreground message. ",
          err,
        ),
      )
  }, [])

  const messages: any = useMemo(
    () => ({
      en,
      fr,
    }),
    [en, fr],
  )

  const retriveLocaleLang = () => {
    const lang = localStorage.getItem("lang") as LANG
    if (lang) {
      dispatch(setLang(lang))
    } else {
      const locale = getNativatorLanguage()
      const validLang = locale.includes("fr") ? "fr" : "en"
      dispatch(setLang(validLang))
    }
  }

  useEffect(() => {
    retriveLocaleLang()
  }, [])

  const localeMessages = useMemo(() => {
    if (!messages[lang]) {
      messages["en"]
    }
    return messages[lang]
  }, [lang])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <IntlProvider defaultLocale="fr" messages={localeMessages} locale={lang}>
        <ThemeProvider theme={customTheme}>
          <Router />
          <CustomSnackbar />
          <CustomAlert />
          <ToastContainer hideProgressBar />
        </ThemeProvider>
      </IntlProvider>
    </LocalizationProvider>
  )
}

export default App
