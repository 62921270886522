import React from "react"
import { Box, Typography } from "@mui/material"
import { useIntl } from "react-intl"
import { ChatMessage } from "../../../../../../gql/graphql"
import useLang from "../../../../../../hooks/useLang"

interface Props {
  message: ChatMessage
}

const MessageSize = ({ message }: Props) => {
  const isHavValue = (value?: string | null) => (value ? true : false)
  const { getText } = useLang()

  const style = {
    textAlign: "left",
    fontSize: "12.5px",
  }

  if (!message.OrderSize) return null

  return (
    <Box>
      <Typography variant="body2" align="left" sx={style}>
        {message.content}
      </Typography>
      {isHavValue(message.OrderSize.width) && (
        <Typography variant="body2" sx={style}>
          {getText("Largeur")}: {message.OrderSize.width}
        </Typography>
      )}
      {isHavValue(message.OrderSize.depth) && (
        <Typography variant="body2" sx={style}>
          {getText("Profondeur")}: {message.OrderSize.depth}
        </Typography>
      )}
      {isHavValue(message.OrderSize.height) && (
        <Typography variant="body2" sx={style}>
          {getText("Hauteur")}: {message.OrderSize.height}
        </Typography>
      )}
      {isHavValue(message.OrderSize.length) && (
        <Typography variant="body2" sx={style}>
          {getText("Longueur")}: {message.OrderSize.length}
        </Typography>
      )}
      {isHavValue(message.OrderSize.weight) && (
        <Typography variant="body2" sx={style}>
          {getText("Poids")}: {message.OrderSize.weight}
        </Typography>
      )}
    </Box>
  )
}

export default MessageSize
