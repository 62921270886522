import Box from "@mui/material/Box"
import { Button, Grid, Tab } from "@mui/material"
import { green, orange, red } from "@mui/material/colors"
import PaidIcon from "@mui/icons-material/Paid"
import CardStat from "./CardStat/CardStat"
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange"
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { graphql } from "../../gql"
import { useQuery } from "@apollo/client"
import Loader from "../Common/Loader/Loader"
import { Action, Role, Status } from "../../gql/graphql"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { SyntheticEvent, useEffect, useMemo, useState } from "react"
import OrderNoOrderPrice from "./OrderNoOrderPrice/OrderNoOrderPrice"
import ListAskDelivryMan from "./ListAskDelivryMan/ListAskDelivryMan"
import ListAskVersment from "./ListAskVersment/ListAskVersment"
import { havePermissions } from "../../utils/permissions"
import { whereInputClause } from "../../utils/permissions"
import { getUserCountryId } from "../../utils/authToken"
import ArchiveIcon from "@mui/icons-material/Archive"
import exportRelationsExcel from "../../utils/exportRelationsExcel"
import { ModelConfig } from "../../types/ModelConfig"
import { useAppDispatch } from "../../store/app/hooks"
import { setOpenSnackbar } from "../../store/features/snackbar/snackbarSlice"
import useLang from "../../hooks/useLang"

export const STAT_ONE = graphql(`
  query getStatisticOne(
    $aggregateOrderWhere2: OrderWhereInput
    $where: UserWhereInput
  ) {
    aggregateOrder(where: $aggregateOrderWhere2) {
      _count {
        _all
      }
    }
    aggregateUser(where: $where) {
      _count {
        _all
      }
    }
  }
`)

export const STAT_TWO = graphql(`
  query getStatisticTwo(
    $aggregateOrderWhere2: OrderWhereInput
    $where: UserWhereInput
  ) {
    aggregateOrder(where: $aggregateOrderWhere2) {
      _count {
        _all
      }
    }
    aggregateUser(where: $where) {
      _count {
        _all
      }
    }
  }
`)

export const STAT_ARCHIVE = graphql(`
  query getStatisticArchive(
    $aggregateOrderWhere2: OrderWhereInput
    $where: UserWhereInput
  ) {
    aggregateOrder(where: $aggregateOrderWhere2) {
      _count {
        _all
      }
    }
    aggregateUser(where: $where) {
      _count {
        _all
      }
    }
  }
`)
export const STAT_ALL_PAID = graphql(`
  query getStatisticAllPaid($aggregateOrderWhere2: OrderWhereInput) {
    aggregateOrder(where: $aggregateOrderWhere2) {
      _count {
        _all
      }
    }
  }
`)

export default function LabTabs() {
  const countryId = getUserCountryId()
  const [loadingExport, setLoadingExport] = useState(false)
  const { getText } = useLang()
  const craftmanQuery = useMemo(
    () => ({
      Craftman: {
        is: {
          ...whereInputClause({
            country: {
              is: {
                id: {
                  equals: countryId,
                },
              },
            },
          }),
        },
      },
    }),
    [countryId],
  )

  const customerQuery = useMemo(
    () => ({
      role: {
        equals: Role.Customer,
      },
      ...whereInputClause({
        CustomerOrders: {
          some: {
            Country: {
              is: {
                id: {
                  equals: countryId,
                },
              },
            },
          },
        },
      }),
    }),
    [countryId],
  )

  const { loading, data: dataOne } = useQuery(STAT_ONE, {
    variables: {
      aggregateOrderWhere2: {
        status: {
          not: {
            in: [Status.Paid, Status.Archive, Status.Delivry],
          },
        },
        ...craftmanQuery,
      },
      where: {
        role: {
          equals: Role.Craftman,
        },
        ...whereInputClause({
          country: {
            is: {
              id: {
                equals: countryId,
              },
            },
          },
        }),
      },
    },
  })
  const { loading: isLoading, data: dataTwo } = useQuery(STAT_TWO, {
    variables: {
      aggregateOrderWhere2: {
        status: {
          in: [Status.Paid, Status.Delivry],
        },
        ...craftmanQuery,
      },
      where: {
        ...customerQuery,
      },
    },
  })

  const { loading: isLoadingArchive, data: dataArchive } = useQuery(STAT_TWO, {
    variables: {
      aggregateOrderWhere2: {
        status: {
          in: [Status.Archive],
        },
        ...craftmanQuery,
      },
      where: {
        ...customerQuery,
      },
    },
  })

  const { loading: isLoadingAllPaid, data: dataAllPaid } = useQuery(
    STAT_ALL_PAID,
    {
      variables: {
        aggregateOrderWhere2: {
          ...craftmanQuery,
          ActionOrders: {
            some: {
              action: {
                equals: Action.Paid,
              },
            },
          },
        },
      },
    },
  )

  const urlParams = new URLSearchParams(window.location.search)
  const tabs = urlParams.get("tabs")
  const tabsValus = ["1", "2", "3"]
  const [value, setValue] = useState("1")
  const dispatch = useAppDispatch()

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const handleExport = () => {
    setLoadingExport(true)
    const models: ModelConfig[] = [
      {
        model: "User",
        fields: ["id", "name", "lastName", "email", "phoneNumber"],
        relations: [
          {
            field: "Categories",
            type: "many-to-many",
            fields: ["id", "name_fr"],
          },
        ],
        sheetName: getText("Artisants"),
      },
      {
        model: "User",
        fields: ["id", "name", "lastName", "email", "phoneNumber"],
        sheetName: getText("Clients"),
      },
    ]
    exportRelationsExcel("ceedow_base_client_artisan.xlsx", models)
      .then(() =>
        dispatch(
          setOpenSnackbar({
            message: getText("Extraction a été faite avec succès."),
            status: "success",
          }),
        ),
      )
      .catch((e) => {
        dispatch(
          setOpenSnackbar({
            message:
              getText("Erreur lors de l'exportation, veuillez réessayer s'il vous plaît."),
            status: "error",
          }),
        )
      })
      .finally(() => setLoadingExport(false))
  }

  useEffect(() => {
    if (tabs && tabsValus.includes(tabs)) {
      setValue(tabs)
    }
  }, [tabs])

  const styleTabPanel = { maxWidth: "94Vw", overflow: "auto" }

  if (loading || isLoading || isLoadingArchive || isLoadingAllPaid)
    return <Loader />

  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Button style={{ backgroundColor: "white" }} onClick={handleExport}>
            {loadingExport
              ? getText("Traitement en cours...")
              : getText("Extraire base clients et artisans")}
          </Button>
        </Grid>
        <Grid item lg={3} xs={12} md={6} sm={6}>
          <CardStat
            color={green[500]}
            icon={<PaidIcon htmlColor="white" />}
            label={getText("Total commande payé")}
            value={dataAllPaid?.aggregateOrder._count?._all.toString() || "0"}
            route="/order/list?status=all_paid"
            permission="order.view"
          />
        </Grid>
        <Grid item lg={3} xs={12} md={6} sm={6}>
          <CardStat
            color={green[500]}
            icon={<PaidIcon htmlColor="white" />}
            label={getText("Total commande payé en cours")}
            value={dataTwo?.aggregateOrder._count?._all.toString() || "0"}
            route="/order/list?status=paid"
            permission="order.view"
          />
        </Grid>
        <Grid item lg={3} xs={12} md={6} sm={6}>
          <CardStat
            color={orange[500]}
            icon={<CurrencyExchangeIcon htmlColor="white" />}
            label={getText("Commande en cours")}
            value={dataOne?.aggregateOrder._count?._all.toString() || "0"}
            route="/order/list?status=pending"
            permission="order.view"
          />
        </Grid>
        <Grid item lg={3} xs={12} md={6} sm={6}>
          <CardStat
            color={red[500]}
            icon={<ArchiveIcon htmlColor="white" />}
            label={getText("Commande en archivés")}
            value={dataArchive?.aggregateOrder._count?._all.toString() || "0"}
            route="/order/list?status=archive"
            permission="order.view"
          />
        </Grid>
        <Grid item lg={3} xs={12} md={6} sm={6}>
          <CardStat
            color={"#1CBA93"}
            icon={<AssignmentIndIcon htmlColor="white" />}
            label={getText("Artisans")}
            value={dataOne?.aggregateUser._count?._all.toString() || "0"}
            route="/craftman/list"
            permission="craftman.view"
          />
        </Grid>
        <Grid item lg={3} xs={12} md={6} sm={6}>
          <CardStat
            color={"#931CBA"}
            icon={<AccountCircleIcon htmlColor="white" />}
            label={getText("Clients")}
            value={dataTwo?.aggregateUser._count?._all.toString() || "0"}
            route="/user/list"
            permission="customer.view"
          />
        </Grid>
      </Grid>
      {havePermissions([
        "askPaiment.view",
        "deliveryMan.view",
        "order.view",
      ]) && (
        <Grid container mt={2} bgcolor={"#5D490E"} borderRadius={1}>
          <Grid item lg={12}>
            <TabContext value={value}>
              <TabList
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {havePermissions(["askPaiment.view"]) && (
                  <Tab
                    label={getText("Demande de paiement")}
                    value="1"
                    sx={{ color: "ActiveCaption" }}
                    wrapped
                  />
                )}
                {havePermissions(["deliveryMan.view"]) && (
                  <Tab
                    label={getText("Demande de livreur")}
                    value="2"
                    sx={{ color: "ActiveCaption" }}
                    wrapped
                  />
                )}
                {havePermissions(["order.view"]) && (
                  <Tab
                    label={getText("Commande sans proposition de prix")}
                    value="3"
                    sx={{ color: "ActiveCaption" }}
                    wrapped
                  />
                )}
              </TabList>
              {havePermissions(["askPaiment.view"]) && (
                <TabPanel value="1" style={styleTabPanel}>
                  <ListAskVersment />
                </TabPanel>
              )}
              {havePermissions(["deliveryMan.view"]) && (
                <TabPanel value="2" style={styleTabPanel}>
                  <ListAskDelivryMan />
                </TabPanel>
              )}
              {havePermissions(["order.view"]) && (
                <TabPanel value="3" style={styleTabPanel}>
                  <OrderNoOrderPrice />
                </TabPanel>
              )}
            </TabContext>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}
