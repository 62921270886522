import { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  selecttransporterPricePagination,
  selecttransporterPriceQuery,
  settransporterPricePagination,
  settransporterPriceQuery,
} from "../../../store/features/transporterPrice/transporterPriceSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import {
  TransporterPrice,
  SortOrder,
  TransporterPriceWhereInput,
} from "../../../gql/graphql"
import { MRT_ColumnDef, MRT_ColumnFiltersState } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { updateCacheTransporterPrice } from "../../../caches/updateCacheTransporterPrice"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { havePermissions, whereInputClause } from "../../../utils/permissions"
import { Box } from "@mui/material"
import ModalCountries from "../../Common/ModalCountries/ModalCountries"
import { getUserCountryId } from "../../../utils/authToken"
import useLang from "../../../hooks/useLang"

export const list_transporterPrices = graphql(`
  query transporterPrices_total(
    $where: TransporterPriceWhereInput
    $orderBy: [TransporterPriceOrderByWithRelationAndSearchRelevanceInput!]
    $where2: TransporterPriceWhereInput
    $take: Int
    $skip: Int
  ) {
    aggregateTransporterPrice(where: $where2) {
      _count {
        _all
      }
    }
    transporterPrices(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      weightMin
      weightMax
      price
      TransporterZones {
        id
        name
        Countries {
          id
          name_fr
        }
      }
      Transporters {
        id
        name
      }
    }
  }
`)

export const delete_transporterPrice = graphql(`
  mutation DeleteOneTransporterPrice(
    $where: TransporterPriceWhereUniqueInput!
  ) {
    deleteOneTransporterPrice(where: $where) {
      id
      weightMin
      weightMax
      price
    }
  }
`)

const LIST_FILTER = graphql(`
  query filtersListTransporterPrice(
    $where: CountryWhereInput
    $transporterZonesWhere2: TransporterZoneWhereInput
    $transportersWhere2: TransporterWhereInput
  ) {
    countries(where: $where) {
      name_fr
      id
    }
    transporterZones(where: $transporterZonesWhere2) {
      id
      name
    }
    transporters(where: $transportersWhere2) {
      id
      name
    }
  }
`)

const ListtransporterPrice = () => {
  const { page, size } = useAppSelector(selecttransporterPricePagination)
  const query = useAppSelector(selecttransporterPriceQuery)
  const dispatch = useAppDispatch()
  const countryId = getUserCountryId()
  const { getText } = useLang()
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])

  const whereCluaseFilder = whereInputClause({
    variables: {
      transporterZonesWhere2: {
        Countries: {
          some: {
            id: {
              equals: countryId,
            },
          },
        },
      },
      where: {
        id: {
          equals: countryId,
        },
      },
      transportersWhere2: {
        TransporterZones: {
          some: {
            id: {
              equals: countryId,
            },
          },
        },
      },
    },
  })

  const { loading: loadingFilter, data: dataFilter } = useQuery(
    LIST_FILTER,
    whereCluaseFilder,
  )
  const navigate = useNavigate()

  const getValueFilter = (el: {
    name_fr?: string
    id: number
    name?: string
  }) => {
    return {
      label: el.name_fr ? el.name_fr : el.name,
      value: el.id.toString(),
      text: el.name_fr ? el.name_fr : el.name,
    }
  }

  const getFilters: () => TransporterPriceWhereInput = useCallback(() => {
    if (columnFilters.length) {
      return columnFilters.reduce(
        (
          accumulator: TransporterPriceWhereInput,
          { id, value }: { id: string; value: any },
        ) => {
          switch (id) {
            case "Countries":
              accumulator = {
                ...accumulator,
                TransporterZones: {
                  some: {
                    Countries: {
                      some: {
                        id: {
                          equals: Number(value),
                        },
                      },
                    },
                  },
                },
              }
              break
            case "TransporterZones":
              accumulator = {
                ...accumulator,
                TransporterZones: {
                  some: {
                    id: {
                      equals: Number(value),
                    },
                  },
                },
              }
              break
            case "Transporters":
              accumulator = {
                ...accumulator,
                Transporters: {
                  some: {
                    id: {
                      equals: Number(value),
                    },
                  },
                },
              }
              break
            case "id":
              accumulator = {
                ...accumulator,
                id: {
                  equals: Number(value),
                },
              }
              break
            case "price":
              accumulator = {
                ...accumulator,
                price: {
                  equals: Number(value),
                },
              }
              break
            case "weightMax":
              accumulator = {
                ...accumulator,
                weightMax: {
                  equals: Number(value),
                },
              }
              break
            case "weightMin":
              accumulator = {
                ...accumulator,
                weightMin: {
                  equals: Number(value),
                },
              }
              break
          }
          return accumulator
        },
        {},
      )
    }
    return {}
  }, [columnFilters])

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            { weightMin: { equals: Number(query) } },
            { weightMax: { equals: Number(query) } },
            { price: { equals: Number(query) } },
            {
              id: {
                equals: Number(query),
              },
            },
          ],
        }
      : {}
  }, [query])

  const [getTransporterPirces, { loading, data }] = useLazyQuery(
    list_transporterPrices,
  )

  useEffect(() => {
    getTransporterPirces({
      variables: {
        take: size,
        skip: page * size,
        orderBy: { createdAt: SortOrder.Asc },
        where: {
          ...queryName(),
          ...getFilters(),
          ...whereInputClause({
            TransporterZones: {
              some: { Countries: { some: { id: { equals: countryId } } } },
            },
          }),
        },
        where2: {
          ...queryName(),
          ...getFilters(),
          ...whereInputClause({
            TransporterZones: {
              some: { Countries: { some: { id: { equals: countryId } } } },
            },
          }),
        },
      },
    })
  }, [getTransporterPirces, page, query, queryName, size, getFilters])

  const [deletetransporterPrice] = useMutation(delete_transporterPrice)

  const columns = useMemo<MRT_ColumnDef<TransporterPrice>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
        filterVariant: "text",
        id: "id",
      },
      {
        accessorKey: "price",
        header: getText("Tarif du transport"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "price",
      },
      {
        accessorKey: "weightMin",
        header: getText("Poids minimum"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "weightMin",
      },
      {
        accessorKey: "weightMax",
        header: getText("Poids maximum"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "weightMax",
      },
      {
        accessorFn: (row) => row.Transporters.map((el) => el.name).join(", "),
        header: getText("Transporteurs"),
        enableClickToCopy: true,
        filterVariant: "select",
        id: "Transporters",
        filterSelectOptions: dataFilter?.transporters.map((el) =>
          getValueFilter(el),
        ),
      },
      {
        accessorFn: (row) =>
          row.TransporterZones.map((el) => el.name).join(", "),
        header: getText("Zones"),
        enableClickToCopy: true,
        filterVariant: "select",
        id: "TransporterZones",
        filterSelectOptions: dataFilter?.transporterZones.map((el) =>
          getValueFilter(el),
        ),
      },
      {
        accessorFn: (row) => {
          if (!row?.TransporterZones?.length) return null
          const uniqueNames = new Set(
            row.TransporterZones.flatMap((el) => el.Countries).map(
              (el) => el.name_fr,
            ),
          )
          return (
            <>
              <Box>{Array.from(uniqueNames).join(", ").slice(0, 100)}...</Box>
              <Box>
                <ModalCountries countries={Array.from(uniqueNames)} />
              </Box>
            </>
          )
        },
        header: getText("Pays"),
        filterVariant: "select",
        filterSelectOptions: dataFilter?.countries.map((el) =>
          getValueFilter(el),
        ),
        id: "Countries",
      },
    ],
    [dataFilter],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(settransporterPricePagination(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deletetransporterPrice({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: getText(
              "Le tarif du transport a été supprimé avec succès",
            ),
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheTransporterPrice({
          action: "delete",
          cache,
          entryData: data?.deleteOneTransporterPrice,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: getText(
          "Êtes-vous vraiment sûr de vouloir supprimer ce tarif du transport ?",
        ),
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) =>
    dispatch(settransporterPriceQuery(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle(getText("Modifier un tarif de transport")))
    navigate(`/transporter-price/edit/${id}`)
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  if (loadingFilter) return <Loader />

  return (
    <CustomTable
      enableColumnFilters
      columns={columns}
      data={data?.transporterPrices || []}
      lableAddNew={getText("Créer un nouveau tarif du transport")}
      rootLisName={"/transporter-price/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateTransporterPrice._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={
        havePermissions(["transporterPrice.delete"])
          ? handleOpenDeleteDialog
          : undefined
      }
      isHaveHeaderAction={havePermissions(["transporterPrice.create"])}
      handleEdit={
        havePermissions(["transporterPrice.change"]) ? handleEdit : undefined
      }
      isHaveView={false}
      isHaveAction={havePermissions([
        "transporterPrice.view",
        "transporterPrice.change",
        "transporterPrice.delete",
      ])}
      handleRefresh={handleRefresh}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
    />
  )
}

export default ListtransporterPrice
