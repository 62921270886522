import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useMutation } from "@apollo/client"
import FormMaterialType from "../FormMaterialType/FormMaterialType"
import { updateCacheMaterialType } from "../../../caches/updateCacheMaterialType"
import { InfinityListItem } from "../../../types/InfinityListItem"
import useLang from "../../../hooks/useLang"

const createOneMaterialType = graphql(`
  mutation createOneMaterialType($data: MaterialTypeCreateInput!) {
    createOneMaterialType(data: $data) {
      id
      name_fr
      name_en
      Category {
        id
        name_fr
        name_en
      }
      Countries {
        id
        name_fr
      }
    }
  }
`)

export default function AddMaterialType() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { getText } = useLang()

  const [materialType, setMaterialType] = useState({
    name_fr: "",
    name_en: "",
  })
  const [country, setCountry] = useState<InfinityListItem[]>([])
  const [createMaterialType, { loading }] = useMutation(createOneMaterialType)
  const [category, setCategory] = useState<InfinityListItem>({
    label: "",
    value: "",
  })

  const getCategory = (value: InfinityListItem) => {
    setCategory(value)
  }

  const handleInputChange = (event: any) => {
    setMaterialType({
      ...materialType,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateSegmentation = (e: any) => {
    e.preventDefault()
    createMaterialType({
      variables: {
        data: {
          ...materialType,
          Category: {
            connect: { id: Number(category.value) },
          },
          Countries: {
            connect: country.map((el) => ({ id: +el.value })),
          },
        },
      },
      onCompleted: () => {
        navigate("/material-type/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheMaterialType({
          action: "add",
          cache,
          entryData: data?.createOneMaterialType,
        })
      },
    })
  }
  const getCountry = (value: InfinityListItem[]) => {
    if (value) {
      setCountry(value)
    } else {
      setCountry([])
    }
  }

  return (
    <FormMaterialType
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateSegmentation}
      loading={loading}
      name_en={materialType.name_en}
      name_fr={materialType.name_fr}
      title={getText("Ajouter un type matière")}
      getCategory={getCategory}
      category={category}
      countryDefault={country}
      getCountry={getCountry}
    />
  )
}
