import { OrderPrice, Status } from "../gql/graphql"
import useLang from "./useLang"

const useOrder = () => {
  const { getText } = useLang()
  const getLastOrderPriceValid = (orderPrices: OrderPrice[]) => {
    const orderPricesValid = orderPrices.filter(
      (p) => p.status === Status.Valid,
    )
    if (orderPricesValid.length) {
      return orderPricesValid[0].price
    } else if (orderPrices.length) {
      const lastOrderPrice = orderPrices[0]
      if (lastOrderPrice.status === Status.Valid) {
        return lastOrderPrice.price
      }
    }
    return getText("Pas définie")
  }

  const getCustomerOrderPrice = (orderPrices: OrderPrice[]) => {
    if (orderPrices.length) {
      const copyOrderPrice = [...orderPrices]
      const lastPrice = copyOrderPrice.pop()
      return lastPrice?.price
    }
    return getText("Pas définie")
  }
  const getOrderStatus = (orderStatus?: Status) => {
    let status = ""
    if (orderStatus) {
      switch (orderStatus) {
        case Status.Paid:
          status = getText("Payée")
          break
        case Status.Archive:
          status = getText("Archivée")
          break
        case Status.Delivry:
          status = getText("Livrée")
          break
        case Status.Pending:
          status = getText("En cours")
          break
        case Status.Valid:
          status = getText("Validée")
          break
      }
    }
    return status
  }
  return {
    getLastOrderPriceValid,
    getCustomerOrderPrice,
    getOrderStatus,
  }
}

export default useOrder
