import { Avatar, Box, Card, CardContent, Typography } from "@mui/material"
import moment from "moment"
import { Notification, NotificationDataType } from "../../../../gql/graphql"
import MessageIcon from "@mui/icons-material/Message"
import CampaignIcon from "@mui/icons-material/Campaign"
import PaymentIcon from "@mui/icons-material/Payment"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt"
import { green } from "@mui/material/colors"
import "moment/locale/fr"
import useNotification from "../../../../hooks/useNotification"
import { graphql } from "../../../../gql"
import { useMutation } from "@apollo/client"
import { useCallback } from "react"
import useLang from "../../../../hooks/useLang"
moment.locale("fr")

interface Props {
  notification: Notification
  handleClose: () => void
}

const VIEW_NOTIFICATION = graphql(`
  mutation ViewNotification(
    $data: NotificationUpdateInput!
    $where: NotificationWhereUniqueInput!
  ) {
    updateOneNotification(data: $data, where: $where) {
      id
      read
      body_en
      body_fr
      createdAt
      data
      receiverId
      title_en
      title_fr
      type
    }
  }
`)

const CardNotification = ({ notification, handleClose }: Props) => {
  const { handleNavigate } = useNotification()
  const [ViewNotification] = useMutation(VIEW_NOTIFICATION)
  const { getByLang } = useLang()

  const isNew = useCallback(() => {
    return !notification.read
  }, [notification])

  const Icon = () => {
    switch (notification.type) {
      case NotificationDataType.Chatmessage:
        return <MessageIcon />
        break
      case NotificationDataType.Order:
        return <CampaignIcon />
        break
      case NotificationDataType.Newcustomer:
      case NotificationDataType.Newcraftman:
        return <PersonAddAltIcon />
        break
      case NotificationDataType.Askcraftmanpayment:
        return <PaymentIcon />
        break
      case NotificationDataType.Askdelivryman:
        return <LocalShippingIcon />
        break
      default:
        return <MessageIcon />
        break
    }
  }

  const handleGo = () => {
    handleNavigate(notification.data, notification.type)
    handleClose()
    if (isNew()) {
      ViewNotification({
        variables: {
          data: {
            read: {
              set: true,
            },
          },
          where: {
            id: notification.id,
          },
        },
        update(cache, { data }) {
          const newNotification = data?.updateOneNotification
          if (newNotification) {
            cache.modify({
              id: cache.identify({
                __typename: "User",
                id: newNotification.receiverId,
              }),
              fields: {
                countNewNotification(existing = 1) {
                  return existing ? existing - 1 : 0
                },
                Notifications(existing = [], { toReference }) {
                  const index = existing.findIndex(
                    (el: any) =>
                      el.__ref === toReference(newNotification, true)?.__ref,
                  )
                  if (index > -1) {
                    return [
                      ...existing.map((el: any, i: number) => ({
                        ...el,
                        read: i === index ? true : el.read,
                      })),
                    ]
                  }
                  return existing
                },
              },
            })
          }
        },
        onError(error) {
          console.log(error)
        },
      })
    }
  }

  return (
    <Card
      variant="elevation"
      sx={{
        m: 2,
        background: isNew() ? green[600] : "#5D490E",
        cursor: "pointer",
        ":hover": {
          opacity: 0.9,
        },
      }}
      onClick={handleGo}
    >
      <CardContent>
        <Box
          alignItems={"flex-start"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Box mr={1}>
            <Avatar sx={{ width: 35, height: 35 }}>
              <Icon />
            </Avatar>
          </Box>
          <Box maxWidth={200}>
            <Box>
              <Typography variant="h5" color="white">
                {getByLang({
                  value_fr: notification.title_fr,
                  value_en: notification.title_en,
                })}
              </Typography>
            </Box>
            <Box my={1.5}>
              <Typography variant="body2" color="whitesmoke">
                {getByLang({
                  value_fr: notification.body_fr,
                  value_en: notification.body_en,
                })}
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"end"}>
              <Typography
                variant="caption"
                color="whitesmoke"
                textAlign={"end"}
                fontSize={11}
              >
                {moment(notification.createdAt).fromNow()}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default CardNotification
