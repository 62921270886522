import { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  selectUserPagination,
  selectUserQuery,
  setUserPagination,
  setQuery,
} from "../../../store/features/user/userSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { SortOrder, User, Role, UserWhereInput } from "../../../gql/graphql"
import { MRT_ColumnDef, MRT_ColumnFiltersState } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { updateCacheCraftman } from "../../../caches/updateCacheCraftman"
import { havePermissions, whereInputClause } from "../../../utils/permissions"
import CustomImage from "../../Common/CustomImage/CustomImage"
import { getUserCountryId } from "../../../utils/authToken"
import useLang from "../../../hooks/useLang"

export const LIST_CUSTOMERS = graphql(`
  query Customers(
    $where: UserWhereInput
    $aggregateUserWhere2: UserWhereInput
    $orderBy: [UserOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    users(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      phoneNumber
      email
      role
      name
      lastName
      lang
      customerRole
      customerNbOrderPaid
      customerNbOrder
      ProfilePhoto {
        url
      }
      country {
        id
        name_fr
        currency
      }
    }
    aggregateUser(where: $aggregateUserWhere2) {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_CUSTOMER = graphql(`
  mutation DeleteOneCustomer($where: UserWhereUniqueInput!) {
    deleteOneUser(where: $where) {
      id
      phoneNumber
      email
      name
      lastName
      role
    }
  }
`)

const LIST_FILTER = graphql(`
  query filtersListCustomer($where: CountryWhereInput) {
    countries(where: $where) {
      name_fr
      id
    }
  }
`)

const ListCustomer = () => {
  const { getText } = useLang()
  const { page, size } = useAppSelector(selectUserPagination)
  const query = useAppSelector(selectUserQuery)
  const dispatch = useAppDispatch()
  const countryId = getUserCountryId()
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
  const whereCluase = whereInputClause({
    variables: {
      where: {
        id: {
          equals: countryId,
        },
      },
    },
  })
  const { loading: loadingFilter, data: dataFilter } = useQuery(
    LIST_FILTER,
    whereCluase,
  )

  const navigate = useNavigate()

  const getValueFilter = (el: { name_fr: string; id: number }) => {
    return {
      label: el.name_fr,
      value: el.id.toString(),
      text: el.name_fr,
    }
  }

  const getFilters: () => UserWhereInput = useCallback(() => {
    if (columnFilters.length) {
      return columnFilters.reduce(
        (
          accumulator: UserWhereInput,
          { id, value }: { id: string; value: any },
        ) => {
          switch (id) {
            case "customerRole":
              accumulator = {
                ...accumulator,
                customerRole: {
                  equals: value,
                },
              }
              break
            case "country":
              accumulator = {
                ...accumulator,
                countryId: {
                  equals: Number(value),
                },
              }
              break
            case "Devise":
              accumulator = {
                ...accumulator,
                country: {
                  is: {
                    currency: {
                      contains: value,
                    },
                  },
                },
              }
              break
            case "name":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    name: {
                      search: value,
                    },
                  },
                  {
                    name: {
                      contains: value,
                    },
                  },
                ],
              }
              break
            case "id":
              accumulator = {
                ...accumulator,
                id: {
                  equals: Number(value),
                },
              }
              break
            case "lastName":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    lastName: {
                      search: value,
                    },
                  },
                  {
                    lastName: {
                      contains: value,
                    },
                  },
                ],
              }
              break
            case "email":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    email: {
                      search: value,
                    },
                  },
                  {
                    email: {
                      contains: value,
                    },
                  },
                ],
              }
              break
            case "phoneNumber":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    phoneNumber: {
                      search: value,
                    },
                  },
                  {
                    phoneNumber: {
                      contains: value,
                    },
                  },
                ],
              }
              break
            case "lang":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    lang: {
                      search: value,
                    },
                  },
                  {
                    lang: {
                      contains: value,
                    },
                  },
                ],
              }
              break
          }
          return accumulator
        },
        {},
      )
    }
    return {}
  }, [columnFilters])

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            { name: { search: query } },
            { lastName: { search: query } },
            { email: { search: query } },
          ],
        }
      : {}
  }, [query])

  const getRole = (value: string) => {
    return value == "customer_particular"
      ? getText("Particulier")
      : getText("Professionnel")
  }

  const [getUsers, { loading, data }] = useLazyQuery(LIST_CUSTOMERS)

  const roles = [
    {
      value: "customer_particular",
      label: getText("Particulier"),
    },
    {
      value: "customer_business",
      label: getText("Professionnel"),
    },
  ]

  useEffect(() => {
    getUsers({
      variables: {
        take: size,
        skip: page * size,
        orderBy: { id: SortOrder.Desc },
        where: {
          role: {
            equals: Role.Customer,
          },
          ...whereInputClause({
            CustomerOrders: {
              some: {
                Craftman: {
                  is: {
                    countryId: {
                      equals: countryId,
                    },
                  },
                },
              },
            },
          }),
          ...queryName(),
          ...getFilters(),
        },
        aggregateUserWhere2: {
          role: {
            equals: Role.Customer,
          },
          ...whereInputClause({
            CustomerOrders: {
              some: {
                countryId: {
                  equals: countryId,
                },
              },
            },
          }),
          ...queryName(),
          ...getFilters(),
        },
      },
    })
  }, [getUsers, page, query, queryName, size, getFilters])

  const [deleteCustomer] = useMutation(DELETE_CUSTOMER)

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorFn: (row) => (
          <>
            <CustomImage
              url={row?.ProfilePhoto?.url}
              style={{ width: "50px", height: "50px", borderRadius: "25px" }}
            />
          </>
        ),
        header: getText("Profile"),
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) =>
          row.customerRole ? getRole(row.customerRole) : getText("Pas définie"),
        header: getText("Rôle"),
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
        filterVariant: "select",
        filterSelectOptions: roles,
        id: "customerRole",
      },
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
        filterVariant: "text",
        id: "id",
      },
      {
        accessorKey: "name",
        header: getText("Nom"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "name",
      },
      {
        accessorKey: "lastName",
        header: getText("Prénom"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "lastName",
      },
      {
        accessorKey: "email",
        header: getText("Email"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "email",
      },
      {
        accessorKey: "phoneNumber",
        header: getText("Numéro de telephone"),
        filterVariant: "text",
        enableClickToCopy: true,
        id: "phoneNumber",
      },
      {
        accessorFn: (row) => row?.country?.name_fr.toLocaleUpperCase(),
        header: getText("Pays"),
        enableClickToCopy: true,
        filterVariant: "select",
        id: "country",
        filterSelectOptions: dataFilter?.countries.map((el) =>
          getValueFilter(el),
        ),
      },
      {
        accessorFn: (row) => row.lang,
        header: getText("Langue"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "lang",
      },
      {
        accessorFn: (row) => row?.country?.currency,
        header: getText("Devise"),
        enableClickToCopy: true,
        filterVariant: "text",
        enableColumnFilter: true,
      },
      {
        accessorFn: (row) => row?.customerNbOrderPaid,
        header: getText("Nb de commande payé"),
        enableClickToCopy: true,
        filterVariant: "text",
        enableColumnFilter: false,
      },

      {
        accessorFn: (row) => row?.customerNbOrder,
        header: getText("Nb de devis"),
        enableClickToCopy: true,
        filterVariant: "text",
        enableColumnFilter: false,
      },
    ],
    [dataFilter, getText],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setUserPagination(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteCustomer({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: getText("Le client a été supprimé avec succès"),
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheCraftman({
          action: "delete",
          cache,
          entryData: data?.deleteOneUser,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: getText(
          "Êtes-vous vraiment sûr de vouloir supprimer ce client ?",
        ),
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) => dispatch(setQuery(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle(getText("Modifier un client")))
    navigate(`/user/edit/${id}`)
  }

  const handleView = (id: number) => {
    dispatch(setTitle(getText("Detail un client")))
    navigate(`/user/detail/${id}`)
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  if (loadingFilter) return <Loader />

  return (
    <CustomTable
      enableColumnFilters
      columns={columns}
      data={data?.users || []}
      lableAddNew={getText("Créer un nouveau client")}
      rootLisName={"/user/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateUser._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={
        havePermissions(["customer.delete"])
          ? handleOpenDeleteDialog
          : undefined
      }
      isHaveHeaderAction={havePermissions(["customer.create"])}
      handleEdit={havePermissions(["customer.change"]) ? handleEdit : undefined}
      isHaveView={havePermissions(["customer.view"])}
      handleView={havePermissions(["customer.view"]) ? handleView : undefined}
      isHaveAction={havePermissions([
        "customer.view",
        "customer.change",
        "customer.delete",
      ])}
      handleRefresh={handleRefresh}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
    />
  )
}

export default ListCustomer
