import { useCallback, useEffect, useState } from "react"
import { graphql } from "../../../gql"
import { useQuery } from "@apollo/client"
import { QueryMode, SortOrder } from "../../../gql/graphql"
import InfiniteScrollAutocomplete from "../InfiniteScrollAutocomplete/InfiniteScrollAutocomplete"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { getUserCountryId } from "../../../utils/authToken"
import { isSuperAdmin, whereInputClause } from "../../../utils/permissions"
import useLang from "../../../hooks/useLang"

const LIST_TRANSPORTER = graphql(`
  query ListTransporter(
    $where: TransporterWhereInput
    $orderBy: [TransporterOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    transporters(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name
    }
  }
`)

interface Props {
  getTransporter: (transporter: InfinityListItem | InfinityListItem[]) => void
  transporterDefault: InfinityListItem | InfinityListItem[]
  label?: string
  required?: boolean
  multiple?: boolean
  isOpen?: boolean
}
const CustomListTransporter = ({
  getTransporter,
  transporterDefault,
  label = "Transporteurs",
  required = true,
  isOpen = false,
  multiple = false,
}: Props) => {
  const [transporter, setTransporter] = useState(transporterDefault)
  const { getText } = useLang()
  const [queryTransporter, setQueryTransporter] = useState("")
  const [openTransporter, setOpenTransporter] = useState(false)
  const size = 500

  const countryId = getUserCountryId()
  const whereCluase = whereInputClause({
    TransporterZones: {
      some: {
        Countries: {
          some: {
            id: {
              equals: countryId,
            },
          },
        },
      },
    },
  })

  const queryName = useCallback(() => {
    const OrClause = {
      OR: [
        { name: { search: queryTransporter, mode: QueryMode.Insensitive } },
        { name: { endsWith: queryTransporter, mode: QueryMode.Insensitive } },
        { name: { contains: queryTransporter, mode: QueryMode.Insensitive } },
        { name: { startsWith: queryTransporter, mode: QueryMode.Insensitive } },
      ],
    }
    return queryTransporter ? OrClause : {}
  }, [queryTransporter])

  const { loading, data, fetchMore } = useQuery(LIST_TRANSPORTER, {
    variables: {
      take: size,
      skip: 0,
      orderBy: { name: SortOrder.Asc },
      ...((isOpen || queryTransporter || !isSuperAdmin()) && {
        where: {
          ...queryName(),
          ...whereCluase,
        },
      }),
    },
  })

  const handleSearchCategory = (query: string) => {
    setQueryTransporter(query)
  }

  const handleCloseCategory = () => {
    setOpenTransporter(false)
  }
  const handleOpenTransporter = async () => {
    setOpenTransporter(true)
  }

  const onEndReachedCategory = () => {
    fetchMore({
      variables: {
        skip: data?.transporters.length,
      },
    })
  }

  useEffect(() => {
    if (transporter && transporterDefault) {
      if (!Array.isArray(transporter) && !Array.isArray(transporterDefault)) {
        if (!transporter?.value && transporterDefault?.value) {
          setTransporter(transporterDefault)
        }
      } else {
        setTransporter(transporterDefault)
      }
    }
  }, [transporter, transporterDefault])

  const dataTransporter = data?.transporters

  const transporterValue =
    loading || !dataTransporter
      ? []
      : dataTransporter.map((el) => ({
          label: el.name,
          value: el.id.toString(),
        }))

  return (
    <InfiniteScrollAutocomplete
      handleClose={handleCloseCategory}
      handleOpen={handleOpenTransporter}
      open={openTransporter}
      defaultValue={transporterDefault}
      keyName="label"
      label={label === "Transporteurs" ? getText(label) : label}
      onSearch={handleSearchCategory}
      options={openTransporter ? transporterValue : []}
      loading={loading}
      multiple={multiple}
      onChange={(value) => {
        if (value) {
          setTransporter(value)
          getTransporter(value)
        }
      }}
      query={queryTransporter}
      value={transporter}
      onEndReached={onEndReachedCategory}
      required={required}
    />
  )
}

export default CustomListTransporter
