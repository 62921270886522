import { useState } from "react"
import moment from "moment"
import { Typography, Box } from "@mui/material"
import { ChatMessageType } from "../MessageCard"
import useLang from "../../../../../../hooks/useLang"

interface Props {
  message: ChatMessageType
  limit?: number
}

const MessageText = ({ message, limit = 50 }: Props) => {
  const [length, setLength] = useState(limit)
  const { getText } = useLang()
  const havePlus = message.content && message.content.length > limit

  const handleSetLength = () => {
    setLength((length === limit ? message?.content?.length : limit) || limit)
  }

  const nextText =
    message.content && message.content?.length > limit && length === limit
      ? "..."
      : ""

  if (!message.content) return null

  return (
    <Box
      sx={{
        marginRight: "5px",
      }}
    >
      {message.contentType === "DATE" ? (
        <Typography
          sx={{
            marginBottom: havePlus ? "10px" : "0",
            textAlign: "left",
            fontSize: "12.5px",
          }}
          variant="body2"
        >
          {getText("La date de fin de conception est prévue pour le")}
          <strong>{moment(new Date(message.content)).format("LL")}</strong>
        </Typography>
      ) : (
        <Typography
          sx={{
            marginBottom: havePlus ? "10px" : "0",
            textAlign: "left",
            fontSize: "12.5px",
          }}
          variant="body2"
        >
          {message.content.slice(0, length)}
          {nextText}
        </Typography>
      )}
      {havePlus && (
        <Box
          onClick={handleSetLength}
          sx={{
            display: "flex",
            cursor: "pointer",
            fontSize: "12px",
            textDecorationLine: "underline",
            justifyContent: "end",
          }}
        >
          {length === limit ? getText("Voir plus") : getText("Voir moins")}
        </Box>
      )}
    </Box>
  )
}

export default MessageText
