import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useMutation } from "@apollo/client"
import FormItemType from "../FormItemType/FormItemType"
import { updateCacheItemType } from "../../../caches/updateCacheItemType"
import { InfinityListItem } from "../../../types/InfinityListItem"
import useLang from "../../../hooks/useLang"

const createOneItemType = graphql(`
  mutation createOneItemType($data: ItemTypeCreateInput!) {
    createOneItemType(data: $data) {
      id
      name_fr
      name_en
      weight
      Category {
        id
        name_fr
        name_en
      }
      Countries {
        id
        name_fr
      }
    }
  }
`)

export default function AddItemType() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { getText } = useLang()

  const [itemType, setItemType] = useState({
    name_fr: "",
    name_en: "",
    weight: "",
  })

  const [createItemType, { loading }] = useMutation(createOneItemType)
  const [country, setCountry] = useState<InfinityListItem[]>([])
  const [category, setCategory] = useState<InfinityListItem>({
    label: "",
    value: "",
  })

  const getCategory = (value: InfinityListItem) => {
    setCategory(value)
  }

  const handleInputChange = (event: any) => {
    setItemType({
      ...itemType,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateSegmentation = (e: any) => {
    e.preventDefault()
    if (!country.length) {
      dispatch(
        setOpenSnackbar({
          message: getText("Veuillez préciser le pays, s'il vous plait."),
        }),
      )
      return
    }
    createItemType({
      variables: {
        data: {
          ...itemType,
          weight: itemType.weight ? Number(itemType.weight) : undefined,
          Category: {
            connect: { id: Number(category.value) },
          },
          Countries: {
            connect: country.map((el) => ({ id: +el.value })),
          },
        },
      },
      onCompleted: () => {
        navigate("/item-type/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheItemType({
          action: "add",
          cache,
          entryData: data?.createOneItemType,
        })
      },
    })
  }

  const getCountry = (value: InfinityListItem[]) => {
    if (value) {
      setCountry(value)
    } else {
      setCountry([])
    }
  }

  return (
    <FormItemType
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateSegmentation}
      loading={loading}
      title={getText("Ajouter un type article")}
      getCategory={getCategory}
      category={category}
      countryDefault={country}
      getCountry={getCountry}
      {...itemType}
    />
  )
}
