import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useEffect, useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { NewFileInput, UserRoleType } from "../../../gql/graphql"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { updateCacheCraftman } from "../../../caches/updateCacheCraftman"
import FormCraftman from "../FormCraftman/FormCraftman"
import Loader from "../../Common/Loader/Loader"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { uploadFiles } from "../../../utils/uploadFiles"
import { MinTypeFile } from "../../Common/CustomUploadFiles/CustomUploadFiles"
import { isSuperAdmin } from "../../../utils/permissions"
import { getUserCountryId } from "../../../utils/authToken"
import useLang from "../../../hooks/useLang"

const updateCraftmanMutation = graphql(`
  mutation updateCraftman($updateUserInput: UpdateUserInput!) {
    updateUserAccount(updateUserInput: $updateUserInput) {
      token
      user {
        id
        phoneNumber
        email
        role
        name
        lastName
        lang
        yearOfExperience
        minQuantityOrder
        ProfilePhoto {
          id
          name
          url
        }
        PhotoAchievements {
          id
          name
          url
        }
        Categories {
          id
          name_fr
          name_en
        }
        ItemTypes {
          id
          name_fr
          name_en
        }
        MaterialTypes {
          id
          name_fr
          name_en
        }
        country {
          id
          name_fr
          name_en
          currency
        }
      }
    }
  }
`)

const getOneCraftmanQuery = graphql(`
  query GetOneCraftmanQuery($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      phoneNumber
      email
      role
      name
      lastName
      lang
      yearOfExperience
      minQuantityOrder
      description
      ProfilePhoto {
        id
        name
        url
      }
      PhotoAchievements {
        id
        name
        url
      }
      Categories {
        id
        name_fr
        name_en
      }
      ItemTypes {
        id
        name_fr
        name_en
      }
      MaterialTypes {
        id
        name_fr
        name_en
      }
      country {
        id
        name_fr
        currency
        name_en
      }
    }
  }
`)

export default function EditCraftman() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const params = useParams()
  const { getText, getByLang } = useLang()
  const id = params.id ? parseInt(params.id, 10) : 0

  const [updatecraftman, { loading }] = useMutation(updateCraftmanMutation)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [rePassword, setRePassword] = useState<string>("")
  const [defaulPhoto, setDefaulPhoto] = useState<MinTypeFile | undefined>(
    undefined,
  )
  const [defaultUrls, setDefaultUrls] = useState<MinTypeFile[]>([])
  const [files, setFiles] = useState<File[]>([])
  const [photoAchievementDeleteIds, setPhotoAchievementDeleteIds] = useState<
    number[]
  >([])
  const [lengthPhotosDefaults, setLengthPhotosDefaults] = useState<number>()

  const [profileIdDelete, setProfileIdDelete] = useState<number | undefined>(
    undefined,
  )

  const defaultValue: InfinityListItem = {
    label: "",
    value: "",
  }

  const [category, setCategory] = useState<InfinityListItem>(defaultValue)
  const [country, setCountry] = useState<InfinityListItem>(defaultValue)
  const [itemTypes, setItemTypes] = useState<InfinityListItem[]>([])
  const [materialTypes, setMaterialTypes] = useState<InfinityListItem[]>([])

  const [user, setUser] = useState({
    id,
    email: "",
    lastName: "",
    role: UserRoleType.Craftman,
    phoneNumber: "",
    name: "",
    lang: "fr",
    description: "",
    minQuantityOrder: "",
    yearOfExperience: "",
  })

  const [getCraftman, { loading: loadingInit, error }] = useLazyQuery(
    getOneCraftmanQuery,
    {
      onCompleted(data) {
        const newUser = data.user
        if (!isSuperAdmin()) {
          if (newUser?.country?.id != getUserCountryId()) {
            navigate("/")
            return
          }
        }
        setUser({
          id: newUser?.id || 0,
          email: newUser?.email || "",
          lastName: newUser?.lastName || "",
          phoneNumber: newUser?.phoneNumber || "",
          name: newUser?.name || "",
          lang: newUser?.lang || "fr",
          role: UserRoleType.Craftman,
          yearOfExperience: newUser?.yearOfExperience?.toString() || "",
          minQuantityOrder: newUser?.minQuantityOrder?.toString() || "",
          description: newUser?.description || "",
        })

        if (data.user?.ProfilePhoto) {
          const { url, id, name } = data.user?.ProfilePhoto
          setDefaulPhoto({ url, id, name })
        }

        if (newUser?.PhotoAchievements) {
          newUser?.PhotoAchievements.forEach((el) => {
            let photo = {
              name: el.name,
              url: el.url,
              id: el.id,
            }
            setDefaultUrls((prev) => [...prev, photo])
          })
          setLengthPhotosDefaults(newUser?.PhotoAchievements.length)
        }

        if (newUser?.country) {
          const newcountry: InfinityListItem = {
            value: newUser?.country.id.toString() || "",
            label:
              getByLang({
                value_fr: newUser?.country.name_fr,
                value_en: newUser?.country.name_en,
              }) || "",
          }
          setCountry(newcountry)
        }
        if (newUser?.Categories) {
          const newCategories: InfinityListItem[] =
            newUser?.Categories.map((el) => ({
              value: el.id.toString(),
              label: getByLang({
                value_fr: el.name_fr,
                value_en: el.name_en,
              }),
            })) || []
          setCategory(newCategories.length ? newCategories[0] : defaultValue)
        }
        if (newUser?.MaterialTypes) {
          const newmaterialTypes: InfinityListItem[] =
            newUser?.MaterialTypes.map((el) => ({
              value: el.id.toString(),
              label: getByLang({
                value_fr: el.name_fr,
                value_en: el.name_en,
              }),
            })) || []
          setMaterialTypes(newmaterialTypes)
        }
        if (newUser?.ItemTypes) {
          const newItemTypes: InfinityListItem[] =
            newUser?.ItemTypes.map((el) => ({
              value: el.id.toString(),
              label: getByLang({
                value_fr: el.name_fr,
                value_en: el.name_en,
              }),
            })) || []
          setItemTypes(newItemTypes)
        }
      },
    },
  )

  const handleInputChange = (event: any) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  const handleSignup = ({
    profilePhoto,
    photoAchievements,
  }: {
    profilePhoto?: NewFileInput
    photoAchievements?: NewFileInput[]
  }) => {
    updatecraftman({
      variables: {
        updateUserInput: {
          ...user,
          id,
          profilePhoto,
          photoAchievements,
          categoryIds: [Number(category.value)],
          countryId: Number(country.value),
          itemIds: itemTypes.map((el) => Number(el.value)),
          materialIds: materialTypes.map((el) => Number(el.value)),
          photoAchievementDeleteIds,
          profilePhotoDeleteId: profileIdDelete,
          minQuantityOrder: user.minQuantityOrder
            ? Number(user.minQuantityOrder)
            : 0,
          yearOfExperience: user.yearOfExperience
            ? Number(user.yearOfExperience)
            : 0,
        },
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
        setIsLoading(false)
      },
      onCompleted: () => {
        setIsLoading(false)
        navigate("/craftman/list")
      },
      update: (cache, { data }) => {
        updateCacheCraftman({
          action: "update",
          cache,
          entryData: data?.updateUserAccount.user,
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (
      !files.length &&
      lengthPhotosDefaults &&
      photoAchievementDeleteIds.length === lengthPhotosDefaults
    ) {
      dispatch(
        setOpenSnackbar({
          message:
            "Veuillez choisir les photos de réalisations de l'artisan !!!",
        }),
      )
      return
    }
    if (!itemTypes?.length) {
      dispatch(
        setOpenSnackbar({
          message: "Veuillez choisir le(s) type(s) article(s) de l'artisan !!!",
        }),
      )
      return
    }
    if (!materialTypes.length) {
      dispatch(
        setOpenSnackbar({
          message: "Veuillez choisir le(s) type(s) matière(s) de l'artisan !!!",
        }),
      )
      return
    }
    if (file || files?.length) {
      setIsLoading(true)
      const filesToUploads = file ? [file, ...files] : files
      uploadFiles({
        files: filesToUploads,
        cbErr(err) {
          setOpenSnackbar({
            message:
              "Veuillez choisir des fichiers plus léger, s'il vous plait",
          })
          setIsLoading(false)
        },
      }).then((files) => {
        const profilePhoto = files?.find((el) => el.name === file?.name)
        const photoAchievements = files?.filter((el) => el.name !== file?.name)
        handleSignup({
          profilePhoto: profilePhoto,
          photoAchievements: photoAchievements,
        })
      })
    } else {
      handleSignup({})
    }
  }

  const onFileUpload = (file: File) => {
    setFile(file)
  }

  const onClearUpload = (file: MinTypeFile) => {
    setFile(null)
    if (file.id) {
      setProfileIdDelete(file.id)
    }
  }

  useEffect(() => {
    getCraftman({
      variables: {
        where: {
          id,
        },
      },
    })
  }, [id])

  const getCategory = (newcategory: InfinityListItem) => {
    setCategory(category)
    if (category.value != newcategory.value) {
      setMaterialTypes([])
      setItemTypes([])
    }
  }

  const getCountry = (country: InfinityListItem) => {
    setCountry(country)
  }

  const getItemTypes = (itemTypes: InfinityListItem[] | InfinityListItem) => {
    if (Array.isArray(itemTypes)) {
      setItemTypes(itemTypes)
    }
  }

  const getMaterialTypes = (
    materialTypes: InfinityListItem[] | InfinityListItem,
  ) => {
    if (Array.isArray(materialTypes)) {
      setMaterialTypes(materialTypes)
    }
  }

  const onFilesUpload = (files: File[]) => {
    setFiles(files)
  }

  const onClearUploads = (file: MinTypeFile) => {
    setFiles((prev) => [...prev.filter((_) => _.name !== file.name)])
    if (file.id) {
      setPhotoAchievementDeleteIds([...photoAchievementDeleteIds, file.id])
    }
  }

  if (loadingInit) return <Loader />

  if (error) return "error"

  return (
    <FormCraftman
      getPhoneNumber={(value) => setUser({ ...user, phoneNumber: value })}
      getRePassword={(value) => setRePassword(value)}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      loading={isLoading || loading}
      onClearUpload={onClearUpload}
      onFileUpload={onFileUpload}
      rePassword={rePassword}
      title={getText("Modifier un craftman")}
      email={user.email}
      lastName={user.lastName}
      name={user.name}
      password={""}
      description={user.description || ""}
      phoneNumber={user.phoneNumber || ""}
      defaulPhoto={defaulPhoto}
      havePassword={false}
      categoryDefault={category}
      countryDefault={country}
      getCategory={getCategory}
      getCountry={getCountry}
      getItemTypes={getItemTypes}
      getMaterialTypes={getMaterialTypes}
      itemTypesDefault={itemTypes}
      materialTypesDefault={materialTypes}
      categoryId={category.value ? parseInt(category.value) : undefined}
      onClearUploads={onClearUploads}
      onFilesUpload={onFilesUpload}
      defaultUrls={defaultUrls}
      required={!defaultUrls.length}
      yearOfExperience={user.yearOfExperience}
      minQuantityOrder={user.minQuantityOrder}
    />
  )
}
