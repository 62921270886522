import { Box, Typography } from "@mui/material"
import useLang from "../../../hooks/useLang"

const NoDataStatistic = () => {
  const { getText } = useLang()
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      py={6}
    >
      <Box>
        <img
          style={{ width: "100px", height: "100px" }}
          src="/statistics.png"
          alt=""
          loading="eager"
        />
      </Box>
      <Box>
        <Typography style={{ color: "#fff", textAlign: "center" }}>
          {getText("Il n'y a pas encore de statistique à afficher")}
        </Typography>
      </Box>
    </Box>
  )
}

export default NoDataStatistic
