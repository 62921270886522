/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Paper, Typography, colors } from "@mui/material"
import { graphql } from "../../../gql"
import { useNavigate, useParams } from "react-router-dom"
import { useLazyQuery } from "@apollo/client"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"
import { Role, SortOrder, User } from "../../../gql/graphql"
import { useCallback, useEffect, useMemo, useState } from "react"
import Loader from "../../Common/Loader/Loader"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { useAppDispatch } from "../../../store/app/hooks"
import NoDataStatistic from "../../Common/NoDataStatistic/NoDataStatistic"
import Chart from "../../Common/Chart/Chart"
import CustomTable from "../../Common/CustomTable/CustomTable"
import { MRT_ColumnDef } from "material-react-table"
import { havePermissions, isSuperAdmin } from "../../../utils/permissions"
import { getUserCountryId } from "../../../utils/authToken"
import useLang from "../../../hooks/useLang"

const GET_MATERIALTYPE = graphql(`
  query getDetailMaterialTypeQuery(
    $where: MaterialTypeWhereUniqueInput!
    $aggregateUserWhere2: UserWhereInput
    $aggregateOrderWhere2: OrderWhereInput
  ) {
    materialType(where: $where) {
      id
      name_fr
      name_en
      Countries {
        id
        name_en
      }
      Category {
        id
        name_fr
        name_en
      }
    }
    aggregateUser(where: $aggregateUserWhere2) {
      _count {
        id
      }
    }
    aggregateOrder(where: $aggregateOrderWhere2) {
      _count {
        id
      }
    }
  }
`)
const GET_CRAFTMEN = graphql(`
  query getCraftmen(
    $where: UserWhereInput
    $take: Int
    $skip: Int
    $orderBy: [UserOrderByWithRelationAndSearchRelevanceInput!]
  ) {
    users(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      email
      lastName
    }
  }
`)

const DetailMaterialType = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { getText } = useLang()
  const params = useParams()
  const id = params.id ? parseInt(params.id, 10) : 0
  const size = 5

  const [getMaterialType, { data, loading }] = useLazyQuery(GET_MATERIALTYPE)

  const [
    geCraftmen,
    { data: dataCraftmen, loading: loadingCraftmen, refetch },
  ] = useLazyQuery(GET_CRAFTMEN)

  const colorsGlobal = [colors.blue[400], colors.green[400]]
  const [query, setQuery] = useState("")
  const [page, setPage] = useState(0)

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [{ name: { search: query } }, { email: { search: query } }],
        }
      : {}
  }, [query])

  useEffect(() => {
    geCraftmen({
      variables: {
        take: size,
        skip: page * size,
        orderBy: {
          NoticeOrders: {
            _count: SortOrder.Desc,
          },
        },
        where: {
          role: {
            equals: Role.Craftman,
          },
          MaterialTypes: {
            some: {
              id: {
                equals: id,
              },
            },
          },
          ...queryName(),
        },
      },
    })
  }, [id, page, queryName])

  useEffect(() => {
    getMaterialType({
      variables: {
        where: {
          id,
        },
        aggregateOrderWhere2: {
          MaterialTypes: {
            some: {
              id: {
                equals: id,
              },
            },
          },
        },
        aggregateUserWhere2: {
          role: {
            equals: Role.Craftman,
          },
          MaterialTypes: {
            some: {
              id: {
                equals: id,
              },
            },
          },
        },
      },
    })
  }, [id])

  const handleEdit = (id: number) => {
    dispatch(setTitle(getText("Modifier un type matière")))
    navigate(`/material-type/edit/${id}`)
  }

  const getStatistic = useCallback(() => {
    const value_order = data?.aggregateOrder?._count?.id || 0
    const value_craftman = data?.aggregateUser?._count?.id || 0
    return !value_order && !value_craftman
      ? []
      : [
          {
            label: getText("Nombre commande"),
            value: value_order,
            color: colorsGlobal[0],
          },
          {
            label: getText("Nombre artisan"),
            value: value_craftman,
            color: colorsGlobal[1],
          },
        ]
  }, [data, getText])

  const handleGoDetailCratman = (id: number) => {
    dispatch(setTitle(getText("Detail d'un artisan")))
    navigate(`/craftman/detail/${id}`)
  }

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      { accessorKey: "id", header: "ID", enableEditing: false, size: 80 },
      {
        accessorKey: "email",
        header: getText("Email"),
      },
      {
        accessorKey: "lastName",
        header: getText("Prénom"),
      },
    ],
    [],
  )

  useEffect(() => {
    const countryIds = data?.materialType?.Countries.map((el) => el.id)
    if (!isSuperAdmin()) {
      if (countryIds) {
        if (!countryIds.includes(getUserCountryId())) {
          navigate("/")
        }
      }
    }
  }, [id, data])

  const handleAddCraftman = () => {
    dispatch(setTitle(getText("Ajouter un artisan")))
    navigate(`/craftman/add`)
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  if (loading && !page && !query) return <Loader />

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <HeaderAdd
                    title={getText("Information de type matière")}
                    variant="h4"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                      <Typography>
                        <b>{getText("Nom en fr")}:</b>{" "}
                        {data?.materialType?.name_fr}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <b>{getText("Nom en en")}:</b>{" "}
                        {data?.materialType?.name_en}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <b>{getText("Catégorie")}: </b>{" "}
                        {data?.materialType?.Category?.name_fr}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={3}>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"flex-end"}
                    >
                      {havePermissions(["materialType.change"]) && (
                        <Button
                          onClick={() =>
                            handleEdit(data?.materialType?.id || 0)
                          }
                          sx={{ mr: 1 }}
                          color="info"
                          variant="outlined"
                          size="small"
                        >
                          {getText("Modifier")}
                        </Button>
                      )}
                      {havePermissions(["craftman.create"]) && (
                        <Button
                          onClick={handleAddCraftman}
                          color="success"
                          variant="outlined"
                          size="small"
                        >
                          {getText("Ajouter un artisan")}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <HeaderAdd
                    title={getText("Statistique de type matière")}
                    variant="h4"
                  />
                </Grid>
                <Grid item xs={12}>
                  {getStatistic().length ? (
                    <Chart data={getStatistic()} />
                  ) : (
                    <NoDataStatistic />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <HeaderAdd title={getText("Liste des artisans")} variant="h4" />
            </Grid>
            <Grid item xs={12} mt={2}>
              <CustomTable
                columns={columns}
                data={dataCraftmen?.users || []}
                lableAddNew=""
                rootLisName={""}
                isLoading={loadingCraftmen}
                showProgressBars={loadingCraftmen}
                rowCount={data?.aggregateUser._count?.id || 0}
                handleChangePagination={(value) => setPage(value.page)}
                pageIndex={page}
                pageSize={size}
                searchValue={query}
                onChangeSearchValue={setQuery}
                isHaveView={true}
                handleView={handleGoDetailCratman}
                rowsPerPageOptions={[size]}
                handleRefresh={handleRefresh}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DetailMaterialType
