import React, { useCallback } from "react"
import { Box, Typography } from "@mui/material"
import { ChatMessage, ContentMessageType } from "../../../../../../gql/graphql"
import { capitalizeFirstLetter } from "../../../../../../utils/methodeString"
import useLang from "../../../../../../hooks/useLang"

interface Props {
  message: ChatMessage | string
}

const LabelNewArtisan = ({ message }: Props) => {
  const { getText } = useLang()
  const messageText = typeof message === "string" ? message : message?.content
  const contentType =
    typeof message === "string" ? ContentMessageType.Text : message.contentType

  const getCategoryName = useCallback(() => {
    if (messageText) {
      const content = JSON.parse(messageText)
      if (content.label === "LABEL_ORDER_NEW_CRAFTMAN") {
        const data = content.data
        const category = data[0]
        const name = category.name_fr

        return capitalizeFirstLetter(name.toLocaleLowerCase())
      }
    }
    return ""
  }, [message])

  if (!messageText || contentType != ContentMessageType.Infotext) return null

  return (
    <Box
      justifyContent={"center"}
      display={"flex"}
      marginTop={"10px"}
      marginBottom={"10px"}
    >
      <Typography style={{ textAlign: "center", fontSize: "11px" }} variant="caption">
        «{getText("La commande a été reattribue à un autre")} {getCategoryName()}»
      </Typography>
    </Box>
  )
}
export default LabelNewArtisan
