import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useEffect, useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { updateCacheItemType } from "../../../caches/updateCacheItemType"
import FormItemType from "../FormItemType/FormItemType"
import Loader from "../../Common/Loader/Loader"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { isSuperAdmin } from "../../../utils/permissions"
import { getUserCountryId } from "../../../utils/authToken"
import useLang from "../../../hooks/useLang"

const editItemTypeMutation = graphql(`
  mutation editItemTypeMutation(
    $data: ItemTypeUpdateInput!
    $where: ItemTypeWhereUniqueInput!
  ) {
    updateOneItemType(data: $data, where: $where) {
      id
      name_fr
      name_en
      weight
      Countries {
        id
        name_fr
      }
      Category {
        name_fr
        id
        name_en
      }
    }
  }
`)

const getOneItemTypeQuery = graphql(`
  query getOneItemTypeQuery($where: ItemTypeWhereUniqueInput!) {
    itemType(where: $where) {
      id
      name_fr
      name_en
      weight
      Countries {
        id
        name_fr
      }
      Category {
        id
        name_fr
        name_en
      }
    }
  }
`)

export default function EditItemType() {
  const navigate = useNavigate()
  const { getText } = useLang()
  const dispatch = useAppDispatch()
  const params = useParams()
  const [existingCountryIds, setExistingCountryIds] = useState<number[]>([])
  const id = params.id ? parseInt(params.id, 10) : 0

  const [itemType, setItemType] = useState({
    name_fr: "",
    name_en: "",
    weight: "",
  })

  const [category, setCategory] = useState<InfinityListItem>({
    label: "",
    value: "",
  })
  const [country, setCountry] = useState<InfinityListItem[]>([])

  const getCategory = (value: InfinityListItem) => {
    setCategory(value)
  }

  const [getItemType, { loading: loadingInit, error }] = useLazyQuery(
    getOneItemTypeQuery,
    {
      onCompleted(data) {
        const id = data?.itemType?.Category?.id
        const countries = data.itemType?.Countries
        const countryIds = countries?.map((el) => el.id)
        if (!isSuperAdmin()) {
          if (countryIds) {
            if (!countryIds.includes(getUserCountryId())) {
              navigate("/")
              return
            }
          }
        }
        if (countries && countries.length) {
          setCountry([
            ...countries.map((el) => ({
              label: el.name_fr,
              value: el.id.toString(),
            })),
          ])
          setExistingCountryIds([...countries.map((el) => el.id)])
        }
        setItemType({
          name_en: data?.itemType?.name_en || "",
          name_fr: data?.itemType?.name_fr || "",
          weight: data?.itemType?.weight?.toString() || "",
        })
        setCategory({
          label: data?.itemType?.Category?.name_fr || "",
          value: id ? String(id) : "",
        })
      },
    },
  )

  const [updateItemType, { loading }] = useMutation(editItemTypeMutation)

  const handleInputChange = (event: any) => {
    setItemType({
      ...itemType,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateItemType = (e: any) => {
    e.preventDefault()
    if (!country.length) {
      dispatch(
        setOpenSnackbar({
          message: getText("Veuillez préciser le pays, s'il vous plait."),
        }),
      )
      return
    }
    updateItemType({
      variables: {
        data: {
          name_en: {
            set: itemType.name_en,
          },
          name_fr: {
            set: itemType.name_fr,
          },
          weight: {
            set: Number(itemType.weight),
          },
          Category: {
            connect: { id: Number(category.value) },
          },
          Countries: {
            ...(isSuperAdmin() && {
              disconnect: existingCountryIds.map((id) => ({ id })),
            }),
            connect: country.map((el) => ({ id: +el.value })),
          },
        },
        where: {
          id,
        },
      },
      onCompleted: () => {
        navigate("/item-type/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheItemType({
          action: "update",
          cache,
          entryData: data?.updateOneItemType,
        })
      },
    })
  }

  const getCountry = (value: InfinityListItem[]) => {
    if (value) {
      setCountry(value)
    } else {
      setCountry([])
    }
  }

  useEffect(() => {
    getItemType({
      variables: {
        where: {
          id,
        },
      },
    })
  }, [id])

  if (loadingInit) return <Loader />

  if (error) return "error"

  return (
    <FormItemType
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateItemType}
      loading={loading}
      title={getText("Modifier un type article")}
      getCategory={getCategory}
      category={category}
      countryDefault={country}
      getCountry={getCountry}
      {...itemType}
    />
  )
}
