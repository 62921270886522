import { styled, useTheme } from "@mui/material/styles"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Badge from "@mui/material/Badge"
import MenuIcon from "@mui/icons-material/Menu"
import NotificationsIcon from "@mui/icons-material/Notifications"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  selectMenuStatus,
  selectMenuTitle,
  toogleOpen,
} from "../../../store/features/menu/menuSlice"
import { Box, Popover, Tab, useMediaQuery } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { graphql } from "../../../gql"
import { getUserId } from "../../../utils/authToken"
import Loader from "../../Common/Loader/Loader"
import { useLazyQuery, useQuery } from "@apollo/client"
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks"
import React, { useEffect } from "react"
import { Notification, SortOrder } from "../../../gql/graphql"
import CardNotification from "./CardNotification/CardNotification"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import useLang from "../../../hooks/useLang"
import LanguageDropdown from "../../Common/LanguageDropdown/LanguageDropdown"

const drawerWidth: number = 240

const GET_NOTIFICATION = graphql(`
  query CountNewNotification($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      countNewNotification
      countNotification
    }
  }
`)

const GET_USER_NOTIFICATIONS = graphql(`
  query UserNotification(
    $where: UserWhereUniqueInput!
    $orderBy: [NotificationOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
    $notificationsWhere2: NotificationWhereInput
  ) {
    user(where: $where) {
      id
      countNotification
      Notifications(
        orderBy: $orderBy
        take: $take
        skip: $skip
        where: $notificationsWhere2
      ) {
        body_en
        body_fr
        data
        id
        read
        title_en
        receiverId
        title_fr
        type
        createdAt
      }
    }
  }
`)

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  "& .MuiToolbar-root": {
    minHeight: "50px",
  },
  backgroundColor: "#5D490E",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export default function AppBarBody() {
  const open = useAppSelector(selectMenuStatus)
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const title = useAppSelector(selectMenuTitle)
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const [value, setValue] = React.useState("1")
  const { getText } = useLang()

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  })
  const styleMenuIcon = {
    background: "none",
    borderRadius: "5px",
  }

  const { data, loading } = useQuery(GET_NOTIFICATION, {
    variables: {
      where: {
        id: getUserId(),
      },
    },
  })

  const [
    getNotifications,
    { data: dataNotifications, loading: loadingNotifications, refetch },
  ] = useLazyQuery(GET_USER_NOTIFICATIONS)

  useEffect(() => {
    refetch()
  }, [popupState.isOpen])

  useEffect(() => {
    getNotifications({
      variables: {
        where: {
          id: getUserId(),
        },
        notificationsWhere2: {
          read: {
            equals: value == "1",
          },
        },
        orderBy: [
          {
            createdAt: SortOrder.Desc,
          },
          {
            read: SortOrder.Desc,
          },
        ],
      },
    })
  }, [value])

  return (
    <AppBar position="absolute" open={isMobile ? false : open} elevation={0}>
      <Toolbar
        sx={{
          pr: "24px",
          alignItems: "center",
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => dispatch(toogleOpen())}
          sx={{
            marginRight: "36px",
            ...(isMobile ? {} : open && { display: "none" }),
          }}
        >
          {isMobile && open ? (
            <CloseIcon sx={styleMenuIcon} />
          ) : (
            <MenuIcon sx={styleMenuIcon} />
          )}
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1, color: "white" }}
        >
          {title}
        </Typography>
        <Box sx={{ mx: 2 }}>
          <LanguageDropdown />
        </Box>
        <IconButton
          color="inherit"
          disabled={loading || !data?.user?.countNotification}
          {...bindTrigger(popupState)}
        >
          {loading ? (
            <Loader size={20} />
          ) : (
            <Badge
              badgeContent={data?.user?.countNewNotification}
              color="error"
            >
              <NotificationsIcon color="action" />
            </Badge>
          )}
        </IconButton>
      </Toolbar>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              textColor="secondary"
              indicatorColor="secondary"
              variant="fullWidth"
              centered
            >
              <Tab
                label={getText("VU")}
                value="1"
                sx={{ color: "whitesmoke" }}
              />
              <Tab
                label={getText("NON VU")}
                value="2"
                sx={{ color: "whitesmoke" }}
              />
            </TabList>
          </Box>
          <Box
            sx={{
              maxHeight: "88vh",
            }}
          >
            {loadingNotifications ? (
              <Box
                sx={{
                  m: 2,
                  width: 260,
                }}
              >
                <Loader size={30} />
              </Box>
            ) : (
              dataNotifications?.user?.Notifications.map((notification) => (
                <CardNotification
                  notification={notification as Notification}
                  key={notification.id}
                  handleClose={popupState.close}
                />
              ))
            )}
          </Box>
        </TabContext>
      </Popover>
    </AppBar>
  )
}
