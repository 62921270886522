import { useIntl } from "react-intl"
import { useAppDispatch, useAppSelector } from "../store/app/hooks"
import { Lang, selectLang, setLang } from "../store/features/lang/langSlice"

const useLang = () => {
  const dispatch = useAppDispatch()
  const lang = useAppSelector(selectLang)
  const intl = useIntl()

  const toogleLang = ({ lang }: Lang) => {
    localStorage.setItem("lang", lang)
    dispatch(setLang(lang))
  }

  const getPrice = ({
    price,
    currency = "XOF",
  }: {
    price: string | number
    currency?: string
  }) => {
    const priceValue = intl.formatNumber(Number(price), {
      currency: currency,
      currencyDisplay: "narrowSymbol",
      style: "currency",
    })
    return priceValue
  }

  const getText = (key: any, char: string = "", values?: any) => {
    if (!key) return ""
    return `${intl.formatMessage({ id: key }, values)} ${char}`
  }

  const getByLang = ({
    value_fr,
    value_en,
    value_wl,
    value_mg,
    value_dj,
    value_ba,
  }: {
    value_fr: string
    value_en?: string | null
    value_wl?: string | null
    value_mg?: string | null
    value_dj?: string | null
    value_ba?: string | null
  }) => {
    if (lang === "dyu") {
      return value_dj || value_fr
    }
    if (lang == "en") {
      return value_en || value_fr
    }
    if (lang == "mg") {
      return value_mg || value_fr
    }
    if (lang == "wo") {
      return value_wl || value_fr
    }
    if (lang == "ba") {
      return value_ba || value_fr
    }
    return value_fr || ""
  }
  return {
    toogleLang,
    getText,
    getByLang,
    lang,
    getPrice,
  }
}

export default useLang
