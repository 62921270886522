import { RootState } from "../../app/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type LANG = "fr" | "en" | "mg" | "dyu" | "wo" | "ba";

export interface Lang {
  lang: LANG;
}

const initialState = {
  lang: "fr",
} as Lang;

const langSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    setLang: (state, action: PayloadAction<LANG>) => {
      state.lang = action.payload || "fr";
    },
  },
});

export const { setLang } = langSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectLang = (state: RootState) => state.lang.lang;

export default langSlice.reducer;
