import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useEffect, useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { updateCacheCountry } from "../../../caches/updateCacheCountry"
import FormCountry from "../FormCountry/FormCountry"
import Loader from "../../Common/Loader/Loader"
import useLang from "../../../hooks/useLang"

const editCountryMutation = graphql(`
  mutation updateOneCountry(
    $data: CountryUpdateInput!
    $where: CountryWhereUniqueInput!
  ) {
    updateOneCountry(data: $data, where: $where) {
      id
      name_fr
      name_en
      alpha2
      alpha3
      currency
      isOpen
      minPrice
      ceedowCommission
    }
  }
`)

const getOneCountryQuery = graphql(`
  query getOneCountryQuery($where: CountryWhereUniqueInput!) {
    country(where: $where) {
      id
      name_en
      name_fr
      alpha2
      alpha3
      isOpen
      currency
      minPrice
      ceedowCommission
    }
  }
`)

export default function EditCountry() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const params = useParams()
  const { getText } = useLang()
  const id = params.id ? parseInt(params.id, 10) : 0

  const [country, setCountry] = useState({
    name_fr: "",
    name_en: "",
    currency: "",
    alpha2: "",
    alpha3: "",
    isOpen: "no",
    minPrice: "",
    ceedowCommission: ""
  })

  const [getCountry, { loading: loadingInit, error }] = useLazyQuery(
    getOneCountryQuery,
    {
      onCompleted(data) {
        setCountry({
          name_en: data?.country?.name_en || "",
          name_fr: data?.country?.name_fr || "",
          alpha2: data?.country?.alpha2 || "",
          alpha3: data?.country?.alpha3 || "",
          currency: data?.country?.currency || "",
          isOpen: data?.country?.isOpen ? "yes" : "no",
          minPrice: data?.country?.minPrice?.toString() || "",
          ceedowCommission: data?.country?.ceedowCommission?.toString() || "",
        })
      },
    },
  )

  const [updateCountry, { loading }] = useMutation(editCountryMutation)

  const handleInputChange = (event: any) => {
    setCountry({
      ...country,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateCountry = (e: any) => {
    e.preventDefault()
    updateCountry({
      variables: {
        data: {
          name_en: {
            set: country.name_en,
          },
          name_fr: {
            set: country.name_fr,
          },
          alpha2: {
            set: country.alpha2,
          },
          alpha3: {
            set: country.alpha3,
          },
          currency: {
            set: country.currency,
          },
          isOpen: {
            set: country.isOpen === "yes",
          },
          ceedowCommission: {
            set: parseFloat(country.ceedowCommission)
          },
          minPrice:{
            set: parseFloat(country.minPrice)
          }
        },
        where: {
          id,
        },
      },
      onCompleted: () => {
        navigate("/country/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheCountry({
          action: "update",
          cache,
          entryData: data?.updateOneCountry,
        })
      },
    })
  }

  useEffect(() => {
    getCountry({
      variables: {
        where: {
          id,
        },
      },
    })
  }, [id])

  if (loadingInit) return <Loader />

  if (error) return "error"

  return (
    <FormCountry
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateCountry}
      loading={loading}
      name_en={country.name_en}
      name_fr={country.name_fr}
      alpha3={country.alpha3}
      alpha2={country.alpha2}
      currency={country.currency}
      minPrice={country.minPrice}
      title={getText("Modifier un pays")}
      isOpen={country.isOpen as "yes" | "no"}
      isEdit={false}
      ceedowCommission={country.ceedowCommission}
    />
  )
}
