import "./App.css"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Dashboard from "./components/Dashboard/Dashboard"
import Layout from "./components/Layout/Layout"
import Login from "./components/Login/Login"
import ListUser from "./components/User/ListUser/ListUser"
import AddCountry from "./components/Country/AddCountry/AddCountry"
import ListCountry from "./components/Country/ListCountry/ListCountry"
import AddAdmin from "./components/Admin/AddAdmin/AddAdmin"
import ListAdmin from "./components/Admin/ListAdmin/ListAdmin"
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute"
import DetailCategory from "./components/Category/DetailCategory/DetailCategory"
import AddCategory from "./components/Category/AddCategory/AddCategory"
import ListCategory from "./components/Category/ListCategory/ListCategory"
import ListOrder from "./components/Order/ListOrder/ListOrder"
import AddOrder from "./components/Order/AddOrder/AddOrder"
import DetailOrder from "./components/Order/DetailOrder/DetailOrder"
import AddCraftman from "./components/Craftman/AddCraftman/AddCraftman"
import ListCraftman from "./components/Craftman/ListCraftman/ListCraftman"
import DetailCraftman from "./components/Craftman/DetailCraftman/DetailCraftman"
import ListSegmentation from "./components/Segmentation/ListSegmentation/ListSegmentation"
import AddSegmentation from "./components/Segmentation/AddSegmentation/AddSegmentation"
import EditSegmentation from "./components/Segmentation/EditSegmentation/EditSegmentation"
import DetailSegmentation from "./components/Segmentation/DetailSegmentation/DetailSegmentation"
import AddMaterialType from "./components/MaterialType/AddMaterialType/AddMaterialType"
import AddItemType from "./components/ItemType/AddItemType/AddItemType"
import DetailItemType from "./components/ItemType/DetailItemType/DetailItemType"
import ListMaterialType from "./components/MaterialType/ListMaterialType/ListMaterialType"
import ListItemType from "./components/ItemType/ListItemType/ListItemType"
import DetailMaterialType from "./components/MaterialType/DetailMaterialType/DetailMaterialType"
import DetailEmail from "./components/Email/DetailEmail/DetailEmail"
import ListEmail from "./components/Email/ListEmail/ListEmail"
import EditCategory from "./components/Category/EditCategory/EditCategory"
import EditCountry from "./components/Country/EditCountry/EditCountry"
import DetailCountry from "./components/Country/DetailCountry/DetailCountry"
import EditMaterialType from "./components/MaterialType/EditMaterialType/EditMaterialType"
import EditItemType from "./components/ItemType/EditItemType/EditItemType"
import EditAdmin from "./components/Admin/EditAdmin/EditAdmin"
import EditCraftman from "./components/Craftman/EditCraftman/EditCraftman"
import EditOrder from "./components/Order/EditOrder/EditOrder"
import EditUser from "./components/User/EditUser/EditUser"
import AddCustomer from "./components/User/AddUser/AddUser"
import DetailUser from "./components/User/DetailUser/DetailUser"
import EditOrderHistory from "./components/Order/EditOrderHistory/EditOrderHistory"
import WalletCraftman from "./components/Craftman/WalletCraftman/WalletCraftman"
import ListCountryCraftman from "./components/Country/ListCountryCraftman/ListCountryCraftman"
import EditFaqs from "./components/Faqs/EditFaqs/EditFaqs"
import AddFaqs from "./components/Faqs/AddFaqs/AddFaqs"
import ListFaqs from "./components/Faqs/ListFaqs/ListFaqs"
import ListTransporter from "./components/Transporter/ListTransporter/ListTransporter"
import AddTransporter from "./components/Transporter/AddTransporter/AddTransporter"
import EditTransporter from "./components/Transporter/EditTransporter/EditTransporter"
import ListTransporterPrice from "./components/TransporterPrice/ListTransporterPrice/ListTransporterPrice"
import AddTransporterPrice from "./components/TransporterPrice/AddTransporterPrice/AddTransporterPrice"
import EditTransporterPrice from "./components/TransporterPrice/EditTransporterPrice/EditTransporterPrice"
import ListTransporterZone from "./components/TransporterZone/ListTransporterZone/ListTransporterZone"
import AddTransporterZone from "./components/TransporterZone/AddTransporterZone/AddTransporterZone"
import EditTransporterZone from "./components/TransporterZone/EditTransporterZone/EditTransporterZone"
import NotFound from "./NotFound/NotFound"
import PaimentOrderHistory from "./components/Order/PaimentOrderHistory/PaimentOrderHistory"

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <ProtectedRoute isProtected={false} permissions={[]}>
        <Login />
      </ProtectedRoute>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/",
    element: (
      <ProtectedRoute isInitRoute={true} permissions={[]}>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "country/add",
        element: (
          <ProtectedRoute permissions={["country.create"]}>
            <AddCountry />
          </ProtectedRoute>
        ),
      },
      {
        path: "country/edit/:id",
        element: (
          <ProtectedRoute permissions={["country.change"]}>
            <EditCountry />
          </ProtectedRoute>
        ),
      },
      {
        path: "country/detail/:id",
        element: (
          <ProtectedRoute permissions={["country.view"]}>
            <DetailCountry />
          </ProtectedRoute>
        ),
      },
      {
        path: "country/list",
        element: (
          <ProtectedRoute permissions={["country.view"]}>
            <ListCountry />
          </ProtectedRoute>
        ),
      },
      {
        path: "country/craftman-list",
        element: (
          <ProtectedRoute permissions={["country.view"]}>
            <ListCountryCraftman />
          </ProtectedRoute>
        ),
      },
      {
        path: "user/edit/:id",
        element: (
          <ProtectedRoute permissions={["customer.change"]}>
            <EditUser />
          </ProtectedRoute>
        ),
      },
      {
        path: "user/detail/:id",
        element: (
          <ProtectedRoute permissions={["customer.view"]}>
            <DetailUser />
          </ProtectedRoute>
        ),
      },
      {
        path: "user/add",
        element: (
          <ProtectedRoute permissions={["customer.create"]}>
            <AddCustomer />
          </ProtectedRoute>
        ),
      },
      {
        path: "user/list",
        element: (
          <ProtectedRoute permissions={["customer.view"]}>
            <ListUser />
          </ProtectedRoute>
        ),
      },
      {
        path: "admin/add",
        element: (
          <ProtectedRoute permissions={["admin.create"]}>
            <AddAdmin />
          </ProtectedRoute>
        ),
      },
      {
        path: "admin/edit/:id",
        element: (
          <ProtectedRoute permissions={["admin.change"]}>
            <EditAdmin />
          </ProtectedRoute>
        ),
      },
      {
        path: "admin/list",
        element: (
          <ProtectedRoute permissions={["admin.view"]}>
            <ListAdmin />
          </ProtectedRoute>
        ),
      },
      {
        path: "category/list",
        element: (
          <ProtectedRoute permissions={["category.view"]}>
            <ListCategory />
          </ProtectedRoute>
        ),
      },
      {
        path: "category/add",
        element: (
          <ProtectedRoute permissions={["category.create"]}>
            <AddCategory />
          </ProtectedRoute>
        ),
      },
      {
        path: "category/detail/:id",
        element: (
          <ProtectedRoute permissions={["category.view"]}>
            <DetailCategory />
          </ProtectedRoute>
        ),
      },
      {
        path: "category/edit/:id",
        element: (
          <ProtectedRoute permissions={["category.change"]}>
            <EditCategory />
          </ProtectedRoute>
        ),
      },
      {
        path: "order/list",
        element: (
          <ProtectedRoute permissions={["order.view"]}>
            <ListOrder />
          </ProtectedRoute>
        ),
      },
      {
        path: "order/add",
        element: (
          <ProtectedRoute permissions={["order.create"]}>
            <AddOrder />
          </ProtectedRoute>
        ),
      },
      {
        path: "order/edit-story/:id",
        element: (
          <ProtectedRoute permissions={["order.view"]}>
            <EditOrderHistory />
          </ProtectedRoute>
        ),
      },
      {
        path: "order/payment-story/:id",
        element: (
          <ProtectedRoute permissions={["order.view"]}>
            <PaimentOrderHistory />
          </ProtectedRoute>
        ),
      },
      {
        path: "order/detail/:id",
        element: (
          <ProtectedRoute permissions={["order.view"]}>
            <DetailOrder />
          </ProtectedRoute>
        ),
      },
      {
        path: "order/edit/:id",
        element: (
          <ProtectedRoute permissions={["order.change"]}>
            <EditOrder />
          </ProtectedRoute>
        ),
      },

      {
        path: "transporter/list",
        element: (
          <ProtectedRoute permissions={["transporter.view"]}>
            <ListTransporter />
          </ProtectedRoute>
        ),
      },
      {
        path: "transporter/add",
        element: (
          <ProtectedRoute permissions={["transporter.create"]}>
            <AddTransporter />
          </ProtectedRoute>
        ),
      },
      {
        path: "transporter/edit/:id",
        element: (
          <ProtectedRoute permissions={["transporter.change"]}>
            <EditTransporter />
          </ProtectedRoute>
        ),
      },
      {
        path: "transporter-price/list",
        element: (
          <ProtectedRoute permissions={["transporterPrice.view"]}>
            <ListTransporterPrice />
          </ProtectedRoute>
        ),
      },
      {
        path: "transporter-price/add",
        element: (
          <ProtectedRoute permissions={["transporterPrice.create"]}>
            <AddTransporterPrice />
          </ProtectedRoute>
        ),
      },
      {
        path: "transporter-price/edit/:id",
        element: (
          <ProtectedRoute permissions={["transporterPrice.change"]}>
            <EditTransporterPrice />
          </ProtectedRoute>
        ),
      },
      {
        path: "transporter-zone/list",
        element: (
          <ProtectedRoute permissions={["transporterZone.view"]}>
            <ListTransporterZone />
          </ProtectedRoute>
        ),
      },
      {
        path: "transporter-zone/add",
        element: (
          <ProtectedRoute permissions={["transporterZone.create"]}>
            <AddTransporterZone />
          </ProtectedRoute>
        ),
      },
      {
        path: "transporter-zone/edit/:id",
        element: (
          <ProtectedRoute permissions={["transporterZone.change"]}>
            <EditTransporterZone />
          </ProtectedRoute>
        ),
      },
      {
        path: "craftman/list",
        element: (
          <ProtectedRoute permissions={["craftman.view"]}>
            <ListCraftman />
          </ProtectedRoute>
        ),
      },
      {
        path: "craftman/add",
        element: (
          <ProtectedRoute permissions={["craftman.create"]}>
            <AddCraftman />
          </ProtectedRoute>
        ),
      },
      {
        path: "craftman/wallet/:id",
        element: (
          <ProtectedRoute permissions={["craftman.view"]}>
            <WalletCraftman />
          </ProtectedRoute>
        ),
      },
      {
        path: "craftman/detail/:id",
        element: (
          <ProtectedRoute permissions={["craftman.view"]}>
            <DetailCraftman />
          </ProtectedRoute>
        ),
      },
      {
        path: "craftman/edit/:id",
        element: (
          <ProtectedRoute permissions={["craftman.change"]}>
            <EditCraftman />
          </ProtectedRoute>
        ),
      },
      {
        path: "segmentation/list",
        element: (
          <ProtectedRoute permissions={["segmentation.view"]}>
            <ListSegmentation />
          </ProtectedRoute>
        ),
      },
      {
        path: "segmentation/add",
        element: (
          <ProtectedRoute permissions={["segmentation.create"]}>
            <AddSegmentation />
          </ProtectedRoute>
        ),
      },
      {
        path: "segmentation/edit/:id",
        element: (
          <ProtectedRoute permissions={["segmentation.change"]}>
            <EditSegmentation />
          </ProtectedRoute>
        ),
      },
      {
        path: "faqs/list",
        element: (
          <ProtectedRoute permissions={["faqs.view"]}>
            <ListFaqs />
          </ProtectedRoute>
        ),
      },
      {
        path: "faqs/add",
        element: (
          <ProtectedRoute permissions={["faqs.create"]}>
            <AddFaqs />
          </ProtectedRoute>
        ),
      },
      {
        path: "faqs/edit/:id",
        element: (
          <ProtectedRoute permissions={["faqs.change"]}>
            <EditFaqs />
          </ProtectedRoute>
        ),
      },
      {
        path: "segmentation/detail/:id",
        element: (
          <ProtectedRoute permissions={["segmentation.view"]}>
            <DetailSegmentation />
          </ProtectedRoute>
        ),
      },
      {
        path: "item-type/list",
        element: (
          <ProtectedRoute permissions={["itemType.view"]}>
            <ListItemType />
          </ProtectedRoute>
        ),
      },
      {
        path: "item-type/add",
        element: (
          <ProtectedRoute permissions={["itemType.create"]}>
            <AddItemType />
          </ProtectedRoute>
        ),
      },
      {
        path: "item-type/detail/:id",
        element: (
          <ProtectedRoute permissions={["itemType.view"]}>
            <DetailItemType />
          </ProtectedRoute>
        ),
      },
      {
        path: "item-type/edit/:id",
        element: (
          <ProtectedRoute permissions={["itemType.change"]}>
            <EditItemType />
          </ProtectedRoute>
        ),
      },
      {
        path: "material-type/list",
        element: (
          <ProtectedRoute permissions={["materialType.view"]}>
            <ListMaterialType />
          </ProtectedRoute>
        ),
      },
      {
        path: "material-type/add",
        element: (
          <ProtectedRoute permissions={["materialType.create"]}>
            <AddMaterialType />
          </ProtectedRoute>
        ),
      },
      {
        path: "material-type/detail/:id",
        element: (
          <ProtectedRoute permissions={["materialType.view"]}>
            <DetailMaterialType />
          </ProtectedRoute>
        ),
      },
      {
        path: "material-type/edit/:id",
        element: (
          <ProtectedRoute permissions={["materialType.change"]}>
            <EditMaterialType />
          </ProtectedRoute>
        ),
      },
      {
        path: "email/list",
        element: (
          <ProtectedRoute permissions={["email.view"]}>
            <ListEmail />
          </ProtectedRoute>
        ),
      },
      {
        path: "email/detail/:id",
        element: (
          <ProtectedRoute permissions={["email.view"]}>
            <DetailEmail />
          </ProtectedRoute>
        ),
      },
    ],
  },
])

function Router() {
  return <RouterProvider router={router} />
}

export default Router
