import { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  selectUserPagination,
  selectUserQuery,
  setUserPagination,
  setQuery,
} from "../../../store/features/user/userSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import {
  SortOrder,
  User,
  Role,
  UserWhereInput,
  QueryMode,
} from "../../../gql/graphql"
import { MRT_ColumnDef, MRT_ColumnFiltersState } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { updateCacheCraftman } from "../../../caches/updateCacheCraftman"
import { havePermissions } from "../../../utils/permissions"
import moment from "moment"
import CustomImage from "../../Common/CustomImage/CustomImage"
import { getUserCountryId } from "../../../utils/authToken"
import { whereInputClause } from "../../../utils/permissions"
import useLang from "../../../hooks/useLang"

export const LIST_ARTISANS = graphql(`
  query Craftmans(
    $where: UserWhereInput
    $aggregateUserWhere2: UserWhereInput
    $orderBy: [UserOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    users(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      oldID
      phoneNumber
      email
      role
      name
      lastName
      lang
      workshopName
      yearOfExperience
      dateOfLastConnexion
      jackpotCraftman
      ProfilePhoto {
        url
      }
      PhotoAchievements {
        url
      }
      Categories {
        id
        name_fr
        name_en
      }
      ItemTypes {
        id
        name_fr
        name_en
      }
      MaterialTypes {
        id
        name_fr
        name_en
      }
      country {
        id
        name_fr
        currency
        name_en
      }
    }
    aggregateUser(where: $aggregateUserWhere2) {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_CRAFTMAN = graphql(`
  mutation DeleteOneCraftman($where: UserWhereUniqueInput!) {
    deleteOneUser(where: $where) {
      id
      phoneNumber
      email
      name
      lastName
      role
    }
  }
`)

const LIST_FILTER = graphql(`
  query filtersListCraftman {
    categories {
      id
      name_fr
      name_en
    }
    itemTypes {
      name_fr
      name_en
      id
    }
    materialTypes {
      name_fr
      name_en
      id
    }
    countries {
      name_fr
      name_en
      id
    }
  }
`)

const ListCraftman = () => {
  const { page, size } = useAppSelector(selectUserPagination)
  const query = useAppSelector(selectUserQuery)
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
  const dispatch = useAppDispatch()
  const countryId = getUserCountryId()
  const { getText, getByLang } = useLang()
  const { loading: loadingFilter, data: dataFilter } = useQuery(LIST_FILTER)

  const navigate = useNavigate()

  const getValueFilter = (el: {
    name_fr: string
    name_en: string
    id: number
  }) => {
    return {
      label: el.name_fr,
      value: el.id.toString(),
      text: getByLang({
        value_fr: el.name_fr,
        value_en: el.name_en,
      }),
    }
  }

  const getFilters: () => UserWhereInput = useCallback(() => {
    if (columnFilters.length) {
      return columnFilters.reduce(
        (
          accumulator: UserWhereInput,
          { id, value }: { id: string; value: any },
        ) => {
          switch (id) {
            case "workshopName":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    workshopName: {
                      search: value,
                      mode: QueryMode.Insensitive,
                    },
                  },
                  {
                    workshopName: {
                      contains: value,
                      mode: QueryMode.Insensitive,
                    },
                  },
                ],
              }
              break
            case "Categories":
              accumulator = {
                ...accumulator,
                Categories: {
                  some: {
                    id: {
                      equals: Number(value),
                    },
                  },
                },
              }
              break
            case "itemTypes":
              accumulator = {
                ...accumulator,
                ItemTypes: {
                  some: {
                    id: {
                      equals: Number(value),
                    },
                  },
                },
              }
              break
            case "countries":
              accumulator = {
                ...accumulator,
                countryId: {
                  equals: Number(value),
                },
              }
              break
            case "currency":
              accumulator = {
                ...accumulator,
                country: {
                  is: {
                    currency: value,
                  },
                },
              }
              break
            case "name":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    name: {
                      search: value,
                      mode: QueryMode.Insensitive,
                    },
                  },
                  {
                    name: {
                      contains: value,
                      mode: QueryMode.Insensitive,
                    },
                  },
                ],
              }
              break
            case "id":
              accumulator = {
                ...accumulator,
                id: {
                  equals: Number(value),
                },
              }
              break
            case "oldID":
              accumulator = {
                ...accumulator,
                oldID: {
                  equals: Number(value),
                },
              }
              break
            case "lastName":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    lastName: {
                      search: value,
                      mode: QueryMode.Insensitive,
                    },
                  },
                  {
                    lastName: {
                      contains: value,
                      mode: QueryMode.Insensitive,
                    },
                  },
                ],
              }
              break
            case "email":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    email: {
                      search: value,
                      mode: QueryMode.Insensitive,
                    },
                  },
                  {
                    email: {
                      contains: value,
                      mode: QueryMode.Insensitive,
                    },
                  },
                ],
              }
              break
            case "phoneNumber":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    phoneNumber: {
                      search: value,
                      mode: QueryMode.Insensitive,
                    },
                  },
                  {
                    phoneNumber: {
                      contains: value,
                      mode: QueryMode.Insensitive,
                    },
                  },
                ],
              }
              break
            case "lang":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    lang: {
                      search: value,
                      mode: QueryMode.Insensitive,
                    },
                  },
                  {
                    lang: {
                      contains: value,
                      mode: QueryMode.Insensitive,
                    },
                  },
                ],
              }
              break
            case "dateOfLastConnexion":
              accumulator = {
                ...accumulator,
                dateOfLastConnexion: {
                  gte: value.toDate(),
                },
              }
              break
          }
          return accumulator
        },
        {},
      )
    }
    return {}
  }, [columnFilters])

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            { workshopName: { contains: query, mode: QueryMode.Insensitive } },
            { workshopName: { search: query, mode: QueryMode.Insensitive } },
            { name: { contains: query, mode: QueryMode.Insensitive } },
            { name: { search: query, mode: QueryMode.Insensitive } },
            { lastName: { contains: query, mode: QueryMode.Insensitive } },
            { lastName: { search: query, mode: QueryMode.Insensitive } },
          ],
        }
      : {}
  }, [query])

  const [getCraftmans, { loading, data }] = useLazyQuery(LIST_ARTISANS)

  useEffect(() => {
    getCraftmans({
      variables: {
        take: size,
        skip: page * size,
        orderBy: { id: SortOrder.Desc },
        where: {
          role: {
            equals: Role.Craftman,
          },
          ...whereInputClause({
            countryId: {
              equals: countryId,
            },
          }),
          ...queryName(),
          ...getFilters(),
        },
        aggregateUserWhere2: {
          role: {
            equals: Role.Craftman,
          },
          ...whereInputClause({
            countryId: {
              equals: countryId,
            },
          }),
          ...queryName(),
          ...getFilters(),
        },
      },
    })
  }, [getCraftmans, page, query, queryName, size, getFilters])

  const [deleteCraftman] = useMutation(DELETE_CRAFTMAN)

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorFn: (row) => (
          <>
            <CustomImage
              url={row?.ProfilePhoto?.url}
              style={{ width: "50px", height: "50px", borderRadius: "25px" }}
            />
          </>
        ),
        header: getText("Profile"),
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
        filterVariant: "text",
        id: "id",
      },
      {
        accessorKey: "oldID",
        header: getText("Ancien ID"),
        enableEditing: false,
        enableClickToCopy: true,
        filterVariant: "text",
        id: "oldID",
      },
      {
        accessorKey: "workshopName",
        header: getText("Atélier"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "workshopName",
      },
      {
        accessorKey: "name",
        header: getText("Nom"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "name",
      },
      {
        accessorKey: "lastName",
        header: getText("Prénom"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "lastName",
      },
      {
        accessorKey: "email",
        header: getText("Email"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "email",
      },
      {
        accessorKey: "phoneNumber",
        header: getText("Numéro de telephone"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "phoneNumber",
      },
      {
        accessorFn: (row) =>
          row?.Categories?.flatMap((el) =>
            getByLang({
              value_fr: el.name_fr,
              value_en: el.name_en,
            }),
          ).toString(),
        header: getText("Catégorie"),
        enableClickToCopy: true,
        filterVariant: "select",
        filterSelectOptions: dataFilter?.categories.map((el) =>
          getValueFilter(el),
        ),
        id: "Categories",
      },
      {
        accessorFn: (row) =>
          row?.ItemTypes?.flatMap((el) =>
            getByLang({
              value_fr: el.name_fr,
              value_en: el.name_en,
            }),
          )?.join("; "),
        header: getText("Type article(s)"),
        enableClickToCopy: true,
        filterVariant: "select",
        filterSelectOptions: dataFilter?.itemTypes.map((el) =>
          getValueFilter(el),
        ),
        id: "itemTypes",
      },
      {
        accessorFn: (row) =>
          row?.MaterialTypes?.flatMap((el) =>
            getByLang({
              value_fr: el.name_fr,
              value_en: el.name_en,
            }),
          ).join("; "),
        header: getText("Type matière(s)"),
        enableClickToCopy: true,
        filterVariant: "select",
        filterSelectOptions: dataFilter?.materialTypes.map((el) =>
          getValueFilter(el),
        ),
        id: "materialTypes",
      },
      {
        accessorFn: (row) =>
          getByLang({
            value_fr: row?.country?.name_fr || "",
            value_en: row?.country?.name_en,
          }).toLocaleUpperCase(),
        header: getText("Pays"),
        enableClickToCopy: true,
        filterVariant: "select",
        filterSelectOptions: dataFilter?.countries.map((el) =>
          getValueFilter(el),
        ),
        id: "countries",
      },
      {
        accessorFn: (row) => row?.lang,
        header: getText("Langue"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "lang",
      },
      {
        accessorFn: (row) => row?.jackpotCraftman,
        header: getText("Cagnotte"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "jackpotCraftman",
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => row?.country?.currency,
        header: getText("Devise"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "currency",
      },
      {
        accessorFn: (row) =>
          row?.dateOfLastConnexion
            ? moment(row.dateOfLastConnexion).format("DD MMM YYYY")
            : getText("Pas définie"),
        header: getText("Dernière connexion"),
        enableClickToCopy: true,
        filterVariant: "date",
        id: "dateOfLastConnexion",
      },
    ],
    [dataFilter, getText],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setUserPagination(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteCraftman({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: getText("L'artisan a été supprimé avec succès"),
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheCraftman({
          action: "delete",
          cache,
          entryData: data?.deleteOneUser,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: getText(
          "Êtes-vous vraiment sûr de vouloir supprimer cet artisan ?",
        ),
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) => dispatch(setQuery(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle(getText("Modifier un artisan")))
    navigate(`/craftman/edit/${id}`)
  }

  const handleView = (id: number) => {
    dispatch(setTitle(getText("Detail un artisan")))
    navigate(`/craftman/detail/${id}`)
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  if (loadingFilter) return <Loader />

  return (
    <CustomTable
      enableColumnFilters
      columns={columns}
      data={data?.users || []}
      lableAddNew={getText("Créer une nouveau artisan")}
      rootLisName={"/craftman/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateUser._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={
        havePermissions(["craftman.delete"])
          ? handleOpenDeleteDialog
          : undefined
      }
      isHaveHeaderAction={havePermissions(["craftman.create"])}
      handleEdit={havePermissions(["craftman.change"]) ? handleEdit : undefined}
      isHaveView={havePermissions(["craftman.view"])}
      handleView={havePermissions(["craftman.view"]) ? handleView : undefined}
      isHaveAction={havePermissions([
        "craftman.view",
        "craftman.change",
        "craftman.delete",
      ])}
      handleRefresh={handleRefresh}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
    />
  )
}

export default ListCraftman
