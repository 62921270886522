import { useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useCallback, useEffect, useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { NewFileInput } from "../../../gql/graphql"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { updateCacheOrder } from "../../../caches/updateCacheOrder"
import FormOrder from "../FormOrder/FormOrder"
import Loader from "../../Common/Loader/Loader"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { uploadFiles } from "../../../utils/uploadFiles"
import { MinTypeFile } from "../../Common/CustomUploadFiles/CustomUploadFiles"
import { getStringEmpty } from "../../../utils/methodeString"
import moment, { Moment } from "moment"
import useImage from "../../../hooks/useImage"
import useLang from "../../../hooks/useLang"

const updateOrderMutation = graphql(`
  mutation UpdateCustomerOrder(
    $photosDeleteIds: [Int!]!
    $photosOrders: [NewFileInput!]!
    $categoryId: Float!
    $materialId: Float!
    $itemId: Float!
    $craftmanId: Float!
    $updateCustomerOrderId: Float!
    $customerId: Float
    $deadline: DateTimeISO
    $countryId: Float
    $craftmanCustomPrice: Float
    $ceedowCommissionCustomPrice: Float
    $transporterCustomPrice: Float
    $quantity: Float
  ) {
    updateCustomerOrder(
      photosDeleteIds: $photosDeleteIds
      photosOrders: $photosOrders
      categoryId: $categoryId
      materialId: $materialId
      itemId: $itemId
      customerId: $customerId
      craftmanId: $craftmanId
      id: $updateCustomerOrderId
      deadline: $deadline
      countryId: $countryId
      craftmanCustomPrice: $craftmanCustomPrice
      ceedowCommissionCustomPrice: $ceedowCommissionCustomPrice
      transporterCustomPrice: $transporterCustomPrice
      quantity: $quantity
    ) {
      id
      deadline
      status
      createdAt
      updatedAt
      quantity
      craftmanCustomPrice
      transporterCustomPrice
      ceedowCommissionCustomPrice
      Customer {
        id
        email
        name
        lastName
      }
      Craftman {
        id
        email
        name
        lastName
      }
      Country {
        id
        name_fr
        name_en
        currency
      }
      Categories {
        id
        name_fr
        name_en
      }
      ItemTypes {
        id
        name_fr
        name_en
      }
      MaterialTypes {
        id
        name_fr
        name_en
      }
      Photos {
        id
        name
        url
      }
    }
  }
`)

const getOneOrderQuery = graphql(`
  query GetNewOrder($where: OrderWhereUniqueInput!) {
    getOrder(where: $where) {
      id
      deadline
      craftmanCustomPrice
      transporterCustomPrice
      ceedowCommissionCustomPrice
      quantity
      Customer {
        id
        name
        lastName
      }
      Craftman {
        id
        name
        lastName
      }
      Photos {
        id
        name
        url
      }
      Country {
        id
        name_fr
        name_en
      }
      Categories {
        id
        name_fr
        name_en
      }
      ItemTypes {
        id
        name_fr
        name_en
      }
      MaterialTypes {
        id
        name_fr
        name_en
      }
    }
  }
`)

interface Order {
  deadline: Moment | null
  craftmanCustomPrice: string
  ceedowCommissionCustomPrice: string
  transporterCustomPrice: string
  quantity: string
}

export default function EditOrder() {
  const { getText, getByLang } = useLang()
  const dispatch = useAppDispatch()
  const params = useParams()
  const { getUri } = useImage()
  const id = params.id ? parseInt(params.id, 10) : 0

  const [updateorder, { loading }] = useMutation(updateOrderMutation)
  const [isLoading, setIsLoading] = useState(false)

  const [defaultUrls, setDefaultUrls] = useState<MinTypeFile[]>([])
  const [files, setFiles] = useState<File[]>([])
  const [photosDeleteIds, setPhotosDeleteIds] = useState<number[]>([])
  const [lengthPhotosDefaults, setLengthPhotosDefaults] = useState<number>()

  const defaultValue: InfinityListItem = {
    label: "",
    value: "",
  }

  const [craftman, setCraftman] = useState<InfinityListItem>(defaultValue)
  const [category, setCategory] = useState<InfinityListItem>(defaultValue)
  const [customer, setCustomer] = useState<InfinityListItem>(defaultValue)
  const [country, setCountry] = useState<InfinityListItem>(defaultValue)
  const [itemType, setItemType] = useState<InfinityListItem>(defaultValue)
  const [materialType, setMaterialType] =
    useState<InfinityListItem>(defaultValue)

  const [order, setOrder] = useState<Order>({
    deadline: null,
    craftmanCustomPrice: "",
    transporterCustomPrice: "",
    ceedowCommissionCustomPrice: "",
    quantity: "1",
  })

  const [getOrder, { loading: loadingInit, error }] = useLazyQuery(
    getOneOrderQuery,
    {
      onCompleted(data) {
        const newOrder = data.getOrder
        setOrder({
          deadline: newOrder?.deadline ? moment(newOrder.deadline) : null,
          craftmanCustomPrice: newOrder?.craftmanCustomPrice
            ? newOrder?.craftmanCustomPrice.toString()
            : "",
          transporterCustomPrice: newOrder?.transporterCustomPrice
            ? newOrder?.transporterCustomPrice.toString()
            : "",
          ceedowCommissionCustomPrice: newOrder?.ceedowCommissionCustomPrice
            ? newOrder?.ceedowCommissionCustomPrice.toString()
            : "",
          quantity: newOrder?.quantity ? newOrder?.quantity.toString() : "",
        })

        if (newOrder?.Photos) {
          newOrder?.Photos.forEach((el) => {
            let photos = {
              name: el.name,
              url: getUri(el.url),
              id: el.id,
            }
            setDefaultUrls((prev) => [...prev, photos])
          })
          setLengthPhotosDefaults(newOrder?.Photos.length)
        }
        if (newOrder?.Craftman) {
          const newcraftman: InfinityListItem = {
            value: newOrder?.Craftman.id.toString() || "",
            label: `${newOrder?.Craftman.name} ${getStringEmpty(
              newOrder?.Craftman?.lastName,
            )}`,
          }
          setCraftman(newcraftman)
        }
        if (newOrder?.Customer) {
          const newCustomer: InfinityListItem = {
            value: newOrder?.Customer.id.toString() || "",
            label: `${newOrder?.Customer.name} ${getStringEmpty(
              newOrder?.Customer?.lastName,
            )}`,
          }
          setCustomer(newCustomer)
        }
        if (newOrder?.Country) {
          const newcountry: InfinityListItem = {
            value: newOrder?.Country.id.toString() || "",
            label:
              getByLang({
                value_fr: newOrder?.Country.name_fr,
                value_en: newOrder?.Country.name_en,
              }) || "",
          }
          setCountry(newcountry)
        }
        if (newOrder?.Categories) {
          const newCategories: InfinityListItem[] =
            newOrder?.Categories.map((el) => ({
              value: el.id.toString(),
              label: getByLang({
                value_fr: el.name_fr,
                value_en: el.name_en,
              }),
            })) || []
          setCategory(newCategories.length ? newCategories[0] : defaultValue)
        }
        if (newOrder?.MaterialTypes) {
          const newmaterialTypes: InfinityListItem[] =
            newOrder?.MaterialTypes.map((el) => ({
              value: el.id.toString(),
              label: getByLang({
                value_fr: el.name_fr,
                value_en: el.name_en,
              }),
            })) || []
          setMaterialType(
            newmaterialTypes.length ? newmaterialTypes[0] : defaultValue,
          )
        }
        if (newOrder?.ItemTypes) {
          const newItemTypes: InfinityListItem[] =
            newOrder?.ItemTypes.map((el) => ({
              value: el.id.toString(),
              label: getByLang({
                value_fr: el.name_fr,
                value_en: el.name_en,
              }),
            })) || []
          setItemType(newItemTypes.length ? newItemTypes[0] : defaultValue)
        }
      },
    },
  )

  const handleInputChange = (event: any) => {
    setOrder({
      ...order,
      [event.target.name]: event.target.value,
    })
  }

  const handleInputChangeDeadline = (value: Moment | null) => {
    setOrder({
      ...order,
      deadline: value,
    })
  }

  const handleSignup = ({ photos }: { photos?: NewFileInput[] }) => {
    updateorder({
      variables: {
        updateCustomerOrderId: id,
        categoryId: Number(category.value),
        customerId:
          customer && customer.value ? Number(customer.value) : undefined,
        itemId: Number(itemType.value),
        materialId: Number(materialType.value),
        craftmanId: Number(craftman.value),
        photosDeleteIds,
        deadline: order.deadline ? order.deadline.toDate() : undefined,
        photosOrders: photos || [],
        craftmanCustomPrice: order.craftmanCustomPrice
          ? Number(order.craftmanCustomPrice)
          : undefined,
        ceedowCommissionCustomPrice: order.ceedowCommissionCustomPrice
          ? Number(order.ceedowCommissionCustomPrice)
          : undefined,
        transporterCustomPrice: order.transporterCustomPrice
          ? Number(order.transporterCustomPrice)
          : undefined,
        quantity: order.quantity ? Math.abs(parseInt(order.quantity)) : 1,
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
        setIsLoading(false)
      },
      onCompleted: () => {
        setIsLoading(false)
        window.location.replace("/order/list")
      },
      update: (cache, { data }) => {
        updateCacheOrder({
          action: "update",
          cache,
          entryData: {
            ...data?.updateCustomerOrder,
            __typename: "Order",
          },
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (
      !files.length &&
      lengthPhotosDefaults &&
      photosDeleteIds.length === lengthPhotosDefaults
    ) {
      dispatch(
        setOpenSnackbar({
          message: getText(
            "Veuillez choisir les modèles photos de la commande !!!",
          ),
        }),
      )
      return
    }
    if (!itemType?.value) {
      dispatch(
        setOpenSnackbar({
          message: getText(
            "Veuillez choisir le(s) type(s) article(s) de la commande !!!",
          ),
        }),
      )
      return
    }
    if (!materialType.value) {
      dispatch(
        setOpenSnackbar({
          message: getText(
            "Veuillez choisir le(s) type(s) matière(s) de la commande !!!",
          ),
        }),
      )
      return
    }
    if (files?.length) {
      setIsLoading(true)
      uploadFiles({
        files: files,
        cbErr(err) {
          setOpenSnackbar({
            message: getText(
              "Veuillez choisir des fichiers plus léger, s'il vous plait",
            ),
          })
          setIsLoading(false)
        },
      }).then((files) => {
        handleSignup({
          photos: files,
        })
      })
    } else {
      handleSignup({})
    }
  }

  useEffect(() => {
    getOrder({
      variables: {
        where: {
          id,
        },
      },
    })
  }, [id])

  const getCraftMan = (craftman: InfinityListItem) => {
    setCraftman(craftman)
  }

  const getCategory = (category: InfinityListItem) => {
    setCategory(category)
  }

  const getCountry = (country: InfinityListItem) => {
    setCountry(country)
  }

  const getItemTypes = (itemType: InfinityListItem[] | InfinityListItem) => {
    if (itemType && !Array.isArray(itemType)) {
      setItemType(itemType)
    }
  }

  const getMaterialTypes = (
    materialType: InfinityListItem[] | InfinityListItem,
  ) => {
    if (materialType && !Array.isArray(materialType)) {
      setMaterialType(itemType)
    }
  }

  const onFilesUpload = (files: File[]) => {
    setFiles(files)
  }

  const onClearUploads = (file: MinTypeFile) => {
    setFiles((prev) => [...prev.filter((_) => _.name !== file.name)])
    if (file.id) {
      setPhotosDeleteIds([...photosDeleteIds, file.id])
    }
  }

  const getCustomer = (customer: InfinityListItem) => {
    setCustomer(customer)
  }

  const deadline = useCallback(() => {
    return order.deadline ? moment(order.deadline) : null
  }, [order])

  if (loadingInit) return <Loader />

  if (error) return "error"

  return (
    <FormOrder
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      loading={isLoading || loading}
      title={getText("Modifier une commande")}
      categoryDefault={category}
      countryDefault={country}
      getCategory={getCategory}
      getCountry={getCountry}
      handleInputChangeDeadline={handleInputChangeDeadline}
      getItemTypes={getItemTypes}
      getMaterialTypes={getMaterialTypes}
      itemTypesDefault={itemType}
      materialTypesDefault={materialType}
      categoryId={category.value ? parseInt(category.value) : undefined}
      onClearUploads={onClearUploads}
      onFilesUpload={onFilesUpload}
      defaultUrls={defaultUrls}
      deadline={deadline()}
      isOffer={!craftman.value}
      message=""
      price=""
      isCreated={false}
      craftmanDefault={craftman}
      customerDefault={customer}
      getCraftMan={getCraftMan}
      getCustomer={getCustomer}
      craftmanCustomPrice={order.craftmanCustomPrice}
      ceedowCommissionCustomPrice={order.ceedowCommissionCustomPrice}
      transporterCustomPrice={order.transporterCustomPrice}
      quantity={order.quantity}
    />
  )
}
