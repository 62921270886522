import { useCallback, useEffect, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import { useLazyQuery } from "@apollo/client"
import {
  selectOrderPagination,
  selectOrderQuery,
  setOrderPagination,
  setOrderQuery,
} from "../../../store/features/order/orderSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { ActionOrder, SortOrder } from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import moment from "moment"
import { useParams } from "react-router-dom"
import { getStringEmpty } from "../../../utils/methodeString"
import useLang from "../../../hooks/useLang"

const GET_ACTION_ORDER = graphql(`
  query ActionOrders(
    $where: ActionOrderWhereInput
    $take: Int
    $orderBy: [ActionOrderOrderByWithRelationAndSearchRelevanceInput!]
    $skip: Int
    $aggregateActionOrderWhere2: ActionOrderWhereInput
  ) {
    actionOrders(where: $where, take: $take, orderBy: $orderBy, skip: $skip) {
      Creator {
        id
        email
        role
        name
        lastName
      }
      Order {
        id
      }
      action
      fieldsEdited
      createdAt
      id
    }
    aggregateActionOrder(where: $aggregateActionOrderWhere2) {
      _count {
        _all
      }
    }
  }
`)

const EditOrderHistory = () => {
  const params = useParams()
  const { getText } = useLang()
  const id = params.id ? parseInt(params.id, 10) : 0
  const { page, size } = useAppSelector(selectOrderPagination)
  const query = useAppSelector(selectOrderQuery)
  const dispatch = useAppDispatch()

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            {
              Order: {
                is: {
                  Craftman: {
                    is: {
                      name: {
                        search: query,
                      },
                    },
                  },
                },
              },
            },
            {
              Order: {
                is: {
                  Customer: {
                    is: {
                      name: {
                        equals: query,
                      },
                    },
                  },
                },
              },
            },
          ],
        }
      : {}
  }, [query])

  const [getOrderHistory, { loading, data }] = useLazyQuery(GET_ACTION_ORDER)

  useEffect(() => {
    getOrderHistory({
      variables: {
        take: size,
        skip: page * size,
        orderBy: [
          {
            createdAt: SortOrder.Desc,
          },
        ],
        aggregateActionOrderWhere2: {
          orderId: {
            equals: id,
          },
        },
        where: {
          orderId: {
            equals: id,
          },
          ...queryName(),
        },
      },
    })
  }, [id])

  const columns = useMemo<MRT_ColumnDef<ActionOrder>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => moment(row.createdAt).format("DD MMM YYYY"),
        header: getText("Date de modification"),
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => row.action,
        header: getText("Action"),
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => row.Order.id,
        header: getText("ID commande"),
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => row.Creator.id,
        header: getText("ID Editeur"),
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => row.Creator.email,
        header: getText("Email Editeur"),
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) =>
          `${row.Creator.name} ${getStringEmpty(row?.Creator?.lastName)}`,
        header: getText("Nom et prénom de l'editeur"),
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => `${getStringEmpty(row?.Creator?.role)}`,
        header: getText("Rôle de l'editeur"),
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => row.fieldsEdited.join(", "),
        header: getText("Champs modifiés"),
        enableClickToCopy: true,
      },
    ],
    [getText],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setOrderPagination(pagination))

  const onChangeSearchValue = (value: string) => dispatch(setOrderQuery(value))

  if (loading && !query) return <Loader />

  return (
    <CustomTable
      columns={columns}
      data={data?.actionOrders || []}
      lableAddNew={getText("Créer une nouvelle commande")}
      rootLisName={"/order/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateActionOrder._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      isHaveAction={false}
      onChangeSearchValue={onChangeSearchValue}
    />
  )
}

export default EditOrderHistory
