import { useCallback, useEffect, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import { useLazyQuery } from "@apollo/client"
import {
  selectOrderPagination,
  selectOrderQuery,
  setOrderPagination,
  setOrderQuery,
} from "../../../store/features/order/orderSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { PaimentHistory, PaimentStatus, SortOrder } from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import moment from "moment"
import { useParams } from "react-router-dom"
import { getStringEmpty } from "../../../utils/methodeString"
import useLang from "../../../hooks/useLang"

const GET_ACTION_ORDER = graphql(`
  query PaimentHistories(
    $where: PaimentHistoryWhereInput
    $orderBy: [PaimentHistoryOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
    $aggregatePaimentHistoryWhere2: PaimentHistoryWhereInput
  ) {
    paimentHistories(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      Customer {
        id
      }
      Order {
        id
      }
      paymentId
      id
      status
      userIP
      createdAt
    }
    aggregatePaimentHistory(where: $aggregatePaimentHistoryWhere2) {
      _count {
        _all
      }
    }
  }
`)

const PaimentOrderHistory = () => {
  const params = useParams()
  const { getText } = useLang()
  const id = params.id ? parseInt(params.id, 10) : 0
  const { page, size } = useAppSelector(selectOrderPagination)
  const query = useAppSelector(selectOrderQuery)
  const dispatch = useAppDispatch()

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            {
              Order: {
                is: {
                  Craftman: {
                    is: {
                      name: {
                        search: query,
                      },
                    },
                  },
                },
              },
            },
            {
              Order: {
                is: {
                  Customer: {
                    is: {
                      name: {
                        equals: query,
                      },
                    },
                  },
                },
              },
            },
          ],
        }
      : {}
  }, [query])

  const [getOrderHistory, { loading, data }] = useLazyQuery(GET_ACTION_ORDER)

  useEffect(() => {
    getOrderHistory({
      variables: {
        take: size,
        skip: page * size,
        orderBy: [
          {
            createdAt: SortOrder.Desc,
          },
        ],
        aggregatePaimentHistoryWhere2: {
          orderId: {
            equals: id,
          },
        },
        where: {
          orderId: {
            equals: id,
          },
          ...queryName(),
        },
      },
    })
  }, [id])

  const columns = useMemo<MRT_ColumnDef<PaimentHistory>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => moment(row.createdAt).format("DD MMM YYYY HH:mm"),
        header: getText("Date de création"),
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => row.paymentId,
        header: getText("Paiement pro ref"),
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) =>
          row.status === PaimentStatus.Error
            ? getText("Erreur")
            : getText("Validé"),
        header: getText("Statut"),
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => row?.Order?.id,
        header: getText("ID commande"),
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => row?.Customer?.id,
        header: getText("ID Client"),
        enableClickToCopy: true,
      },
    ],
    [getText],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setOrderPagination(pagination))

  const onChangeSearchValue = (value: string) => dispatch(setOrderQuery(value))

  if (loading && !query) return <Loader />

  return (
    <CustomTable
      columns={columns}
      data={data?.paimentHistories || []}
      lableAddNew={getText("Créer une nouvelle commande")}
      rootLisName={"/order/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregatePaimentHistory?._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      isHaveAction={false}
      onChangeSearchValue={onChangeSearchValue}
    />
  )
}

export default PaimentOrderHistory
