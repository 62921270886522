import { Link } from "react-router-dom"
import useLang from "../hooks/useLang"

const NotFound = () => {
  const { getText } = useLang()
  return (
    <div className="pageError">
      <div className="page-error">
        <h1>404</h1>
        <h2 className="mb-2">{getText("Page introuvable!!!")}</h2>
        <p>{getText("La page que vous essayez d'atteindre est introuvable")}</p>
        <Link className="btnError" to="/">
          {getText("Retour à l'accueil")}
        </Link>
      </div>
    </div>
  )
}

export default NotFound
