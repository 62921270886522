/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Paper, Typography, colors } from "@mui/material"
import { graphql } from "../../../gql"
import { useNavigate, useParams } from "react-router-dom"
import { useLazyQuery } from "@apollo/client"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"
import { Category, SortOrder } from "../../../gql/graphql"
import { useCallback, useEffect, useMemo, useState } from "react"
import Loader from "../../Common/Loader/Loader"
import { FormattedDate } from "react-intl"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { useAppDispatch } from "../../../store/app/hooks"
import NoDataStatistic from "../../Common/NoDataStatistic/NoDataStatistic"
import Chart from "../../Common/Chart/Chart"
import CustomTable from "../../Common/CustomTable/CustomTable"
import { MRT_ColumnDef } from "material-react-table"
import { havePermissions } from "../../../utils/permissions"
import useLang from "../../../hooks/useLang"

const GET_CATEGORIES = graphql(`
  query getDetailCategoriesQuery(
    $take: Int
    $skip: Int
    $orderBy: [CategoryOrderByWithRelationAndSearchRelevanceInput!]
    $categoriesWhere: CategoryWhereInput
    $aggregateCategoryWhere: CategoryWhereInput
  ) {
    categories(
      take: $take
      skip: $skip
      orderBy: $orderBy
      where: $categoriesWhere
    ) {
      name_fr
      name_en
      id
    }
    aggregateCategory(where: $aggregateCategoryWhere) {
      _count {
        id
      }
    }
  }
`)
const GET_SEGMENTATION = graphql(`
  query getSegmentationQuery(
    $where: SegmentationWhereUniqueInput!
    $aggregateOrderWhere2: OrderWhereInput
  ) {
    segmentation(where: $where) {
      id
      name_en
      name_fr
      updatedAt
      createdAt
    }
    aggregateOrder(where: $aggregateOrderWhere2) {
      _count {
        id
      }
    }
  }
`)
const DetailSegmentation = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const { getText } = useLang()
  const id = params.id ? parseInt(params.id, 10) : 0
  const size = 5

  const [getSegmentation, { data, loading }] = useLazyQuery(GET_SEGMENTATION)
  const [geCategories, { data: dataCategories }] = useLazyQuery(GET_CATEGORIES)

  const colorsGlobal = [colors.blue[400], colors.green[400]]
  const [query, setQuery] = useState("")
  const [page, setPage] = useState(0)

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [{ name_fr: { search: query } }, { name_en: { search: query } }],
        }
      : {}
  }, [query])

  useEffect(() => {
    geCategories({
      variables: {
        categoriesWhere: {
          segmentationId: {
            equals: id,
          },
          ...queryName(),
        },
        orderBy: {
          name_fr: SortOrder.Asc,
        },
        take: size,
        skip: page * size,
        aggregateCategoryWhere: {
          segmentationId: {
            equals: id,
          },
        },
      },
    })
  }, [id, page, queryName])

  useEffect(() => {
    getSegmentation({
      variables: {
        where: {
          id,
        },
        aggregateOrderWhere2: {
          Categories: {
            some: {
              segmentationId: {
                equals: id,
              },
            },
          },
        },
      },
    })
  }, [id])

  const handleEdit = (id: number) => {
    dispatch(setTitle(getText("Modifier une segmentation")))
    navigate(`/segmentation/edit/${id}`)
  }

  const getStatistic = useCallback(() => {
    const value_order = data?.aggregateOrder?._count?.id || 0
    const value_category = dataCategories?.aggregateCategory?._count?.id || 0
    return !value_order && !value_category
      ? []
      : [
          {
            label: getText("Nombre commande"),
            value: value_order,
            color: colorsGlobal[0],
          },
          {
            label: getText("Nombre catégorie"),
            value: value_category,
            color: colorsGlobal[1],
          },
        ]
  }, [data, getText])

  const handleGoDetailCategory = (id: number) => {
    dispatch(setTitle(getText("Detail d'une catégorie")))
    navigate(`/category/detail/${id}`)
  }

  const columns = useMemo<MRT_ColumnDef<Category>[]>(
    () => [
      { accessorKey: "id", header: "ID", enableEditing: false, size: 80 },
      {
        accessorKey: "name_fr",
        header: getText("Nom en FR"),
      },
      {
        accessorKey: "name_en",
        header: getText("Nom en EN"),
      },
    ],
    [],
  )

  const handleAddCategory = () => {
    dispatch(setTitle(getText("Ajouter une catégorie")))
    navigate(`/category/add`)
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  if (loading && !page && !query) return <Loader />

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <HeaderAdd
                    title={getText("Information de segmentation")}
                    variant="h4"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                      <Typography>
                        <b>{getText("Nom en FR")}:</b>{" "}
                        {data?.segmentation?.name_fr}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <b>{getText("Nom en EN")}:</b>{" "}
                        {data?.segmentation?.name_en}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <b>{getText("Date de création")}: </b>
                        <FormattedDate
                          value={new Date(data?.segmentation?.createdAt)}
                          year="numeric"
                          month="long"
                          day="2-digit"
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <b>{getText("Date de modification")}: </b>{" "}
                        <FormattedDate
                          value={new Date(data?.segmentation?.updatedAt)}
                          year="numeric"
                          month="long"
                          day="2-digit"
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={3}>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"flex-end"}
                    >
                      {havePermissions(["segmentation.change"]) && (
                        <Button
                          onClick={() =>
                            handleEdit(data?.segmentation?.id || 0)
                          }
                          sx={{ mr: 1 }}
                          color="info"
                          variant="outlined"
                          size="small"
                        >
                          {getText("Modifier")}
                        </Button>
                      )}
                      {havePermissions(["category.create"]) && (
                        <Button
                          onClick={handleAddCategory}
                          color="success"
                          variant="outlined"
                          size="small"
                        >
                          {getText("Ajouter une catégorie")}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <HeaderAdd
                    title={getText("Statistique de segmentation")}
                    variant="h4"
                  />
                </Grid>
                <Grid item xs={12}>
                  {getStatistic().length ? (
                    <Chart data={getStatistic()} />
                  ) : (
                    <NoDataStatistic />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <HeaderAdd title={getText("Liste des catégories")} variant="h4" />
            </Grid>
            <Grid item xs={12} mt={2}>
              <CustomTable
                columns={columns}
                data={dataCategories?.categories || []}
                lableAddNew=""
                rootLisName={""}
                isLoading={loading}
                showProgressBars={loading}
                rowCount={dataCategories?.aggregateCategory._count?.id || 0}
                handleChangePagination={(value) => setPage(value.page)}
                pageIndex={page}
                pageSize={size}
                searchValue={query}
                onChangeSearchValue={setQuery}
                isHaveView={true}
                handleView={handleGoDetailCategory}
                rowsPerPageOptions={[size]}
                handleRefresh={handleRefresh}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DetailSegmentation
