import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { NewFileInput, SignupInput, UserRoleType } from "../../../gql/graphql"
import { graphql } from "../../../gql"
import { useMutation } from "@apollo/client"
import FormUser from "../FormUser/FormUser"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { updateCacheCustomer } from "../../../caches/updateCacheCustomer"
import { uploadFile } from "../../../utils/uploadFile"
import useLang from "../../../hooks/useLang"

// Signin mutation
const addCustomerMutation = graphql(`
  mutation SignupCustomer($signupInput: SignupInput!) {
    signupUser(signupInput: $signupInput) {
      token
      user {
        id
        phoneNumber
        email
        role
        name
        lastName
        lang
        ProfilePhoto {
          url
        }
        country {
          id
          name_fr
          currency
        }
      }
    }
  }
`)

export default function AddCustomer() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { getText } = useLang()
  const [signin, { loading }] = useMutation(addCustomerMutation)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [rePassword, setRePassword] = useState<string>("")

  const defaultValue: InfinityListItem = {
    label: "",
    value: "",
  }

  const [country, setCountry] = useState<InfinityListItem>(defaultValue)

  const [user, setUser] = useState<SignupInput>({
    email: "",
    lastName: "",
    password: "",
    role: UserRoleType.Customer,
    phoneNumber: "",
    name: "",
    lang: "fr",
    description: "",
  })

  const handleInputChange = (event: any) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  const handleSignup = (profilePhoto?: NewFileInput) => {
    signin({
      variables: {
        signupInput: {
          ...user,
          profilePhoto: profilePhoto,
          countryId: Number(country.value),
        },
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
        setIsLoading(false)
      },
      onCompleted: () => {
        setIsLoading(false)
        navigate("/user/list")
      },
      update: (cache, { data }) => {
        updateCacheCustomer({
          action: "add",
          cache,
          entryData: data?.signupUser.user,
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (user.password !== rePassword) {
      dispatch(
        setOpenSnackbar({
          message: getText(
            "Veuillez vérifier le mot de passe et confirmer le mot de passe !!!",
          ),
        }),
      )
      return
    }
    if (file) {
      setIsLoading(true)
      uploadFile({
        cbError: ({ message }) => {
          dispatch(
            setOpenSnackbar({
              message,
            }),
          )
          setIsLoading(false)
        },
        cbOk: (file) => {
          handleSignup(file)
        },
        file,
        getPrecent: (value) => {},
      })
    } else {
      handleSignup()
    }
  }

  const onFileUpload = (file: File) => {
    setFile(file)
  }

  const onClearUpload = () => setFile(null)

  const getCountry = (country: InfinityListItem) => {
    setCountry(country)
  }

  return (
    <FormUser
      getPhoneNumber={(value) => setUser({ ...user, phoneNumber: value })}
      getRePassword={(value) => setRePassword(value)}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      loading={isLoading || loading}
      onClearUpload={onClearUpload}
      onFileUpload={onFileUpload}
      rePassword={rePassword}
      title={getText("Ajouter un client")}
      email={user.email}
      lastName={user.lastName}
      name={user.name}
      password={user.password}
      phoneNumber={user.phoneNumber || ""}
      countryDefault={country}
      getCountry={getCountry}
    />
  )
}
