import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useMutation } from "@apollo/client"
import { updateCacheFaqs } from "../../../caches/updateCacheFaqs"
import FormFaqs from "../FormFaqs/FormFaqs"
import useLang from "../../../hooks/useLang"

// Signin mutation
const CreateOneFaqs = graphql(`
mutation CreateOneFaq($data: FaqCreateInput!) {
  createOneFaq(data: $data) {
    createdAt
    description_en
    id
    title_en
    title_fr
    updatedAt
    description_fr
  }
}
`)

export default function AddFaqs() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { getText } = useLang()

  const [faqs, setFaqs] = useState({
    title_fr: "",
    title_en: "",
    description_fr: "",
    description_en: "",

  })

  const [createFaqs, { loading }] = useMutation(CreateOneFaqs)

  const handleInputChange = (event: any) => {
    setFaqs({
      ...faqs,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateFaqs = (e: any) => {
    e.preventDefault()
    createFaqs({
      variables: {
        data: {
          ...faqs,
        },
      },
      onCompleted: () => {
        navigate("/faqs/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheFaqs({
          action: "add",
          cache,
          entryData: data?.createOneFaq,
        })
      },
    })
  }
  return (
    <FormFaqs
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateFaqs}
      loading={loading}
      description_fr={faqs.description_fr}
      description_en={faqs.description_en}
      title_fr={faqs.title_fr}
      title_en={faqs.title_en}
      title={getText("Ajouter un FAQS")}
    />
  )
}
