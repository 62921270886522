import { useCallback, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import { useQuery } from "@apollo/client"
import {
  selectCountryPagination,
  selectCountryQuery,
  setPaginationCountry,
  setQueryCountry,
} from "../../../store/features/country/countrySlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { Country, SortOrder } from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import useLang from "../../../hooks/useLang"

export const LIST_COUNTRIES = graphql(`
  query CountriesCraftman(
    $where: CountryWhereInput
    $orderBy: [CountryOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
    $aggregateCountryWhere2: CountryWhereInput
  ) {
    countries(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name_fr
      name_en
      alpha2
      alpha3
      currency
    }
    aggregateCountry(where: $aggregateCountryWhere2) {
      _count {
        _all
      }
    }
  }
`)

const ListCountryCraftman = () => {
  const { page, size } = useAppSelector(selectCountryPagination)
  const query = useAppSelector(selectCountryQuery)
  const dispatch = useAppDispatch()
  const { getText } = useLang()
  const navigate = useNavigate()

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            { name_fr: { search: query } },
            { name_en: { search: query } },
            { name_en: { endsWith: query } },
            { name_fr: { endsWith: query } },
            { name_fr: { contains: query } },
            { name_en: { contains: query } },
            { name_fr: { startsWith: query } },
            { name_en: { startsWith: query } },
            { alpha2: { search: query } },
            { alpha2: { endsWith: query } },
            { alpha2: { contains: query } },
            { alpha2: { startsWith: query } },
          ],
        }
      : {}
  }, [query])

  const { loading, data, refetch } = useQuery(LIST_COUNTRIES, {
    variables: {
      take: size,
      skip: page * size,
      orderBy: { name_fr: SortOrder.Asc },
      where: {
        isOpen: {
          equals: true,
        },
        ...queryName(),
      },
      aggregateCountryWhere2: {
        isOpen: {
          equals: true,
        },
        ...queryName(),
      },
    },
  })

  const columns = useMemo<MRT_ColumnDef<Country>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorKey: "name_fr",
        header: getText("Nom en fr"),
        enableClickToCopy: true,
      },
      {
        accessorKey: "name_en",
        header: getText("Nom en en"),
        enableClickToCopy: true,
      },
      {
        accessorKey: "currency",
        header: getText("Devise"),
        enableClickToCopy: true,
      },
    ],
    [getText],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setPaginationCountry(pagination))

  const onChangeSearchValue = (value: string) =>
    dispatch(setQueryCountry(value))

  const handleView = (id: number) => {
    dispatch(setTitle(getText("Detail d'un pays")))
    navigate(`/country/detail/${id}`)
  }

  const handleRefresh = () => {
    window.location.reload();
  }

  if (loading && !query && !page) return <Loader />

  return (
    <CustomTable
      columns={columns}
      data={data?.countries || []}
      lableAddNew=""
      rootLisName={""}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateCountry._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      isHaveView={true}
      handleView={handleView}
      handleRefresh={handleRefresh}
    />
  )
}

export default ListCountryCraftman
