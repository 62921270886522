import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useMutation } from "@apollo/client"
import { updateCacheSegmentation } from "../../../caches/updateCacheSegmentation"
import FormSegmentation from "../FormSegmentation/FormSegmentation"
import useLang from "../../../hooks/useLang"

// Signin mutation
const addSegmentationMutation = graphql(`
  mutation addSegmentation($data: SegmentationCreateInput!) {
    createOneSegmentation(data: $data) {
      id
      name_fr
      name_en
    }
  }
`)

export default function AddSegmentation() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { getText } = useLang()

  const [segmentation, setSegmentation] = useState({
    name_fr: "",
    name_en: "",
  })

  const [createSegmentation, { loading }] = useMutation(addSegmentationMutation)

  const handleInputChange = (event: any) => {
    setSegmentation({
      ...segmentation,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateSegmentation = (e: any) => {
    e.preventDefault()
    createSegmentation({
      variables: {
        data: {
          ...segmentation,
        },
      },
      onCompleted: () => {
        navigate("/segmentation/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheSegmentation({
          action: "add",
          cache,
          entryData: data?.createOneSegmentation,
        })
      },
    })
  }
  return (
    <FormSegmentation
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateSegmentation}
      loading={loading}
      name_en={segmentation.name_en}
      name_fr={segmentation.name_fr}
      title={getText("Ajouter une segmentation")}
    />
  )
}
