/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react"
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Chip,
  Grid,
  Button,
} from "@mui/material"
import MessageCard from "./MessageCard/MessageCard"
import { graphql } from "../../../../gql"
import { useMutation, useQuery, useSubscription } from "@apollo/client"
import { Maybe, Scalars, SortOrder, Status } from "../../../../gql/graphql"
import Loader from "../../../Common/Loader/Loader"
import UserAvatar from "./UserCard/UserAvatar"
import FormMedia from "./FormMedia/FormMedia"
import FormAudio from "./FormAudio/FormAudio"
import useOrder from "../../../../hooks/useOrder"
import moment from "moment"
import "moment/locale/fr"
import HeaderAdd from "../../../Common/HeaderAdd/HeaderAdd"
import { useAppDispatch } from "../../../../store/app/hooks"
import { setOpenSnackbar } from "../../../../store/features/snackbar/snackbarSlice"
import { getErrorsAsString } from "../../../../utils/getErrorsAsString"
import {
  setCloseAlert,
  setLoadingDelete,
  setOpenAlert,
} from "../../../../store/features/alert/alertSlice"
import useLang from "../../../../hooks/useLang"

moment.locale("fr")
export interface FileType {
  id: Scalars["Int"]["output"]
  name: Scalars["String"]["output"]
  orderId?: Maybe<Scalars["Int"]["output"]>
  size?: Maybe<Scalars["Float"]["output"]>
  type: Scalars["String"]["output"]
  url: Scalars["String"]["output"]
}

const GET_CHAT_MESSAGE = graphql(`
  query ChatMessages(
    $where: ChatWhereUniqueInput!
    $orderBy: [ChatMessageOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    chat(where: $where) {
      id
      orderId
      createdAt
      updatedAt
      Order {
        id
        status
        Customer {
          id
          workshopName
          name
          lastName
          role
          ProfilePhoto {
            id
            name
            url
            type
          }
        }
        orderStatus {
          haveAmountNotPaid
          haveAmountPaid
          notPaid
        }
      }
      ChatMessages(orderBy: $orderBy, take: $take, skip: $skip) {
        id
        chatId
        createdAt
        updatedAt
        content
        receiverId
        isDelete
        senderId
        Files {
          id
          name
          url
          type
          size
        }
        contentType
        Sender {
          id
          workshopName
          name
          lastName
          role
          ProfilePhoto {
            id
            name
            url
            type
          }
        }
        Receiver {
          id
          workshopName
          name
          lastName
          role
          ProfilePhoto {
            id
            name
            url
            type
          }
        }
        OrderPrice {
          id
          price
          status
          currenctUserPrice
          createdAt
          updatedAt
        }
        OrderSize {
          id
          depth
          height
          length
          weight
          width
        }
        AskDeliveryMan {
          id
          address
          delivryDate
          codeTracking
          photos {
            id
            name
            url
            type
          }
        }
        ValidOrderEvidence {
          id
          photos {
            id
            name
            url
            type
          }
        }
      }
      _count {
        ChatMessages
      }
    }
  }
`)

export const CREATE_MESSAGE = graphql(`
  mutation SendNewMessage(
    $photos: [NewFileInput!]!
    $receiverId: Float!
    $content: String!
    $chatId: Float!
  ) {
    sendNewMessage(
      photos: $photos
      receiverId: $receiverId
      content: $content
      chatId: $chatId
    ) {
      id
      chatId
      createdAt
      updatedAt
      isDelete
      content
      receiverId
      senderId
      contentType
      Files {
        id
        name
        url
        type
        size
      }
      Sender {
        id
        workshopName
        name
        lastName
        role
        ProfilePhoto {
          id
          name
          url
          type
        }
      }
      Receiver {
        id
        workshopName
        name
        lastName
        role
        ProfilePhoto {
          id
          name
          url
          type
        }
      }
      OrderPrice {
        id
        price
        status
        currenctUserPrice
        createdAt
        updatedAt
      }
      OrderSize {
        id
        depth
        height
        length
        weight
        width
      }
      AskDeliveryMan {
        id
        address
        delivryDate
        codeTracking
        photos {
          id
          name
          url
          type
        }
      }
      ValidOrderEvidence {
        id
        photos {
          id
          name
          url
          type
        }
      }
    }
  }
`)

const NEW_MESSAGE = graphql(`
  subscription NewMessage($chatId: Float!) {
    newMessage(chatId: $chatId) {
      id
      chatId
      createdAt
      updatedAt
      content
      receiverId
      senderId
      isDelete
      Files {
        id
        name
        url
        type
        size
      }
      contentType
      Sender {
        id
        workshopName
        name
        lastName
        role
        ProfilePhoto {
          id
          name
          url
          type
        }
      }
      Receiver {
        id
        workshopName
        name
        lastName
        role
        ProfilePhoto {
          id
          name
          url
          type
        }
      }
      OrderPrice {
        id
        price
        status
        currenctUserPrice
        createdAt
        updatedAt
      }
      OrderSize {
        id
        depth
        height
        length
        weight
        width
      }
      AskDeliveryMan {
        id
        address
        delivryDate
        codeTracking
        photos {
          id
          name
          url
          type
        }
      }
      ValidOrderEvidence {
        id
        photos {
          id
          name
          url
          type
        }
      }
    }
  }
`)

const ARCHIVE_ORDER_SUBSCRIPTION = graphql(`
  subscription ToogleArchiveOrderSub($orderId: Float!) {
    toogleArchiveOrderSub(orderId: $orderId) {
      id
      status
      orderStatus {
        haveAmountNotPaid
        haveAmountPaid
        notPaid
      }
    }
  }
`)

const VALID_PAIMENT_SUBSCRIPTION = graphql(`
  subscription ValidPaimentSub($orderId: Float!) {
    validPaimentSub(orderId: $orderId) {
      id
      status
      orderStatus {
        haveAmountNotPaid
        haveAmountPaid
        notPaid
      }
    }
  }
`)

const VALID_DELIVRY_SUBSCRIPTION = graphql(`
  subscription ValidOrderDelivrySub($orderId: Float!) {
    validOrderDelivrySub(orderId: $orderId) {
      id
      status
      orderStatus {
        haveAmountNotPaid
        haveAmountPaid
        notPaid
      }
    }
  }
`)

const DESARCHIVE = graphql(`
  mutation DesArchiveOrder($isToogle: Boolean, $toogleArchiveOrderId: Float!) {
    toogleArchiveOrder(isToogle: $isToogle, id: $toogleArchiveOrderId) {
      id
    }
  }
`)

interface Props {
  chatId: number
  orderId: number
  craftmanId: number
  customerId: number
}

const Chat = ({ chatId, orderId, craftmanId, customerId }: Props) => {
  const scrollParentRef = useRef<HTMLDivElement>(null)
  const { getOrderStatus } = useOrder()
  const [receiverId, setReceiverId] = useState<number>(0)
  const [isRecord, setIsRecord] = useState(false)
  const [loadingFromMore, setLoadingFromMore] = useState(false)
  const { getText } = useLang()
  const dispatch = useAppDispatch()
  const [desarchiveOrder] = useMutation(DESARCHIVE)

  const { data, loading } = useQuery(GET_CHAT_MESSAGE, {
    variables: {
      where: {
        id: chatId,
      },
      orderBy: {
        createdAt: SortOrder.Asc,
      },
    },
    onCompleted() {
      setLoadingFromMore(false)
    },
  })

  useSubscription(VALID_PAIMENT_SUBSCRIPTION, {
    variables: {
      orderId,
    },
    onData: ({ client, data }) => {
      const order = data.data?.validPaimentSub
      // Change order status to paid
      client.cache.modify({
        id: client.cache.identify({
          __typename: "Chat",
          id: chatId,
        }),
        fields: {
          Order(existingOrer) {
            return { ...existingOrer, ...order }
          },
        },
      })
    },
    onError: (err) => console.log("err"),
  })

  useSubscription(VALID_DELIVRY_SUBSCRIPTION, {
    variables: {
      orderId,
    },
    onData: ({ client, data }) => {
      const order = data.data?.validOrderDelivrySub
      // Change order status to paid
      client.cache.modify({
        id: client.cache.identify({
          __typename: "Chat",
          id: chatId,
        }),
        fields: {
          Order(existingOrer) {
            return { ...existingOrer, ...order }
          },
        },
      })
    },
    onError: (err) => console.log("err"),
  })

  useSubscription(NEW_MESSAGE, {
    variables: {
      chatId,
    },
    onData: ({ client, data }) => {
      const message = data.data?.newMessage
      client.cache.modify({
        id: client.cache.identify({
          __typename: "Chat",
          id: chatId,
        }),
        fields: {
          ChatMessages(existingMessages = []) {
            return [...existingMessages, message]
          },
          _count(oldCount = { ChatMessages: 0 }) {
            //@ts-ignore
            return { ChatMessages: oldCount.ChatMessages + 1 }
          },
        },
      })
      // Change order status to haveAmountNotPaid === true if the current price is valid
      if (message?.OrderPrice && message.OrderPrice.status === Status.Valid) {
        client.cache.modify({
          id: client.cache.identify({
            __typename: "Order",
            id: orderId,
          }),
          fields: {
            orderStatus(existingStatus = {}) {
              return {
                ...existingStatus,
                haveAmountNotPaid: true,
              }
            },
          },
        })
      }
      setLoadingFromMore(false)
    },
    onError: (err) => console.log(err),
  })

  useSubscription(ARCHIVE_ORDER_SUBSCRIPTION, {
    variables: {
      orderId,
    },
    onData: ({ client, data }) => {
      const order = data.data?.toogleArchiveOrderSub
      client.cache.modify({
        id: client.cache.identify({
          __typename: "Chat",
          id: chatId,
        }),
        fields: {
          Order(existingOrer) {
            return { ...existingOrer, ...order }
          },
        },
      })
    },
    onError: (err) => console.log(err),
  })

  useEffect(() => {
    if (data && data?.chat && !loading && data?.chat?.Order?.Customer) {
      setReceiverId(data.chat.Order.Customer.id)
    }
  }, [data, loading])

  useEffect(() => {
    if (scrollParentRef.current && !loadingFromMore) {
      scrollParentRef.current.scrollTo({
        behavior: "smooth",
        top: scrollParentRef.current.scrollHeight,
      })
    }
  }, [data])

  const receiverName =
    data && data.chat && data.chat.Order.Customer
      ? data?.chat?.Order?.Customer?.name
      : "Client"

  const receiverLastName =
    data && data.chat && data.chat.Order.Customer
      ? data?.chat?.Order?.Customer?.lastName
      : ""

  const receiverProfile =
    data &&
    data.chat &&
    data.chat.ChatMessages &&
    data.chat.ChatMessages.length &&
    data.chat.Order.Customer?.ProfilePhoto &&
    data.chat.Order.Customer?.ProfilePhoto?.url
      ? data.chat.Order.Customer?.ProfilePhoto.url
      : ""

  // Créez un tableau pour stocker les messages regroupés par date
  const messages = useCallback(
    () => data?.chat?.ChatMessages || [],
    [data?.chat?.ChatMessages],
  )

  const hendleDesarchiveOrder = () => {
    dispatch(setLoadingDelete(true))
    desarchiveOrder({
      variables: {
        isToogle: true,
        toogleArchiveOrderId: orderId,
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: getText("La commande a été désarchivée avec succès"),
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
    })
  }

  const handleOpenDesarchiveDialog = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => hendleDesarchiveOrder(),
        message: getText(
          "Êtes-vous vraiment sûr de vouloir désarchiver cette commande ?",
        ),
        isLoading: false,
      }),
    )
  }

  if (loading) return <Loader />

  return (
    <>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <HeaderAdd title={getText("Chat instantannée")} variant="h4" />
        </Grid>
        <Grid item xs={12}>
          <Box flexGrow={1}>
            <AppBar
              position="static"
              sx={{
                backgroundColor: "#5D490E",
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
              }}
            >
              <Toolbar
                sx={{
                  display: "flex",
                  padding: "4px 0px 4px 0px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <UserAvatar
                    firstName={receiverName || "Client"}
                    src={receiverProfile}
                    lastName={receiverLastName || ""}
                  />
                </Box>
                <Box sx={{}}>
                  <Chip
                    variant="filled"
                    color="info"
                    label={getOrderStatus(data?.chat?.Order.status)}
                  />
                </Box>
              </Toolbar>
            </AppBar>
            <Box
              style={{
                overflowY: "auto",
                width: "100%",
                border: "1px solid #5D490E",
                padding: "10px",
                marginBottom: "5px",
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
                flexDirection: "column-reverse",
                height: "68vh",
              }}
              ref={scrollParentRef}
            >
              <div className="chat-container">
                {messages().length &&
                  messages()?.map((chatMessage, index) => {
                    return (
                      <MessageCard
                        isArchived={data?.chat?.Order.status === Status.Archive}
                        isPaid={data?.chat?.Order.status === Status.Paid}
                        key={`${chatMessage.id}_${index}`}
                        chatMessage={chatMessage as any}
                        senderIDPrev={
                          index > 0
                            ? Number(
                                data?.chat?.ChatMessages[index - 1].Sender.id,
                              )
                            : Number(data?.chat?.ChatMessages[index].Sender.id)
                        }
                      />
                    )
                  })}
              </div>
            </Box>
            {data?.chat?.Order.status === Status.Archive ? (
              <Box>
                <Typography textAlign={"center"}>
                  {getText("Votre commande a été archivé")}
                </Typography>
                <Box justifyContent={"center"} display={"flex"} my={2}>
                  <Button onClick={handleOpenDesarchiveDialog}>
                    {getText("Désarchiver")}
                  </Button>
                </Box>
              </Box>
            ) : (
              <>
                {isRecord ? (
                  <FormAudio
                    onClearFormAudio={() => setIsRecord(false)}
                    receiverId={receiverId}
                    chatId={chatId}
                  />
                ) : (
                  <FormMedia
                    handleRecord={() => setIsRecord(true)}
                    receiverId={receiverId}
                    chatId={chatId}
                    orderId={orderId}
                    craftmanId={craftmanId}
                    customerId={customerId}
                    haveAmountNotPaid={
                      data?.chat?.Order.orderStatus.haveAmountNotPaid as boolean
                    }
                    haveAmountPaid={
                      data?.chat?.Order.orderStatus.haveAmountPaid as boolean
                    }
                    notPaid={data?.chat?.Order.orderStatus.notPaid as boolean}
                    status={data?.chat?.Order.status as Status}
                  />
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Chat
