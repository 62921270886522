import React, { useState } from "react"
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Checkbox,
  TextField,
  Pagination,
  Button,
  Tooltip,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import VisibilityIcon from "@mui/icons-material/Visibility"
import RefreshIcon from "@mui/icons-material/Refresh"
import { useNavigate } from "react-router-dom"
import ArchiveIcon from "@mui/icons-material/Archive"
import useLang from "../../../hooks/useLang"

interface MobileTableViewProps {
  data: any[]
  columns: any[]
  handleDelete?: (id: number) => void
  handleEdit?: (id: number) => void
  handleView?: (id: number) => void
  handleSelected?: (id: number) => void
  headerFilter?: React.ReactNode
  selectedIds?: number[]
  isHaveView?: boolean
  isHaveAction?: boolean
  isCustomAction?: boolean
  renderAction?: any
  rowCount: number
  pageIndex: number
  pageSize: number
  handleChangePagination: (pagination: { page: number; size: number }) => void
  searchValue?: string
  onChangeSearchValue?: (value: string) => void
  isHaveHeaderAction?: boolean
  lableAddNew?: string
  handleAdd?: () => void
  rootLisName?: string
  handleRefresh?: () => void
  handleArchiveGroup?: () => void
}

const MobileTableView: React.FC<MobileTableViewProps> = ({
  data,
  columns,
  handleDelete,
  handleEdit,
  handleView,
  handleSelected,
  selectedIds,
  isHaveView,
  isHaveAction,
  isCustomAction,
  renderAction,
  rowCount,
  pageIndex,
  pageSize,
  handleChangePagination,
  searchValue,
  onChangeSearchValue,
  handleAdd,
  handleArchiveGroup,
  handleRefresh,
  isHaveHeaderAction,
  lableAddNew,
  rootLisName,
  headerFilter,
}) => {
  const navigate = useNavigate()
  const [expandedItems, setExpandedItems] = useState<number[]>([])
  const { getText } = useLang()

  const toggleItemExpansion = (id: number) => {
    setExpandedItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
    )
  }

  return (
    <Box sx={{ mt: 2, overflowY: "auto" }}>
      {isHaveHeaderAction && lableAddNew && (
        <Box sx={{ mb: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {" "}
            <Button
              variant="contained"
              sx={{ background: "white", color: "#000" }}
              onClick={() => {
                if (handleAdd) {
                  handleAdd()
                } else if (rootLisName) {
                  navigate(rootLisName)
                }
              }}
            >
              {lableAddNew}
            </Button>
            {handleRefresh && (
              <Button
                variant="contained"
                color="success"
                sx={{ background: "white", color: "#000", marginLeft: 1 }}
                onClick={handleRefresh}
              >
                <RefreshIcon />
              </Button>
            )}
          </Box>
          {Boolean(selectedIds?.length && handleArchiveGroup) && (
            <Button
              variant="contained"
              color="error"
              sx={{ color: "#fff", background: "red" }}
              onClick={handleArchiveGroup}
            >
              <ArchiveIcon />
            </Button>
          )}
        </Box>
      )}{" "}
      <TextField
        label=""
        variant="outlined"
        placeholder={getText("Rechercher...")}
        fullWidth
        value={searchValue}
        onChange={(e) =>
          onChangeSearchValue && onChangeSearchValue(e.target.value)
        }
        sx={{ mb: 2, background: "dimgray" }}
      />
      {headerFilter}
      {data.map((row) => {
        const isExpanded = expandedItems.includes(row.id)
        const visibleColumns = isExpanded ? columns : columns.slice(0, 3)
        const customRow = {
          original: row,
        }
        return (
          <Card key={row.id} sx={{ mb: 2 }}>
            <CardContent>
              {isHaveAction && (
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {handleSelected && (
                    <Tooltip title={getText("Sélectionner")}>
                      <Checkbox
                        checked={selectedIds?.includes(row.id)}
                        onChange={() => handleSelected(row.id)}
                        inputProps={{ "aria-label": "controlled" }}
                        color="success"
                        sx={{ color: "white" }}
                      />
                    </Tooltip>
                  )}
                  {handleEdit && (
                    <IconButton onClick={() => handleEdit(row.id)}>
                      <EditIcon />
                    </IconButton>
                  )}
                  {handleDelete && (
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  {isHaveView && handleView && (
                    <IconButton color="info" onClick={() => handleView(row.id)}>
                      <VisibilityIcon />
                    </IconButton>
                  )}
                </Box>
              )}
              {isCustomAction && renderAction && (
                <Box sx={{ mt: 2 }}>
                  {renderAction({ row: customRow, cell: null, table: null })}
                </Box>
              )}
              {visibleColumns.map((column) => (
                <Box key={column.accessorKey || column.header} sx={{ mb: 1 }}>
                  <Box sx={{ textDecorationLine: "underline" }}>
                    <Typography
                      variant="subtitle2"
                      component="span"
                      fontWeight={"bold"}
                    >
                      {column.header}:
                    </Typography>
                  </Box>{" "}
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body2" component="span">
                      {column.accessorFn
                        ? column.accessorFn(row)
                        : row[column.accessorKey]}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </CardContent>
            {columns.length > 3 && (
              <Button
                variant="text"
                color="primary"
                onClick={() => toggleItemExpansion(row.id)}
                sx={{ m: 1 }}
              >
                {isExpanded ? getText("Voir moins") : getText("Voir plus")}
              </Button>
            )}
          </Card>
        )
      })}
      <Pagination
        count={Math.ceil(rowCount / pageSize)}
        page={pageIndex + 1}
        color="secondary"
        variant="outlined"
        showFirstButton
        showLastButton
        onChange={(_, page) =>
          handleChangePagination({ page: page - 1, size: pageSize })
        }
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "center",
          background: "dimgray",
          p: 1.5,
          borderRadius: 1,
        }}
      />
    </Box>
  )
}

export default MobileTableView
