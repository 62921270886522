import { Button, Grid, Paper, TextField } from "@mui/material"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"
import useLang from "../../../hooks/useLang"

interface Props {
  handleSubmit: (e: any) => void
  handleInputChange: (e: any) => void
  name_fr: string
  name_en: string
  loading: boolean
  title: string
}

export default function FormSegmentation({
  handleSubmit,
  handleInputChange,
  name_fr,
  name_en,
  loading,
  title,
}: Props) {
  const { getText } = useLang()
  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={2} lg={3} />
      <Grid item xs={12} md={8} lg={6}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <HeaderAdd title={title} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="name_fr"
                  type="text"
                  onChange={handleInputChange}
                  label={getText("Nom de segmentation en FR")}
                  fullWidth
                  variant="outlined"
                  value={name_fr}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  name="name_en"
                  type="text"
                  onChange={handleInputChange}
                  label={getText("Nom de segmentation en EN")}
                  fullWidth
                  value={name_en}
                  variant="outlined"
                />
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  style={{ background: "#5D490E" }}
                  disabled={loading}
                  variant="contained"
                >
                  {getText("Valider")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={2} lg={3} />
    </Grid>
  )
}
