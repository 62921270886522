import DoneIcon from "@mui/icons-material/Done"
import { Typography, Box, Button } from "@mui/material"
import { ChatMessageType } from "../MessageCard"
import { Status } from "../../../../../../gql/graphql"
import { graphql } from "../../../../../../gql"
import { useMutation } from "@apollo/client"
import { useAppDispatch } from "../../../../../../store/app/hooks"
import {
  setCloseAlert,
  setLoadingDelete,
  setOpenAlert,
} from "../../../../../../store/features/alert/alertSlice"
import { getErrorsAsString } from "../../../../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../../../../store/features/snackbar/snackbarSlice"
import { getUserId } from "../../../../../../utils/authToken"
import { useIntl } from "react-intl"
import { useCallback } from "react"
import useLang from "../../../../../../hooks/useLang"

interface Props {
  message: ChatMessageType
  isArchived: boolean
  isPaid: boolean
}

const VALID_QUOTE = graphql(`
  mutation ValidAdviceOrderPrice(
    $receiverId: Float!
    $orderPriceId: Float!
    $chatId: Float!
  ) {
    validAdviceOrderPrice(
      receiverId: $receiverId
      orderPriceId: $orderPriceId
      chatId: $chatId
    ) {
      id
      chatId
      createdAt
      updatedAt
      content
      receiverId
      senderId
      contentType
      Files {
        id
        name
        url
        type
        size
      }
      Sender {
        id
        workshopName
        name
        lastName
        role
        ProfilePhoto {
          id
          name
          url
          type
        }
      }
      Receiver {
        id
        workshopName
        name
        lastName
        role
        ProfilePhoto {
          id
          name
          url
          type
        }
      }
      OrderPrice {
        id
        price
        status
        currenctUserPrice
        createdAt
        updatedAt
      }
    }
  }
`)

const MessageOrderPrice = ({ message, isArchived, isPaid }: Props) => {
  const [validOrderPrice] = useMutation(VALID_QUOTE)
  const { getText } = useLang()
  const intl = useIntl()

  const dispatch = useAppDispatch()
  const userId = getUserId()

  const isCanValidate = isArchived
    ? false
    : Boolean(message.Sender.id != userId && !isPaid)

  const handleValidQuotePrice = () => {
    dispatch(setLoadingDelete(true))
    validOrderPrice({
      variables: {
        chatId: message.chatId as number,
        receiverId: message.receiverId,
        orderPriceId: message.OrderPrice?.id as number,
      },
      onCompleted: () => {
        dispatch(setLoadingDelete(false))
        dispatch(
          setOpenSnackbar({
            message: getText(
              "La validation de prix a été approuvée avec succès",
            ),
            status: "success",
          }),
        )
        dispatch(setCloseAlert())
      },
      onError: (error) => {
        dispatch(setLoadingDelete(false))
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message, status: "error" }))
      },
    })
  }

  const handleOpenDeleteDialog = () => {
    dispatch(
      setOpenAlert({
        handleValid: handleValidQuotePrice,
        message: getText(
          "Êtes-vous vraiment sûr de vouloir valider cette proposition de prix ?",
        ),
        isLoading: false,
      }),
    )
  }

  //TODO: update price of order on detail order
  //TODO: change currency of order price by user price

  const price = useCallback(() => {
    if (message.OrderPrice?.currenctUserPrice) {
      const value = message.OrderPrice?.currenctUserPrice.split("-")[0]
      const currency = message.OrderPrice?.currenctUserPrice.split("-")[1]
      const priceValue = intl.formatNumber(Number(value), {
        currency: currency,
        currencyDisplay: "narrowSymbol",
        style: "currency",
      })
      return priceValue
    }
    return ""
  }, [message.OrderPrice?.currenctUserPrice])

  if (!message.content) return null

  return (
    <Box
      sx={{
        marginRight: "5px",
      }}
    >
      <Box>
        <Typography
          sx={{
            marginBottom: "0px",
            textAlign: "left",
            fontSize: "12.5px",
          }}
          variant="body2"
        >
          {message.content}{" "}
          <Typography fontWeight={"bold"} color={"orange"}>
            {price()}
          </Typography>
        </Typography>
      </Box>
      <Box my={1}>
        {message.OrderPrice?.status === Status.Valid ? (
          <Button
            variant="text"
            aria-readonly={true}
            disabled={true}
            color="success"
            size="small"
            sx={{ backgroundColor: "green" }}
            style={{ color: "white", fontSize: "12px" }}
            startIcon={<DoneIcon />}
          >
            {getText("Validé")}
          </Button>
        ) : isCanValidate ? (
          <Button
            variant="text"
            sx={{ backgroundColor: "orange" }}
            style={{ color: "white", fontSize: "11px" }}
            size="small"
            color="info"
            onClick={handleOpenDeleteDialog}
          >
            {getText("Valider")}
          </Button>
        ) : null}
      </Box>
    </Box>
  )
}

export default MessageOrderPrice
