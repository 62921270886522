import * as React from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import CircularProgress from "@mui/material/CircularProgress"
import { Paper, Typography, useTheme, Checkbox, ListItem } from "@mui/material"
import useLang from "../../../hooks/useLang"

interface Props {
  query?: string
  value: any
  defaultValue: any
  open?: boolean
  options: any[]
  label: string
  keyName: string
  loading?: boolean
  multiple?: boolean
  required?: boolean
  colorText?: string
  size?: "small" | "medium"
  onChange: (value: any) => void
  handleClose?: () => void
  handleOpen?: () => void
  onSearch?: (query: string) => void
  onEndReached?: () => void
}

export default function InfiniteScrollAutocomplete({
  open,
  query,
  value,
  label,
  keyName,
  required = false,
  colorText = "white",
  options = [],
  size = "medium",
  loading,
  defaultValue,
  multiple,
  onChange,
  onSearch,
  handleClose,
  handleOpen,
  onEndReached,
}: Props) {
  const theme = useTheme()
  const { getText } = useLang()
  const handleScroll = (event: any) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target
    if (scrollHeight - scrollTop === clientHeight) {
      onEndReached && onEndReached()
    }
  }

  const allSelected = React.useCallback(() => {
    return multiple && options.length > 0 && value.length === options.length
  }, [multiple, options, value])

  const handleSelectAll = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    if (!allSelected()) {
      onChange(options)
    } else {
      onChange([])
    }
  }

  const optionsWithSelectAll = React.useMemo(() => {
    return multiple ? [{ selectAll: true }, ...options] : options
  }, [options, multiple])

  return (
    <Autocomplete
      open={open}
      aria-modal
      onOpen={() => handleOpen && handleOpen()}
      onClose={() => handleClose && handleClose()}
      multiple={multiple}
      isOptionEqualToValue={(option, value) =>
        option[keyName] === value[keyName]
      }
      PaperComponent={({ children }) => (
        <Paper elevation={5} style={{ background: theme.palette.primary.main }}>
          {children}
        </Paper>
      )}
      value={value}
      defaultValue={defaultValue}
      noOptionsText={
        <Typography color={colorText}>{getText("Aucun résultat à afficher")}</Typography>
      }
      loadingText={<Typography color={colorText}>{getText("Chargement...")}</Typography>}
      onChange={(e, value) => onChange(value)}
      getOptionLabel={(option) => option[keyName]}
      options={optionsWithSelectAll}
      loading={loading}
      renderOption={(props, option, { selected }) => {
        if (option.selectAll) {
          return (
            <ListItem
              {...props}
              key="select-all"
              style={{ paddingLeft: 6 }}
              onClick={handleSelectAll}
            >
              <Checkbox
                checked={allSelected()}
                indeterminate={
                  value.length > 0 && value.length < options.length
                }
                style={{ color: "white" }}
              />
              <Typography color={colorText}>
                {!allSelected() ? getText("Sélectionner tous") : getText("Désélectionner tous")}
              </Typography>
            </ListItem>
          )
        }
        return (
          <ListItem {...props} key={option[keyName]}>
            {multiple && (
              <Checkbox checked={selected} style={{ color: "white" }} />
            )}
            <Typography color={colorText}>{option[keyName]}</Typography>
          </ListItem>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          value={query || ""}
          sx={{ background: theme.palette.primary.main }}
          size={size}
          onChange={(e) => onSearch && onSearch(e.target.value)}
          InputProps={{
            ...params.InputProps,
            required: required,
            sx: {
              maxHeight: 200,
              overflow: "auto",
            },
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      ListboxProps={{
        onScroll: handleScroll,
        style: { maxHeight: "400px", overflow: "auto" },
      }}
    />
  )
}
