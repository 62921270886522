import * as React from "react"
import DashboardIcon from "@mui/icons-material/Dashboard"
import ListItem, { ListItemButtonStyled, Menu } from "./ListItem/ListItem"
import ListIcon from "@mui/icons-material/List"
import PublicIcon from "@mui/icons-material/Public"
import FlagCircleIcon from "@mui/icons-material/FlagCircle"
import RecentActorsIcon from "@mui/icons-material/RecentActors"
import CategoryIcon from "@mui/icons-material/Category"
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import PersonIcon from "@mui/icons-material/Person"
import PeopleIcon from "@mui/icons-material/People"
import { ListItemIcon, ListItemText } from "@mui/material"
import { useAppDispatch } from "../../../../store/app/hooks"
import { Logout } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import {
  setCloseAlert,
  setOpenAlert,
} from "../../../../store/features/alert/alertSlice"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import ClassIcon from "@mui/icons-material/Class"
import EmailIcon from "@mui/icons-material/Email"
import SegmentIcon from "@mui/icons-material/Segment"
import FormatColorTextIcon from "@mui/icons-material/FormatColorText"
import useLang from "../../../../hooks/useLang"

const ListMain = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { getText } = useLang()

  const menus = [
    {
      name: getText("Tableau de board"),
      title: getText("Tableau de board"),
      icon: <DashboardIcon color="inherit" />,
      route: "/",
      permissions: [
        "askPaiment.view",
        "deliveryMan.view",
        "order.view",
        "craftman.view",
        "customer.view",
      ],
      isBoard: true,
    },
    {
      name: getText("Clients"),
      title: getText("Clients"),
      icon: <PersonIcon />,
      route: "/user/list",
      isMain: true,
      permissions: ["customer.create", "customer.view"],
      subMenu: [
        {
          name: getText("Ajouter"),
          title: getText("Ajouter un client"),
          icon: <FlagCircleIcon />,
          route: "user/add",
          permissions: ["customer.create"],
        },
        {
          name: getText("Listes"),
          title: getText("Liste des utilisateurs"),
          icon: <RecentActorsIcon />,
          route: "user/list",
          permissions: ["customer.view"],
        },
      ],
    },
    {
      name: getText("Commandes"),
      title: getText("Commandes"),
      icon: <ShoppingCartIcon />,
      route: "/order/list",
      isMain: true,
      permissions: ["order.create", "order.view"],
      subMenu: [
        {
          name: getText("Ajouter"),
          title: getText("Ajouter une commande"),
          icon: <FlagCircleIcon />,
          route: "order/add",
          permissions: ["order.create"],
        },
        {
          name: getText("Listes"),
          title: getText("Liste des commandes"),
          icon: <ListIcon />,
          route: "order/list",
          permissions: ["order.view"],
        },
      ],
    },
    {
      name: getText("Artisans"),
      title: getText("Artisans"),
      icon: <PersonIcon />,
      route: "/craftman/list",
      isMain: true,
      permissions: ["craftman.create", "craftman.view"],
      subMenu: [
        {
          name: getText("Ajouter"),
          title: getText("Ajouter un artisant"),
          icon: <FlagCircleIcon />,
          route: "craftman/add",
          permissions: ["craftman.create"],
        },
        {
          name: getText("Listes"),
          title: getText("Liste des artisants"),
          icon: <ListIcon />,
          route: "craftman/list",
          permissions: ["craftman.view"],
        },
      ],
    },
    {
      name: getText("Pays"),
      title: getText("Pays"),
      icon: <PublicIcon />,
      route: "/country/list",
      isMain: true,
      permissions: ["country.create", "country.view"],
      subMenu: [
        {
          name: getText("Ajouter"),
          title: getText("Ajouter un pays"),
          icon: <FlagCircleIcon />,
          route: "country/add",
          permissions: ["country.create"],
        },
        {
          name: getText("Listes"),
          title: getText("Liste des pays"),
          icon: <ListIcon />,
          route: "country/list",
          permissions: ["country.view"],
        },
        {
          name: getText("Pays des artisans"),
          title: getText("Pays des artisans"),
          icon: <ListIcon />,
          route: "country/craftman-list",
          permissions: ["country.view"],
        },
      ],
    },
    {
      name: getText("Segmentation"),
      icon: <SegmentIcon />,
      title: getText("Segmentations"),
      route: "/segmentation/list",
      isMain: true,
      permissions: ["segmentation.create", "segmentation.view"],
      subMenu: [
        {
          name: getText("Ajouter"),
          title: getText("Ajouter une segmentation"),
          icon: <ClassIcon />,
          route: "segmentation/add",
          permissions: ["segmentation.create"],
        },
        {
          name: "Listes",
          title: "Liste des segmentations",
          icon: <ListIcon />,
          route: "segmentation/list",
          permissions: ["segmentation.view"],
        },
      ],
    },
    {
      name: getText("Faqs"),
      icon: <SegmentIcon />,
      title: "Faqs",
      route: "/faqs/list",
      isMain: true,
      permissions: ["faqs.create", "faqs.view"],
      subMenu: [
        {
          name: getText("Ajouter"),
          title: getText("Ajouter un FAQS"),
          icon: <ClassIcon />,
          route: "faqs/add",
          permissions: ["faqs.create"],
        },
        {
          name: getText("Listes"),
          title: getText("Liste des FAQS"),
          icon: <ListIcon />,
          route: "faqs/list",
          permissions: ["faqs.view"],
        },
      ],
    },
    {
      name: getText("Catégories"),
      icon: <CategoryIcon />,
      title: getText("Catégories"),
      route: "/category/list",
      isMain: true,
      permissions: ["category.create", "category.view"],
      subMenu: [
        {
          name: getText("Ajouter"),
          title: getText("Ajouter une catégorie"),
          icon: <ClassIcon />,
          route: "category/add",
          permissions: ["category.create"],
        },
        {
          name: getText("Listes"),
          title: getText("Liste des catégories"),
          icon: <ListIcon />,
          route: "category/list",
          permissions: ["category.view"],
        },
      ],
    },
    {
      name: getText("Type articles"),
      icon: <FormatColorTextIcon />,
      title: getText("Type articles"),
      route: "/item-type/list",
      isMain: true,
      permissions: ["itemType.create", "itemType.view"],
      subMenu: [
        {
          name: getText("Ajouter"),
          title: getText("Ajouter une type article"),
          icon: <ClassIcon />,
          route: "item-type/add",
          permissions: ["itemType.create"],
        },
        {
          name: getText("Listes"),
          title: getText("Liste des type articles"),
          icon: <ListIcon />,
          route: "item-type/list",
          permissions: ["itemType.view"],
        },
      ],
    },
    {
      name: getText("Type matières"),
      icon: <CategoryIcon />,
      title: getText("Type matières"),
      route: "/material-type/list",
      permissions: ["materialType.create", "materialType.view"],
      isMain: true,
      subMenu: [
        {
          name: getText("Ajouter"),
          title: getText("Ajouter une type matière"),
          icon: <ClassIcon />,
          route: "material-type/add",
          permissions: ["materialType.create"],
        },
        {
          name: getText("Listes"),
          title: getText("Liste des type matières"),
          icon: <ListIcon />,
          route: "material-type/list",
          permissions: ["materialType.view"],
        },
      ],
    },
    {
      name: getText("Emails"),
      icon: <EmailIcon />,
      title: getText("Emails"),
      route: "/email/list",
      permissions: ["email.view"],
      isMain: true,
      subMenu: [
        {
          name: getText("Listes"),
          title: getText("Liste des emails"),
          icon: <ListIcon />,
          route: "email/list",
          permissions: ["email.view"],
        },
      ],
    },
    {
      name: getText("Administrateurs"),
      icon: <SupervisedUserCircleIcon />,
      title: getText("Liste des lieux"),
      route: "/admin/list",
      isMain: true,
      permissions: ["admin.create", "admin.view"],
      subMenu: [
        {
          name: getText("Ajouter"),
          title: "Ajouter un administrateur",
          icon: <PersonAddIcon />,
          route: "admin/add",
          permissions: ["admin.create"],
        },
        {
          name: getText("Listes"),
          icon: <PeopleIcon />,
          title: getText("Liste des administrateurs"),
          route: "admin/list",
          permissions: ["admin.create"],
        },
      ],
    },
    {
      name: getText("Transporteur"),
      icon: <SegmentIcon />,
      title: getText("Transporteur"),
      route: "/transporter/list",
      isMain: true,
      permissions: ["transporter.create", "transporter.view"],
      subMenu: [
        {
          name: getText("Ajouter"),
          title: getText("Ajouter un transporteur"),
          icon: <ClassIcon />,
          route: "transporter/add",
          permissions: ["transporter.create"],
        },
        {
          name: getText("Listes"),
          title: getText("Liste des transporteurs"),
          icon: <ListIcon />,
          route: "transporter/list",
          permissions: ["transporter.view"],
        },
      ],
    },
    {
      name: getText("Transporteur Zone"),
      icon: <SegmentIcon />,
      title: getText("Transporteur Zone"),
      route: "/transporter-zone/list",
      isMain: true,
      permissions: ["transporterZone.create", "transporterZone.view"],
      subMenu: [
        {
          name: getText("Ajouter"),
          title: getText("Ajouter une zone"),
          icon: <ClassIcon />,
          route: "transporter-zone/add",
          permissions: ["transporterZone.create"],
        },
        {
          name: getText("Listes"),
          title: getText("Liste des zones"),
          icon: <ListIcon />,
          route: "transporter-zone/list",
          permissions: ["transporterZone.view"],
        },
      ],
    },
    {
      name: getText("Transporteur Tarif"),
      icon: <SegmentIcon />,
      title: getText("Transporteur Tarif"),
      route: "/transporter-price/list",
      isMain: true,
      permissions: ["transporterPrice.create", "transporterPrice.view"],
      subMenu: [
        {
          name: getText("Ajouter"),
          title: getText("Ajouter un tarif"),
          icon: <ClassIcon />,
          route: "transporter-price/add",
          permissions: ["transporterPrice.create"],
        },
        {
          name: getText("Listes"),
          title: getText("Liste des tarifs"),
          icon: <ListIcon />,
          route: "transporter-price/list",
          permissions: ["transporterPrice.view"],
        },
      ],
    },
  ]
  const handleLogout = () => {
    sessionStorage.clear()
    dispatch(setCloseAlert())
    navigate("/login")
  }

  const handleOpenDialog = () => {
    dispatch(
      setOpenAlert({
        handleValid: handleLogout,
        message: getText("Êtes-vous vraiment sûr de vouloir se déconnecter ?"),
        isLoading: false,
      }),
    )
  }

  return (
    <React.Fragment>
      {menus.map((_el: Menu) => (
        <ListItem menu={_el} key={_el.name} />
      ))}
      <ListItemButtonStyled sx={{ mx: 1 }} onClick={() => handleOpenDialog()}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <ListItemText primary={getText("Déconnexion")} />
      </ListItemButtonStyled>
    </React.Fragment>
  )
}

export default ListMain
