import { useCallback, useEffect, useState } from "react"
import { graphql } from "../../../gql"
import { useQuery } from "@apollo/client"
import { QueryMode, SortOrder } from "../../../gql/graphql"
import InfiniteScrollAutocomplete from "../InfiniteScrollAutocomplete/InfiniteScrollAutocomplete"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { isSuperAdmin } from "../../../utils/permissions"
import { getUserCountryId } from "../../../utils/authToken"
import useLang from "../../../hooks/useLang"

const LIST_COUNTRIES = graphql(`
  query ListCountries(
    $where: CountryWhereInput
    $orderBy: [CountryOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    countries(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name_fr
      name_en
    }
  }
`)

interface Props {
  getCountry: (country: InfinityListItem | InfinityListItem[]) => void
  countryDefault: InfinityListItem | InfinityListItem[]
  label?: string
  required?: boolean
  multiple?: boolean
  isOpen?: boolean
}
const CustomListCountry = ({
  getCountry,
  countryDefault,
  label = "Pays",
  required = true,
  isOpen = false,
  multiple = false,
}: Props) => {
  const [country, setCountry] = useState(countryDefault)
  const { getByLang, getText } = useLang()
  const [queryCountry, setQueryCountry] = useState("")
  const [openCountry, setOpenCountry] = useState(false)
  const size = 500

  const queryName = useCallback(() => {
    const OrClause = {
      OR: [
        { name_fr: { search: queryCountry, mode: QueryMode.Insensitive } },
        { name_en: { search: queryCountry, mode: QueryMode.Insensitive } },
        { name_fr: { endsWith: queryCountry, mode: QueryMode.Insensitive } },
        { name_en: { endsWith: queryCountry, mode: QueryMode.Insensitive } },
        { name_fr: { contains: queryCountry, mode: QueryMode.Insensitive } },
        { name_en: { contains: queryCountry, mode: QueryMode.Insensitive } },
        { name_fr: { startsWith: queryCountry, mode: QueryMode.Insensitive } },
        { name_en: { startsWith: queryCountry, mode: QueryMode.Insensitive } },
      ],
    }
    return queryCountry && isSuperAdmin() ? OrClause : {}
  }, [queryCountry])

  const { loading, data, fetchMore } = useQuery(LIST_COUNTRIES, {
    variables: {
      take: size,
      skip: 0,
      orderBy: { name_fr: SortOrder.Asc },
      ...((isOpen || queryCountry || !isSuperAdmin()) && {
        where: {
          ...(isOpen &&
            isSuperAdmin() && {
              isOpen: {
                equals: isOpen,
              },
            }),
          ...queryName(),
          ...(!isSuperAdmin() && {
            id: {
              equals: getUserCountryId(),
            },
          }),
        },
      }),
    },
  })

  const handleSearchCategory = (query: string) => {
    setQueryCountry(query)
  }

  const handleCloseCategory = () => {
    setOpenCountry(false)
  }
  const handleOpenCountry = async () => {
    setOpenCountry(true)
  }

  const onEndReachedCategory = () => {
    fetchMore({
      variables: {
        skip: data?.countries.length,
      },
    })
  }

  useEffect(() => {
    if (country && countryDefault) {
      if (!Array.isArray(country) && !Array.isArray(countryDefault)) {
        if (!country?.value && countryDefault?.value) {
          setCountry(countryDefault)
        }
      } else {
        setCountry(countryDefault)
      }
    }
  }, [country, countryDefault])

  const dataCountry = data?.countries

  const countries =
    loading || !dataCountry
      ? []
      : dataCountry.map((el) => ({
          label: getByLang({ value_fr: el.name_fr, value_en: el.name_en }),
          value: el.id,
        }))

  return (
    <InfiniteScrollAutocomplete
      handleClose={handleCloseCategory}
      handleOpen={handleOpenCountry}
      open={openCountry}
      defaultValue={countryDefault}
      keyName="label"
      label={label != "Pays" ? label : getText(label)}
      onSearch={handleSearchCategory}
      options={openCountry ? countries : []}
      loading={loading}
      multiple={multiple}
      onChange={(value) => {
        if (value) {
          setCountry(value)
          getCountry(value)
        }
      }}
      query={queryCountry}
      value={country}
      onEndReached={onEndReachedCategory}
      required={required}
    />
  )
}

export default CustomListCountry
