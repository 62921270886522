import { Button, Grid, Paper, TextField } from "@mui/material"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"
import ReactPhoneInput from "react-phone-input-material-ui"
import CustomUploadFile from "../../Common/CustomUploadFile/CustomUploadFile"
import CustomTextFieldPassword from "../../Common/CustomTextFieldPassword/CustomTextFieldPassword"
import CustomListCountry from "../../Common/CustomListCountry/CustomListCountry"
import CustomListcategory from "../../Common/CustomListCategory/CustomListCategory"
import CustomListItemType from "../../Common/CustomListItemType/CustomListItemType"
import CustomListMaterialType from "../../Common/CustomListMaterialType/CustomListMaterialType"
import { InfinityListItem } from "../../../types/InfinityListItem"
import CustomUploadFiles, {
  MinTypeFile,
} from "../../Common/CustomUploadFiles/CustomUploadFiles"
import useLang from "../../../hooks/useLang"

interface Props {
  handleSubmit: (e: any) => void
  handleInputChange: (e: any) => void
  name: string
  lastName: string
  email: string
  yearOfExperience: string
  minQuantityOrder: string
  loading: boolean
  title: string
  password: string
  rePassword: string
  phoneNumber: string
  defaulPhoto?: MinTypeFile
  description: string
  segmentationId?: number
  getCategory: (category: InfinityListItem) => void
  categoryDefault: InfinityListItem
  getRePassword: (value: string) => void
  getPhoneNumber: (value: string) => void
  onClearUpload: (file: MinTypeFile) => void
  onFileUpload: (file: File) => void
  havePassword?: boolean
  getCountry: (country: InfinityListItem) => void
  countryDefault: InfinityListItem
  categoryId?: number
  getItemTypes: (itemItems: InfinityListItem[] | InfinityListItem) => void
  itemTypesDefault: InfinityListItem[]
  getMaterialTypes: (
    materialItems: InfinityListItem[] | InfinityListItem,
  ) => void
  materialTypesDefault: InfinityListItem[]
  onFilesUpload: (files: File[]) => void
  onClearUploads: (file: MinTypeFile) => void
  required?: boolean
  defaultUrls?: MinTypeFile[]
}

export default function FormCraftman({
  handleSubmit,
  handleInputChange,
  onFileUpload,
  onClearUpload,
  onClearUploads,
  getPhoneNumber,
  getRePassword,
  name,
  lastName,
  password,
  email,
  rePassword,
  phoneNumber,
  loading,
  title,
  defaulPhoto,
  yearOfExperience,
  minQuantityOrder,
  onFilesUpload,
  defaultUrls,
  required,
  havePassword = true,
  categoryDefault,
  countryDefault,
  description,
  getCategory,
  getCountry,
  getItemTypes,
  getMaterialTypes,
  itemTypesDefault,
  materialTypesDefault,
  categoryId,
  segmentationId,
}: Props) {
  const { getText } = useLang()
  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={1} lg={2} />
      <Grid item xs={12} md={12} lg={8}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <HeaderAdd title={title} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required={true}
                  name="name"
                  type="text"
                  onChange={handleInputChange}
                  label={getText("Nom")}
                  fullWidth
                  variant="outlined"
                  value={name}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  name="lastName"
                  type="text"
                  onChange={handleInputChange}
                  label={getText("Prénom")}
                  fullWidth
                  value={lastName}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label={getText("Email")}
                  name="email"
                  type="email"
                  onChange={handleInputChange}
                  fullWidth
                  value={email}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <ReactPhoneInput
                  label={getText("Numéro de téléphone*")}
                  value={phoneNumber}
                  onChange={(value) => getPhoneNumber(value)}
                  enableSearch={true}
                  component={TextField}
                  enableAreaCodes={true}
                  enableClickOutside={true}
                  enableAreaCodeStretch={true}
                  enableTerritories={true}
                  enableLongNumbers={true}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label={getText("Nombre d'année d'experience")}
                  name="yearOfExperience"
                  type="number"
                  onChange={handleInputChange}
                  fullWidth
                  value={yearOfExperience}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label={getText("Minimum de commande")}
                  name="minQuantityOrder"
                  type="number"
                  onChange={handleInputChange}
                  fullWidth
                  value={minQuantityOrder}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomTextFieldPassword
                  name="password"
                  onChange={handleInputChange}
                  label={getText("Mot de passe")}
                  fullWidth
                  value={password}
                  required={havePassword}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomTextFieldPassword
                  label={getText("Confirmation de mot de passe")}
                  fullWidth
                  name="rePassword"
                  value={rePassword}
                  required={havePassword}
                  onChange={(e) => getRePassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomListCountry
                  countryDefault={countryDefault}
                  getCountry={(value) => getCountry(value as InfinityListItem)}
                  isOpen={true}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomListcategory
                  categoryDefault={categoryDefault}
                  getCategory={getCategory}
                  segmentationId={segmentationId}
                  key={segmentationId}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomListItemType
                  getItemTypes={getItemTypes}
                  itemTypesDefault={itemTypesDefault}
                  categoryId={categoryId}
                  key={categoryId}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CustomListMaterialType
                  getMaterialTypes={getMaterialTypes}
                  materialTypesDefault={materialTypesDefault}
                  categoryId={categoryId}
                  key={categoryId}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  required={false}
                  label={getText("Déscription")}
                  name="description"
                  type="text"
                  onChange={handleInputChange}
                  fullWidth
                  value={description}
                  multiline
                  minRows={3}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomUploadFile
                  onClearUpload={onClearUpload}
                  onFileUpload={onFileUpload}
                  label={getText("Photo de profile")}
                  id="photo-de-profile"
                  defaultUrl={defaulPhoto}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomUploadFiles
                  id="photo-de-realisationq"
                  label={getText("Photos de réalisation*")}
                  onClearUpload={onClearUploads}
                  onFilesUpload={onFilesUpload}
                  required={required}
                  defaultUrls={defaultUrls}
                />
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  style={{ background: "#5D490E" }}
                  disabled={loading}
                  variant="contained"
                >
                  {getText("Valider")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={1} lg={2} />
    </Grid>
  )
}
