import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import userReducer from "../features/user/userSlice"
import menuReducer from "../features/menu/menuSlice"
import countryReducer from "../features/country/countrySlice"
import snackbarReducer from "../features/snackbar/snackbarSlice"
import customerReducer from "../features/customer/customerSlice"
import alertReducer from "../features/alert/alertSlice"
import segmentationReducer from "../features/segmentation/segmentationSlice"
import faqsReducer from "../features/faqs/faqsSlice"
import categoryReducer from "../features/category/categorySlice"
import materialTypeReducer from "../features/materialType/materialTypeSlice"
import itemTypeReducer from "../features/itemType/itemTypeSlice"
import craftmanReducer from "../features/craftman/craftmanSlice"
import orderReducer from "../features/order/orderSlice"
import tenderReducer from "../features/tender/tenderSlice"
import transporterReducer from "../features/transporter/transporterSlice"
import transporterPriceReducer from "../features/transporterPrice/transporterPriceSlice"
import transporterZoneReducer from "../features/transporterZone/transporterZoneSlice"
import langReducer from "../features/lang/langSlice"
import { setupListeners } from "@reduxjs/toolkit/query"

export const store = configureStore({
  reducer: {
    user: userReducer,
    menu: menuReducer,
    country: countryReducer,
    snackbar: snackbarReducer,
    customer: customerReducer,
    alert: alertReducer,
    segmentation: segmentationReducer,
    faqs: faqsReducer,
    category: categoryReducer,
    materialType: materialTypeReducer,
    itemType: itemTypeReducer,
    craftman: craftmanReducer,
    order: orderReducer,
    tender: tenderReducer,
    transporter: transporterReducer,
    transporterPrice: transporterPriceReducer,
    transporterZone: transporterZoneReducer,
    lang: langReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["alert/setOpenAlert", "alert/setCloseAlert"],
        // Ignore these paths in the state
        ignoredPaths: ["alert.handleValid"],
      },
    }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

setupListeners(store.dispatch)
