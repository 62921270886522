import { Button, Grid, Paper, Typography, useTheme } from "@mui/material"
import HeaderAdd from "../../Common/HeaderAdd/HeaderAdd"
import { graphql } from "../../../gql"
import { useNavigate, useParams } from "react-router-dom"
import { useLazyQuery, useMutation } from "@apollo/client"
import { useCallback, useEffect } from "react"
import { File, OrderPrice, SortOrder } from "../../../gql/graphql"
import useOrder from "../../../hooks/useOrder"
import moment from "moment"
import { getStringEmpty } from "../../../utils/methodeString"
import Loader from "../../Common/Loader/Loader"
import OrderImages from "./OrderImages/OrderImages"
import Chat from "./Chat/Chat"
import { havePermissions } from "../../../utils/permissions"
import useLang from "../../../hooks/useLang"
import { useAppDispatch } from "../../../store/app/hooks"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"

const GET_ORDER = graphql(`
  query GetOrder(
    $where: OrderWhereUniqueInput!
    $orderBy: [OrderPriceOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
  ) {
    getOrder(where: $where) {
      id
      status
      createdAt
      delivryDate
      quantity
      orderStatus {
        haveAmountNotPaid
        haveAmountPaid
        notPaid
      }
      Country {
        id
        name_fr
        name_en
      }
      Customer {
        id
        name
        lastName
        dateOfLastConnexion
        phoneNumber
      }
      Craftman {
        id
        name
        lastName
        dateOfLastConnexion
        phoneNumber
      }
      Prices(orderBy: $orderBy) {
        id
        price
        status
        currenctUserPrice
      }
      Categories {
        id
        name_fr
        name_en
      }
      ItemTypes {
        id
        name_fr
        name_en
      }
      MaterialTypes {
        id
        name_fr
        name_en
      }
      Photos {
        id
        url
        name
      }
      Chats(take: $take) {
        id
        orderId
      }
    }
  }
`)

const CALL_USER = graphql(`
  mutation SendEmailMessagePending($userId: Int!) {
    sendEmailMessagePending(userId: $userId)
  }
`)

const CALL_DELIVERY = graphql(`
  mutation SendEmailValidOrderDelivery($customerId: Int!, $craftmanId: Int!) {
    sendEmailValidOrderDelivery(
      customerId: $customerId
      craftmanId: $craftmanId
    )
  }
`)

const DetailOrder = () => {
  const { getText, getByLang } = useLang()
  const globalTheme = useTheme()
  const params = useParams()
  const navigate = useNavigate()
  const id = params.id ? parseInt(params.id, 10) : 0
  const [getOrder, { data, loading }] = useLazyQuery(GET_ORDER)
  const dispatch = useAppDispatch()

  const [callCustomer, { loading: loadingCallCustomer }] =
    useMutation(CALL_USER)

  const [callCraftman, { loading: loadingCallCraftman }] =
    useMutation(CALL_USER)

  const [callDelivery, { loading: loadingCallDelivery }] =
    useMutation(CALL_DELIVERY)

  const { getCustomerOrderPrice, getLastOrderPriceValid } = useOrder()

  useEffect(() => {
    getOrder({
      variables: {
        where: {
          id,
        },
        orderBy: {
          createdAt: SortOrder.Desc,
        },
        take: 1,
      },
    })
  }, [id])

  const handleGoListHistory = () => {
    navigate(`/order/edit-story/${id}`)
  }

  const handleGoPaymentHistory = () => {
    navigate(`/order/payment-story/${id}`)
  }

  const chatId = data?.getOrder?.Chats.length
    ? data?.getOrder?.Chats[0].id
    : undefined

  const orderId = data?.getOrder?.Chats.length
    ? data?.getOrder?.Chats[0].orderId
    : 0

  const craftmanId = data?.getOrder?.Craftman?.id
    ? data?.getOrder?.Craftman?.id
    : 0

  const customerId = data?.getOrder?.Customer.id
    ? data?.getOrder?.Customer.id
    : 0

  const handleCallCustomer = useCallback(() => {
    if (!loadingCallCustomer) {
      callCustomer({
        variables: {
          userId: customerId,
        },
        onCompleted() {
          const message = "successCallCustomer"
          dispatch(
            setOpenSnackbar({ message: getText(message), status: "success" }),
          )
        },
        onError: (error) => {
          const message = getErrorsAsString(error)
          dispatch(setOpenSnackbar({ message }))
        },
      })
    }
  }, [customerId, loadingCallCustomer])

  const handleCallCraftamn = useCallback(() => {
    if (!loadingCallCraftman) {
      callCraftman({
        variables: {
          userId: craftmanId,
        },
        onCompleted() {
          const message = "successCallCraftman"
          dispatch(
            setOpenSnackbar({ message: getText(message), status: "success" }),
          )
        },
        onError: (error) => {
          const message = getErrorsAsString(error)
          dispatch(setOpenSnackbar({ message }))
        },
      })
    }
  }, [craftmanId, loadingCallCraftman])

  const handleCallDelivery = useCallback(() => {
    if (!loadingCallDelivery) {
      callDelivery({
        variables: {
          craftmanId,
          customerId,
        },
        onCompleted() {
          const message = "successCallDelivery"
          dispatch(
            setOpenSnackbar({ message: getText(message), status: "success" }),
          )
        },
        onError: (error) => {
          const message = getErrorsAsString(error)
          dispatch(setOpenSnackbar({ message }))
        },
      })
    }
  }, [craftmanId, loadingCallDelivery, customerId])

  const getDate = (date: any) => {
    const formattedDate =
      date && moment(date).isValid()
        ? moment(date).format("DD MMM YYYY [à] HH[h]mm")
        : getText("Pas définie")
    return formattedDate
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  if (loading) return <Loader />

  return (
    <Grid container spacing={2} id="container-chat-message">
      <Grid item xs={12} md={chatId ? 6 : 12}>
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="success"
                sx={{
                  color: "#fff",
                  background: globalTheme.palette.success.main,
                }}
                size="small"
                onClick={handleRefresh}
              >
                {getText("Actualiser")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <HeaderAdd
                title={getText("Information générale de la commande")}
                variant="h4"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <b>{getText("ID commande")}: </b>
                {data?.getOrder?.id}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("Date commande client")}: </b>
                {getDate(data?.getOrder?.createdAt)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("Quantité(s)")}: </b>
                {getStringEmpty(data?.getOrder?.quantity?.toString())}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("Status")}: </b>
                {data?.getOrder?.status}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("ID de l'artisan")}: </b>
                {data?.getOrder?.Craftman?.id}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("Nom et prénom de l'artisan")}: </b>
                {getStringEmpty(data?.getOrder?.Craftman?.name)}{" "}
                {getStringEmpty(data?.getOrder?.Craftman?.lastName)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("Numéro artisan")} : </b>
                {getStringEmpty(data?.getOrder?.Craftman?.phoneNumber)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("ID de client")}:</b>
                {data?.getOrder?.Customer?.id}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("Nom et prénom de client")}: </b>
                {getStringEmpty(data?.getOrder?.Customer?.name)}{" "}
                {getStringEmpty(data?.getOrder?.Customer?.lastName)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("Numéro client")} : </b>
                {getStringEmpty(data?.getOrder?.Customer?.phoneNumber)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("Catégorie")}: </b>
                {data?.getOrder?.Categories?.flatMap((category) =>
                  getByLang({
                    value_fr: category?.name_fr || "",
                    value_en: category?.name_en,
                  }),
                ).toString()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("Type article")}: </b>
                {data?.getOrder?.ItemTypes?.flatMap((item) =>
                  getByLang({
                    value_fr: item?.name_fr || "",
                    value_en: item?.name_en,
                  }),
                ).toString()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("Type matière")}: </b>
                {data?.getOrder?.MaterialTypes?.flatMap((m) =>
                  getByLang({
                    value_fr: m?.name_fr || "",
                    value_en: m?.name_en,
                  }),
                ).toString()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("Prix initial de client")}: </b>{" "}
                {getCustomerOrderPrice(
                  (data?.getOrder?.Prices as OrderPrice[]) || [],
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("Dernier prix validé")}: </b>
                {getLastOrderPriceValid(
                  (data?.getOrder?.Prices as OrderPrice[]) || [],
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("Pays de destination")}: </b>
                {getStringEmpty(
                  getByLang({
                    value_fr: data?.getOrder?.Country?.name_fr || "",
                    value_en: data?.getOrder?.Country?.name_en,
                  }),
                )}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>{getText("Dernière connexion artisan")}: </b>
                {getDate(data?.getOrder?.Craftman?.dateOfLastConnexion)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <b>{getText("Dernière connexion client")}: </b>
                {getDate(data?.getOrder?.Customer.dateOfLastConnexion)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <b>{getText("Date de livraison")}: </b>
                {getDate(data?.getOrder?.delivryDate)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <b>{getText("Modèles photos de la commande")}:</b>
              </Typography>
            </Grid>
            <Grid item xs={12} lg={chatId ? 12 : 6} md={chatId ? 12 : 8}>
              <OrderImages images={(data?.getOrder?.Photos as File[]) || []} />
            </Grid>
            {havePermissions(["order.view"]) && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ background: "#5D0E43FF" }}
                    onClick={handleGoListHistory}
                  >
                    {getText("Voir les historiques de modification")}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ background: "#385D0EFF" }}
                    onClick={handleCallDelivery}
                    disabled={loadingCallDelivery}
                  >
                    {getText("Envoyer l'email de livraison")}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ background: "#5D0E1BFF" }}
                    onClick={handleGoPaymentHistory}
                  >
                    {getText("Voir les historiques de paiement")}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ background: "#4B0E5DFF" }}
                    onClick={handleCallCustomer}
                    disabled={loadingCallCustomer}
                  >
                    {getText("Rappeller le client")}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ background: "#0E515DFF" }}
                    onClick={handleCallCraftamn}
                    disabled={loadingCallCraftman}
                  >
                    {getText("Rappeller l'artisan")}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </Grid>
      {chatId && (
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 2,
            }}
          >
            <Chat
              chatId={chatId}
              orderId={orderId}
              craftmanId={craftmanId}
              customerId={customerId}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  )
}

export default DetailOrder
