import Box from "@mui/material/Box"
import { Grid, Paper, Tab, Typography } from "@mui/material"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { SyntheticEvent, useCallback, useEffect, useState } from "react"
import ListOrder from "../../Order/ListOrder/ListOrder"
import { useNavigate, useParams } from "react-router-dom"
import { Action, Status } from "../../../gql/graphql"
import { graphql } from "../../../gql"
import { useLazyQuery } from "@apollo/client"
import Loader from "../../Common/Loader/Loader"
import { useIntl } from "react-intl"
import ListAction from "./ListAction/ListAction"
import { isSuperAdmin } from "../../../utils/permissions"
import { getUserCountryId } from "../../../utils/authToken"
import useLang from "../../../hooks/useLang"

const GET_CRAFTMAN = graphql(`
  query GetOneCraftmanWallet($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      jackpotCraftman
      lastName
      workshopName
      country {
        id
        name_fr
        currency
      }
    }
  }
`)

export default function WalletCraftman() {
  const urlParams = new URLSearchParams(window.location.search)
  const tabs = urlParams.get("tabs")
  const tabsValus = ["1", "2", "3"]
  const [value, setValue] = useState("1")
  const intl = useIntl()
  const params = useParams()
  const id = params.id ? parseInt(params.id, 10) : 0
  const { getText } = useLang()
  const [getCraftman, { data, loading, refetch }] = useLazyQuery(GET_CRAFTMAN)
  const navigate = useNavigate()
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  useEffect(() => {
    getCraftman({
      variables: {
        where: {
          id,
        },
      },
      onCompleted(data) {
        if (!isSuperAdmin()) {
          if (data.user?.country?.id != getUserCountryId()) {
            navigate("/")
          }
        }
      },
    })
  }, [id])

  useEffect(() => {
    if (tabs && tabsValus.includes(tabs)) {
      setValue(tabs)
    }
  }, [tabs])

  const styleTabPanel = { maxWidth: "94Vw", overflow: "auto" }

  const cagnotte = useCallback(() => {
    const priceValue = intl.formatNumber(data?.user?.jackpotCraftman || 0, {
      currency: data?.user?.country?.currency || "XOF",
      currencyDisplay: "narrowSymbol",
      style: "currency",
    })
    return priceValue
  }, [data])

  const handleRefresh = () => {
    refetch()
  }

  if (loading) return <Loader />

  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
            }}
          >
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <Typography>
                  <b>ID:</b> {data?.user?.id}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <b>{getText("Prénom ou atélier")}:</b>{" "}
                  {data?.user?.lastName || data?.user?.workshopName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <b>{getText("Cagnotte")}:</b> {cagnotte()}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container mt={2} bgcolor={"#5D490E"} borderRadius={1}>
        <Grid item lg={12}>
          <TabContext value={value}>
            <TabList
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                label={getText("Divers actions")}
                value="1"
                sx={{ color: "ActiveCaption" }}
                wrapped
              />
              <Tab
                label={getText("Commandes payées ou livrées")}
                value="3"
                sx={{ color: "ActiveCaption" }}
                wrapped
              />
            </TabList>
            <TabPanel value="1" style={styleTabPanel}>
              <ListAction
                id={id}
                jackpot={data?.user?.jackpotCraftman || 0}
                handleRefresh={handleRefresh}
              />
            </TabPanel>
            <TabPanel value="3" style={styleTabPanel}>
              <ListOrder
                extraQuery={{
                  Craftman: {
                    is: {
                      id: {
                        equals: id,
                      },
                    },
                  },
                  OR: [
                    {
                      status: {
                        in: [Status.Paid, Status.Delivry],
                      },
                    },
                    {
                      status: {
                        equals: Status.Archive,
                      },
                      ActionOrders: {
                        some: {
                          action: {
                            in: [Action.Delivry, Action.Paid],
                          },
                        },
                      },
                    },
                  ],
                }}
              />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Box>
  )
}
