import { useCallback, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  selectTransporterPagination,
  selectTransporterQuery,
  setTransporterPagination,
  setTransporterQuery,
} from "../../../store/features/transporter/transporterSlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import {
  Transporter,
  SortOrder,
  TransporterWhereInput,
} from "../../../gql/graphql"
import { MRT_ColumnDef, MRT_ColumnFiltersState } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { updateCacheTransporter } from "../../../caches/updateCacheTransporter"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import { havePermissions, whereInputClause } from "../../../utils/permissions"
import { Box } from "@mui/material"
import ModalCountries from "../../Common/ModalCountries/ModalCountries"
import { getUserCountryId } from "../../../utils/authToken"
import useLang from "../../../hooks/useLang"

export const LIST_TRANSPORTERS = graphql(`
  query transporters_total(
    $where: TransporterWhereInput
    $where2: TransporterWhereInput
    $orderBy: [TransporterOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    aggregateTransporter(where: $where2) {
      _count {
        _all
      }
    }
    transporters(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name
      phoneNumber
      email
      webSite
      address
      isLocal
      TransporterZones {
        id
        name
        Countries {
          id
          name_fr
        }
      }
    }
  }
`)

export const DELETE_TRANSPORTER = graphql(`
  mutation DeleteOneTransporter($where: TransporterWhereUniqueInput!) {
    deleteOneTransporter(where: $where) {
      id
      name
      phoneNumber
      email
    }
  }
`)

const LIST_FILTER = graphql(`
  query filtersListTransporters(
    $where: CountryWhereInput
    $transporterZonesWhere2: TransporterZoneWhereInput
  ) {
    countries(where: $where) {
      name_fr
      id
    }
    transporterZones(where: $transporterZonesWhere2) {
      id
      name
    }
  }
`)

const ListTransporter = () => {
  const { page, size } = useAppSelector(selectTransporterPagination)
  const query = useAppSelector(selectTransporterQuery)
  const dispatch = useAppDispatch()
  const { getText } = useLang()
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])

  const countryId = getUserCountryId()
  const whereCluase = whereInputClause({
    variables: {
      transporterZonesWhere2: {
        Countries: {
          some: {
            id: {
              equals: countryId,
            },
          },
        },
      },
      where: {
        id: {
          equals: countryId,
        },
      },
    },
  })

  const { loading: loadingFilter, data: dataFilter } = useQuery(
    LIST_FILTER,
    whereCluase,
  )
  const navigate = useNavigate()

  const getValueFilter = (el: {
    name_fr?: string
    id: number
    name?: string
  }) => {
    return {
      label: el.name_fr ? el.name_fr : el.name,
      value: el.id.toString(),
      text: el.name_fr ? el.name_fr : el.name,
    }
  }

  const getFilters: () => TransporterWhereInput = useCallback(() => {
    if (columnFilters.length) {
      return columnFilters.reduce(
        (
          accumulator: TransporterWhereInput,
          { id, value }: { id: string; value: any },
        ) => {
          switch (id) {
            case "Countries":
              accumulator = {
                ...accumulator,
                TransporterZones: {
                  some: {
                    Countries: {
                      some: {
                        id: {
                          equals: Number(value),
                        },
                      },
                    },
                  },
                },
              }
              break
            case "TransporterZones":
              accumulator = {
                ...accumulator,
                TransporterZones: {
                  some: {
                    id: {
                      equals: Number(value),
                    },
                  },
                },
              }
              break
            case "name":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    name: {
                      search: value,
                    },
                  },
                  {
                    name: {
                      contains: value,
                    },
                  },
                ],
              }
              break
            case "id":
              accumulator = {
                ...accumulator,
                id: {
                  equals: Number(value),
                },
              }
              break
            case "email":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    email: {
                      search: value,
                    },
                  },
                  {
                    email: {
                      contains: value,
                    },
                  },
                ],
              }
              break
            case "phoneNumber":
              accumulator = {
                ...accumulator,
                OR: [
                  {
                    phoneNumber: {
                      search: value,
                    },
                  },
                  {
                    phoneNumber: {
                      contains: value,
                    },
                  },
                ],
              }
              break
            case "isLocal":
              accumulator = {
                ...accumulator,
                isLocal: {
                  equals: value.includes("loc"),
                },
              }
              break
          }
          return accumulator
        },
        {},
      )
    }
    return {}
  }, [columnFilters])

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            { name_fr: { search: query } },
            { phoneNumber: { search: query } },
            { email: { search: query } },
            {
              id: {
                equals: Number(query),
              },
            },
          ],
        }
      : {}
  }, [query])

  const [getTransporters, { loading, data }] = useLazyQuery(LIST_TRANSPORTERS)

  useEffect(() => {
    getTransporters({
      variables: {
        take: size,
        skip: page * size,
        orderBy: { id: SortOrder.Desc },
        where: {
          ...queryName(),
          ...getFilters(),
          ...whereInputClause({
            TransporterZones: {
              some: { Countries: { some: { id: { equals: countryId } } } },
            },
          }),
        },
        where2: {
          ...queryName(),
          ...getFilters(),
          ...whereInputClause({
            TransporterZones: {
              some: { Countries: { some: { id: { equals: countryId } } } },
            },
          }),
        },
      },
    })
  }, [getTransporters, page, query, queryName, size, getFilters])

  const [deleteTransporter] = useMutation(DELETE_TRANSPORTER)

  const columns = useMemo<MRT_ColumnDef<Transporter>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
        filterVariant: "text",
        id: "id",
      },
      {
        accessorKey: "name",
        header: getText("Nom de transporteur"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "name",
      },
      {
        accessorKey: "email",
        header: getText("Email de transporteur"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "email",
      },
      {
        accessorKey: "phoneNumber",
        header: getText("Téléphone de transporteur"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "phoneNumber",
      },
      {
        accessorFn: (row) =>
          row.TransporterZones.map((el) => el.name).join(", "),
        header: getText("Zones"),
        enableClickToCopy: true,
        filterVariant: "select",
        id: "TransporterZones",
        filterSelectOptions: dataFilter?.transporterZones.map((el) =>
          getValueFilter(el),
        ),
      },
      {
        accessorFn: (row) => (row.isLocal ? "Locale" : "Internationale"),
        header: getText("Type de transporteur"),
        enableClickToCopy: true,
        filterVariant: "text",
        id: "isLocal",
      },
      {
        accessorFn: (row) => {
          if (!row?.TransporterZones?.length) return null
          const uniqueNames = new Set(
            row.TransporterZones.flatMap((el) => el.Countries).map(
              (el) => el.name_fr,
            ),
          )
          return (
            <>
              <Box>{Array.from(uniqueNames).join(", ").slice(0, 100)}...</Box>
              <Box>
                <ModalCountries countries={Array.from(uniqueNames)} />
              </Box>
            </>
          )
        },
        header: getText("Pays"),
        filterVariant: "select",
        id: "Countries",
        filterSelectOptions: dataFilter?.countries.map((el) =>
          getValueFilter(el),
        ),
      },
    ],
    [dataFilter, getText],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setTransporterPagination(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deleteTransporter({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: getText("Le transporteur a été supprimé avec succès"),
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheTransporter({
          action: "delete",
          cache,
          entryData: data?.deleteOneTransporter,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: getText(
          "Êtes-vous vraiment sûr de vouloir supprimer ce transporteur ?",
        ),
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) =>
    dispatch(setTransporterQuery(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle(getText("Modifier un transporteur")))
    navigate(`/transporter/edit/${id}`)
  }

  const handleRefresh = () => {
    window.location.reload()
  }

  if (loadingFilter) return <Loader />

  return (
    <CustomTable
      enableColumnFilters
      columns={columns}
      data={data?.transporters || []}
      lableAddNew={getText("Créer un nouveau transporteur")}
      rootLisName={"/transporter/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateTransporter._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={
        havePermissions(["transporter.delete"])
          ? handleOpenDeleteDialog
          : undefined
      }
      isHaveHeaderAction={havePermissions(["transporter.create"])}
      handleEdit={
        havePermissions(["transporter.change"]) ? handleEdit : undefined
      }
      isHaveView={false}
      isHaveAction={havePermissions([
        "transporter.view",
        "transporter.change",
        "transporter.delete",
      ])}
      handleRefresh={handleRefresh}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
    />
  )
}

export default ListTransporter
