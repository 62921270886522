import * as React from "react"
import MoodOutlinedIcon from "@mui/icons-material/MoodOutlined"
import Popover from "@mui/material/Popover"
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state"
import EmojiPicker, { SuggestionMode } from "emoji-picker-react"
import { MouseDownEvent } from "emoji-picker-react/dist/config/config"
import useLang from "../../../../../hooks/useLang"

interface Props {
  handleSelectEmoji: MouseDownEvent
}

export default function SelectEmoji({ handleSelectEmoji }: Props) {
  const { getText } = useLang()
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <MoodOutlinedIcon
            sx={{ cursor: "pointer" }}
            fontSize="large"
            {...bindTrigger(popupState)}
          />
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
          >
            <EmojiPicker
              open={true}
              width={250}
              searchPlaceHolder={getText("Rechercher...")}
              suggestedEmojisMode={SuggestionMode.RECENT}
              onEmojiClick={handleSelectEmoji}
            />
          </Popover>
        </div>
      )}
    </PopupState>
  )
}
