import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { useState } from "react"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { graphql } from "../../../gql"
import { useMutation } from "@apollo/client"
import { updateCacheTransporter } from "../../../caches/updateCacheTransporter"
import FormTransporter from "../FormTransporter/FormTransporter"
import { InfinityListItem } from "../../../types/InfinityListItem"
import useLang from "../../../hooks/useLang"

// Signin mutation
const addTransporterMutation = graphql(`
  mutation addTransporter($data: TransporterCreateInput!) {
    createOneTransporter(data: $data) {
      id
      name
      phoneNumber
      email
      webSite
      address
      isLocal
      TransporterZones {
        id
        name
        Countries {
          id
          name_fr
        }
      }
    }
  }
`)

export default function AddTransporter() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { getText } = useLang()

  const [transporter, setTransporter] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    webSite: "",
    address: "",
    isLocal: "no",
  })

  const [createTransporter, { loading }] = useMutation(addTransporterMutation)
  const [transporterZones, setTransporterZones] = useState<InfinityListItem[]>(
    [],
  )

  const handleInputChange = (event: any) => {
    setTransporter({
      ...transporter,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateTransporter = (e: any) => {
    e.preventDefault()
    if (!transporterZones.length) {
      dispatch(
        setOpenSnackbar({
          message: getText("Veuillez choisir les zones de ce transporteur."),
        }),
      )
      return
    }
    createTransporter({
      variables: {
        data: {
          ...transporter,
          isLocal: transporter.isLocal === "yes",
          TransporterZones: {
            connect: transporterZones.map((el) => ({ id: +el.value })),
          },
        },
      },
      onCompleted: () => {
        navigate("/transporter/list")
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCacheTransporter({
          action: "add",
          cache,
          entryData: data?.createOneTransporter,
        })
      },
    })
  }

  const getTransporterZone = (transporterZones: InfinityListItem[]) => {
    setTransporterZones(transporterZones)
  }

  return (
    <FormTransporter
      handleInputChange={handleInputChange}
      handleSubmit={handleCreateTransporter}
      loading={loading}
      email={transporter.email}
      phoneNumber={transporter.phoneNumber}
      name={transporter.name}
      webSite={transporter.webSite}
      address={transporter.address}
      title={getText("Ajouter un transporteur")}
      getTransporterZone={getTransporterZone}
      transporterZoneDefault={transporterZones}
      isLocal={transporter.isLocal as "yes" | "no"}
    />
  )
}
