import { useCallback, useEffect, useMemo, useState } from "react"
import { graphql } from "../../../gql"
import { useQuery } from "@apollo/client"
import { QueryMode, Role, SortOrder } from "../../../gql/graphql"
import InfiniteScrollAutocomplete from "../InfiniteScrollAutocomplete/InfiniteScrollAutocomplete"
import { InfinityListItem } from "../../../types/InfinityListItem"
import { getStringEmpty } from "../../../utils/methodeString"

const LIST_USERS = graphql(`
  query UsersList(
    $where: UserWhereInput
    $take: Int
    $skip: Int
    $orderBy: [UserOrderByWithRelationAndSearchRelevanceInput!]
  ) {
    users(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      name
      lastName
    }
  }
`)

interface Props {
  getUser: (user: InfinityListItem) => void
  userDefault: InfinityListItem
  role: Role
  label: string
  required: boolean
  isID?: boolean
  categoryId?: number
}
const CustomListUser = ({
  getUser,
  userDefault,
  role,
  label,
  required,
  isID,
  categoryId,
}: Props) => {
  const [user, setuser] = useState(userDefault)

  const [queryuser, setQueryuser] = useState("")
  const [openuser, setOpenuser] = useState(false)
  const size = 20

  const queryName = useCallback(() => {
    return queryuser
      ? {
          OR: !isNaN(Number(queryuser))
            ? [
                {
                  id: {
                    equals: Number(queryuser),
                  },
                },
              ]
            : [
                { name: { search: queryuser, mode: QueryMode.Insensitive } },
                {
                  lastName: { search: queryuser, mode: QueryMode.Insensitive },
                },
                { email: { search: queryuser, mode: QueryMode.Insensitive } },
                {
                  workshopName: {
                    search: queryuser,
                    mode: QueryMode.Insensitive,
                  },
                },
              ],
        }
      : {}
  }, [queryuser])

  const { loading, data, fetchMore } = useQuery(LIST_USERS, {
    variables: {
      take: size,
      skip: 0,
      orderBy: { name: SortOrder.Asc },
      where: {
        ...queryName(),
        ...(categoryId &&
          !queryuser && {
            Categories: {
              some: {
                id: {
                  equals: categoryId,
                },
              },
            },
          }),
        role: {
          equals: role,
        },
      },
    },
  })

  const handleSearchuser = (query: string) => {
    setQueryuser(query)
  }

  const handleCloseuser = () => {
    setOpenuser(false)
  }
  const handleOpenuser = async () => {
    setOpenuser(true)
  }

  const onEndReacheduser = () => {
    fetchMore({
      variables: {
        skip: data?.users.length,
      },
    })
  }

  useEffect(() => {
    if (!user?.value && userDefault?.value) {
      setuser(userDefault)
    }
  }, [user.value, userDefault])

  const datauser = useMemo(() => data?.users, [data])

  const users = useMemo(() => {
    return loading || !datauser
      ? []
      : datauser.map((el) => ({
          label: isID ? el.id : `${el.name}${getStringEmpty(el?.lastName)}`,
          value: el.id,
        }))
  }, [loading, datauser, isID, getStringEmpty])

  return (
    <InfiniteScrollAutocomplete
      handleClose={handleCloseuser}
      handleOpen={handleOpenuser}
      open={openuser}
      defaultValue={userDefault}
      keyName="label"
      label={label}
      onSearch={handleSearchuser}
      options={openuser ? users : []}
      loading={loading}
      onChange={(value) => {
        if (value) {
          setuser(value)
          getUser(value)
        }
      }}
      query={queryuser}
      value={user}
      onEndReached={onEndReacheduser}
      required={required}
    />
  )
}

export default CustomListUser
