import { useCallback, useMemo, useState } from "react"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { AskDeliveryMan, SortOrder } from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { useQuery } from "@apollo/client"
import moment from "moment"
import OrderImages from "../../Order/ListOrder/OrderImages/OrderImages"
import { getUserCountryId } from "../../../utils/authToken"
import { whereInputClause } from "../../../utils/permissions"
import useLang from "../../../hooks/useLang"

export const LIST_IMENS = graphql(`
  query AskDeliveryMen(
    $where: AskDeliveryManWhereInput
    $orderBy: [AskDeliveryManOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
    $aggregateAskDeliveryManWhere2: AskDeliveryManWhereInput
  ) {
    askDeliveryMen(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      craftmanId
      address
      codeTracking
      customerId
      orderId
      delivryDate
      createdAt
      photos {
        id
        url
      }
    }
    aggregateAskDeliveryMan(where: $aggregateAskDeliveryManWhere2) {
      _count {
        _all
      }
    }
  }
`)

const ListAskDelivryMan = () => {
  const { getText } = useLang()
  const countryId = getUserCountryId()
  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    size: 10,
  })
  const [query, setQuery] = useState("")
  const queryName = useCallback(() => {
    return query
      ? {
          OR: [
            {
              Craftman: {
                is: {
                  OR: [
                    {
                      id: {
                        equals: Number(query),
                      },
                    },
                    {
                      lastName: {
                        search: query,
                      },
                    },
                    {
                      workshopName: {
                        search: query,
                      },
                    },
                  ],
                },
              },
            },
            {
              id: {
                equals: Number(query),
              },
            },
            {
              Customer: {
                is: {
                  OR: [
                    {
                      id: {
                        equals: Number(query),
                      },
                    },
                    {
                      lastName: {
                        search: query,
                      },
                    },
                    {
                      email: {
                        search: query,
                      },
                    },
                  ],
                },
              },
            },
          ],
        }
      : {}
  }, [query])

  const { loading, data } = useQuery(LIST_IMENS, {
    variables: {
      take: pagination.size,
      skip: pagination.page * pagination.size,
      orderBy: { delivryDate: SortOrder.Desc },
      where: {
        ...queryName(),
        ...whereInputClause({
          Craftman: {
            is: {
              country: {
                is: {
                  id: {
                    equals: countryId,
                  },
                },
              },
            },
          },
        }),
      },
    },
  })

  const columns = useMemo<MRT_ColumnDef<AskDeliveryMan>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) =>
          row.delivryDate
            ? moment(row.delivryDate).format("DD MMM YYYY")
            : getText("Pas définie"),
        header: getText("Date de livraison"),
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) =>
          row.createdAt
            ? moment(row.createdAt).format("DD MMM YYYY")
            : getText("Pas définie"),
        header: getText("Date de demande"),
        enableClickToCopy: true,
      },
      {
        accessorKey: "address",
        header: getText("Lieu"),
        enableClickToCopy: true,
      },
      {
        accessorKey: "customerId",
        header: getText("ID client"),
        enableClickToCopy: true,
      },
      {
        accessorKey: "craftmanId",
        header: getText("ID artisan"),
        enableClickToCopy: true,
      },
      {
        accessorKey: "orderId",
        header: getText("ID commande"),
        enableClickToCopy: true,
      },
      {
        accessorKey: "codeTracking",
        header: getText("Numéro de suivi"),
        enableClickToCopy: true,
      },
      {
        accessorFn: (row) => {
          return (
            <div>
              <OrderImages images={row.photos} />
            </div>
          )
        },
        header: getText("Photos commande"),
        enableClickToCopy: true,
        enableColumnFilter: false,
      },
    ],
    [getText],
  )

  const handleChangePagination = (pagination: Pagination) =>
    setPagination(pagination)

  const onChangeSearchValue = (value: string) => setQuery(value)

  const handleRefresh = () => {
    window.location.reload()
  }

  if (loading && !query) return <Loader />

  return (
    <CustomTable
      columns={columns}
      data={data?.askDeliveryMen || []}
      lableAddNew=""
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregateAskDeliveryMan._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={pagination.page}
      pageSize={pagination.size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      isHaveAction={false}
      handleRefresh={handleRefresh}
    />
  )
}

export default ListAskDelivryMan
