import * as React from "react"
import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Box from "@mui/material/Box"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import { FixedSizeList, ListChildComponentProps } from "react-window"
import { Checkbox, ListItemIcon } from "@mui/material"
import useLang from "../../../hooks/useLang"

function renderRow(props: ListChildComponentProps) {
  const { index, style, data } = props

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemButton>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={true}
            tabIndex={-1}
            disableRipple
            color="success"
          />
        </ListItemIcon>
        <ListItemText primary={data[index]} />
      </ListItemButton>
    </ListItem>
  )
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}))

interface Props {
  countries: string[]
}

export default function ModalCountries({ countries }: Props) {
  const [open, setOpen] = React.useState(false)
  const { getText } = useLang()

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        size="small"
        color="success"
        sx={{ my: 2 }}
        disableElevation
      >
        {getText("Voir plus")}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {getText("Listes des pays")}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box
            sx={{
              width: "100%",
              height: 400,
              maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            <FixedSizeList
              height={400}
              width={360}
              itemSize={46}
              itemCount={countries.length}
              overscanCount={5}
              itemData={countries}
            >
              {renderRow}
            </FixedSizeList>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            color="error"
            onClick={handleClose}
          >
            {getText("Fermer")}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  )
}
