import { useCallback, useEffect, useState } from "react"
import { Typography, Box } from "@mui/material"
import {
  ChatMessage,
  ContentMessageType,
  Role,
} from "../../../../../gql/graphql"
import MessageVideo from "./MessageVideo/MessageVideo"
import MessageImage from "./MessageImage/MessageImage"
import MessageAudio from "./MessageAudio/MessageAudio"
import MessageText from "./MessageText/MessageText"
import UserAvatar from "../UserCard/UserAvatar"
import { MinTypeFile } from "../../../../Common/CustomUploadFiles/CustomUploadFiles"
import MessageOrderPrice from "./MessageOrderPrice/MessageOrderPrice"
import MessageSize from "./MessageSize/MessageSize"
import MessageDelivry from "./MessageDelivry/MessageDelivry"
import MessageValidOrder from "./MessageValidOrder/MessageValidOrder"
import moment from "moment"
import "moment/locale/fr"
import LabelNewArtisan from "./Labels/LabelNewArtisan"
import MenuActionMessage from "./MenuActionMessage/MenuActionMessage"
import useLang from "../../../../../hooks/useLang"

moment.locale("fr")

export interface ChatMessageType extends ChatMessage {}
interface Props {
  chatMessage: ChatMessageType
  senderIDPrev: number
  isArchived: boolean
  isPaid: boolean
}

const MessageCard = ({
  chatMessage,
  senderIDPrev,
  isArchived,
  isPaid,
}: Props) => {
  const { getText } = useLang()
  const isCustomer = chatMessage.Sender.role === Role.Customer
  const isAdmin = chatMessage.Sender.role === Role.Admin
  const messageClass = isCustomer
    ? "message-other"
    : isAdmin
    ? "message-own"
    : "message-craftman"

  const messageClassAvatar = isCustomer
    ? "message-other-avatar"
    : "message-own-avatar"

  const [defaulPhoto, setDefaulPhoto] = useState<MinTypeFile | undefined>(
    undefined,
  )

  useEffect(() => {
    if (chatMessage.Sender.ProfilePhoto) {
      const { url, id, name } = chatMessage.Sender.ProfilePhoto
      setDefaulPhoto({ url, id, name })
    }
  }, [chatMessage.Sender.ProfilePhoto])

  const haveAvatar = senderIDPrev !== chatMessage.Sender.id

  const isInfoLabel = useCallback(() => {
    return chatMessage.contentType === ContentMessageType.Infotext
  }, [chatMessage])

  const isEdit = useCallback(() => {
    return chatMessage.updatedAt &&
      chatMessage.updatedAt != chatMessage.createdAt
      ? true
      : false
  }, [chatMessage])

  const messageDate = useCallback(() => {
    return isEdit() ? chatMessage.updatedAt : chatMessage.createdAt
  }, [isEdit])

  const isDelete = useCallback(() => {
    return Boolean(chatMessage.isDelete)
  }, [chatMessage])

  if (isInfoLabel()) return <LabelNewArtisan message={chatMessage} />

  return (
    <>
      {haveAvatar && (
        <Box
          className={`${messageClassAvatar}`}
          style={{ backgroundColor: "transparent" }}
        >
          <UserAvatar
            firstName={chatMessage.Sender.name}
            src={defaulPhoto?.url || ""}
            lastName={chatMessage.Sender?.lastName || ""}
            direction={isCustomer ? "row" : "row-reverse"}
          />
        </Box>
      )}
      <Box className={`message ${messageClass}`}>
        {isDelete() ? (
          <Typography variant="caption" fontSize={12}>
            «{getText("Message supprimé")}»
          </Typography>
        ) : (
          <>
            <Box justifyContent={"flex-end"} display={"flex"}>
              <MenuActionMessage message={chatMessage} />
            </Box>
            <div>
              {chatMessage.AskDeliveryMan ? (
                <MessageDelivry askDeliveryMan={chatMessage.AskDeliveryMan} />
              ) : chatMessage.ValidOrderEvidence ? (
                <MessageValidOrder
                  validOrderEvidence={chatMessage.ValidOrderEvidence}
                />
              ) : (
                <>
                  <MessageVideo files={chatMessage.Files} />
                  <MessageImage files={chatMessage.Files} />
                  <MessageAudio files={chatMessage.Files} />
                  {chatMessage.OrderSize ? (
                    <MessageSize message={chatMessage} />
                  ) : !chatMessage.OrderPrice ? (
                    <MessageText message={chatMessage} />
                  ) : (
                    <MessageOrderPrice
                      message={chatMessage}
                      isArchived={isArchived}
                      isPaid={isPaid}
                    />
                  )}
                </>
              )}
            </div>
          </>
        )}
        <Box>
          <Typography
            variant="caption"
            fontSize={11}
            color={"#dbdbdb"}
            sx={{
              justifyContent: "end",
              marginTop: "5px",
              display: "flex",
            }}
          >
            {isEdit() ? getText("Modifié: ") : ""}{" "}
            {moment(messageDate()).format("LLL")}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default MessageCard
